<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 市区町村モーダル -->
    <CityModal
      id="city-modal"
      type="entry"
      :selectedSearchCondition.sync="cityModalSearchCondition"
      @after-close-set="afterCloseSetCity"
      @after-close-unset="afterCloseUnsetCity"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />

    <!-- 同一場所設定モーダル -->
    <PlaceSameSettingModal
      id="same-setting-modal"
      type="entry"
      :representativePlaceIds.sync="selectedRepresentativePlaceIds"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
      @after-close-set="reSearch(obj)"
    />

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />

    <!-- CSV取込モーダル -->
    <UploadModal
      id="upload-modal"
      :columnInfos="fileInfo"
      @upload="setUploadFile"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goRemove,
  goPlaceSameSetting,
  remove,
  download,
  upload,
} from '@/master/place/PlaceList.js'

const screenId = SCREEN_ID.PLACE_LIST

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceSameSettingModal: () => import('./PlaceSameSettingModal.vue'),
    CityModal: () => import('@/master/city/CityModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
      selectedRepresentativePlaceIds: [],
      cityModalSearchCondition: {},
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 場所コード
            type: 'text',
            id: 'placeCode',
            columnInfo: searchConditionInfo.place_code,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            maxLength: 10,
          },
          {
            // 場所名
            type: 'text',
            id: 'placeName',
            columnInfo: searchConditionInfo.place_name,
            maxLength: 100,
          },
          {
            // 郵便番号
            type: 'text',
            id: 'postalCode',
            columnInfo: searchConditionInfo.postal_code,
            rules: [this.INPUT_TYPE_CHECK.ZIPNUMERIC],
            maxLength: 7,
          },
          {
            // 都道府県
            type: 'select',
            id: 'prefectureCode',
            columnInfo: searchConditionInfo.prefecture_code,
            options: getListOptions(this.MASTER_CODE.PREFECTURE),
            onChangeCallback: () => {},
          },
          {
            // 市区町村
            type: 'referenceCodeName',
            codeId: 'cityCode',
            nameId: 'cityName',
            codeColumnInfo: searchConditionInfo.city_code,
            nameColumnInfo: searchConditionInfo.city_name,
            btnColumnInfo: searchConditionInfo.sub_modal_city,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.cityModalSearchCondition = {}
              this.cityModalSearchCondition.prefectureCode = this.searchCondition.prefectureCode
              this.openSubModal(this, 'city-modal')
            },
          },
          {
            // 荷主
            type: 'referenceCodeName',
            codeId: 'ownerCode',
            nameId: 'ownerName',
            codeColumnInfo: searchConditionInfo.owner_code,
            nameColumnInfo: searchConditionInfo.owner_name,
            btnColumnInfo: searchConditionInfo.sub_modal_owner,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.OWNER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 位置変換済区分
            type: 'select',
            id: 'accuracyLevel',
            columnInfo: searchConditionInfo.accuracy_level,
            options: getListOptions(this.MASTER_CODE.ACCURACY_LEVEL),
            onChangeCallback: () => {},
          },
          {
            // 場所区分
            type: 'select',
            id: 'placeKind',
            columnInfo: searchConditionInfo.place_kind,
            options: getListOptions(this.MASTER_CODE.PLACE_KIND_SEARCH),
            onChangeCallback: () => {},
          },
          {
            // マスタ区分
            type: 'select',
            id: 'isOnetimeMaster',
            columnInfo: searchConditionInfo.is_onetime_master,
            options: getListOptions(this.MASTER_CODE.IS_ONETIME_MASTER),
            onChangeCallback: () => {},
          },
          {
            // 削除表示
            type: 'select',
            id: 'deleted',
            columnInfo: searchConditionInfo.deleted,
            options: getListOptions(this.MASTER_CODE.DELETED_KIND),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew()
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
          // 同一場所設定ボタン
          {
            variant: 'primary',
            icon: 'map-marker',
            columnInfo: this.resultControlInfo.c0_place_setting,
            onClickCallback: () => {
              goPlaceSameSetting(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // ﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              openSubModal(this, 'download-modal')
            },
          },
          // ﾌｧｲﾙ取込ボタン
          {
            variant: 'primary',
            icon: 'upload',
            columnInfo: this.resultControlInfo.file_read,
            onClickCallback: () => {
              openSubModal(this, 'upload-modal')
            },
          },
        ],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    reSearch,
    search,
    clear,
    remove,
    download,
    upload,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        placeCode: null,
        placeName: null,
        postalCode: null,
        prefectureCode: null,
        cityCode: null,
        cityName: null,
        ownerCode: null,
        ownerName: null,
        accuracyLevel: null,
        placeKind: null,
        isOnetimeMaster: null,
        deleted: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.ownerCode = code
      this.searchCondition.ownerName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.ownerCode = null
      this.searchCondition.ownerName = null
    },

    /**
     * 市区町村マスタから取得データ設定
     */
    afterCloseSetCity(selectedItem) {
      this.searchCondition.cityCode = selectedItem.city_code
      this.searchCondition.cityName = selectedItem.city_name
      if (
        this.searchCondition.cityCode &&
        this.searchCondition.cityCode.length >= 2
      ) {
        this.searchCondition.prefectureCode = this.searchCondition.cityCode.substring(
          0,
          2
        )
      }
    },
    /**
     * 市区町村情報解除
     */
    afterCloseUnsetCity() {
      this.searchCondition.cityCode = null
      this.searchCondition.cityName = null
      this.searchCondition.prefectureCode = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
      // this.retrievePlaces()
    },

    setUploadFile(file) {
      upload(this, file)
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
