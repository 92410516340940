import {
  convertCamelCace,
  convertSnakeCace,
  openMsgModal,
  openSubModal,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import DepartmentService from './DepartmentService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    DepartmentService.get(obj.departmentId)
      .then(
        (response) => {
          obj.formData = response.data
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.departmentId = null
            obj.formData.deleted = '0'
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
            obj.formData.contactEmailDtos = []
          } else {
            obj.formData.departmentId = obj.departmentId
          }

          obj.formData.contactEmailDtos = convertSnakeCace(
            obj.formData.contactEmailDtos
          )
          obj.tableItems = obj.formData.contactEmailDtos

          obj.oriData = _.cloneDeep(obj.formData)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')
  obj.formData.contactEmailDtos = convertCamelCace(
    obj.formData.contactEmailDtos
  )
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.formData.deleted == '1') {
    obj.formData.deleted = '0'
  }

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規

    // DB変更分対応要
    obj.formData.accountingWorkKind = '1'

    callApi = DepartmentService.create(obj.formData)
  } else {
    // 詳細
    callApi = DepartmentService.update(obj.departmentId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.formData.contactEmailDtos = _.cloneDeep(obj.oriData.contactEmailDtos)
  obj.tableItems = obj.formData.contactEmailDtos || []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function add(obj) {
  if (obj.formData.contactEmailDtos.length > 4) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG994E', '5')
    )
    return
  }
  obj.detailRowIndex = -1
  obj.detailScreenKbn = DETAIL_SCREEN_KBN.NEW
  obj.sectionContactEmailDetail.push(obj)
  openSubModal(obj, 'contact-email-edit')
}

export function addRow(obj, info) {
  if (info != null) {
    let addInfo = {
      email: info.email,
      reserve01: info.reserve01,
      reserve02: info.reserve02,
      reserve03: info.reserve03,
      reserve04: info.reserve04,
      reserve05: info.reserve05,
    }
    if (obj.detailRowIndex !== -1) {
      obj.selectedItem[0].email = info.email
      obj.selectedItem[0].reserve01 = info.reserve01
      obj.selectedItem[0].reserve02 = info.reserve02
      obj.selectedItem[0].reserve03 = info.reserve03
      obj.selectedItem[0].reserve04 = info.reserve04
      obj.selectedItem[0].reserve05 = info.reserve05
    } else {
      obj.formData.contactEmailDtos.push(addInfo)
    }
  }
  obj.tableItems = obj.formData.contactEmailDtos
}

export function edit(obj) {
  if (obj.selectedItem.length !== 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    obj.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
    obj.sectionContactEmailDetail.push(obj)
    openSubModal(obj, 'contact-email-edit')
  }
}

export function remove(obj) {
  // 複数個削除
  for (let info of obj.selectedItem) {
    const index = obj.formData.contactEmailDtos.indexOf(info)
    obj.formData.contactEmailDtos.splice(index, 1)
  }
  obj.tableItems = obj.formData.contactEmailDtos
}
