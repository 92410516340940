<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import FreightItemInfo from './FreightItemInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  clear,
  goRegister,
  getBreadcrumbItems,
  back,
} from '@/common/screen-common.js'
import { init, register } from '@/master/freightItem/FreightItemDetail.js'

const screenId = SCREEN_ID.FREIGHT_ITEM_DETAIL

export default {
  name: 'freightItemDetail',
  components: {},
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    freightItemId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      selectLonLat: null,
      formData: new FreightItemInfo(),
      obj: this,
      registerMessage: null,
      oriData: new FreightItemInfo(),
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 運賃項目情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 運賃項目ID
              type: 'text',
              id: 'freightItemId',
              columnInfo: this.detailInfo.freight_item_id,
            },
            {
              // ブロックコード
              type: 'text',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
            },
            {
              // 運賃項目名
              type: 'text',
              id: 'freightItemName',
              columnInfo: this.detailInfo.freight_item_name,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 請求支払区分
              type: 'select',
              id: 'costType',
              columnInfo: this.detailInfo.cost_type,
              options: getListOptions(this.MASTER_CODE.COST_TYPE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 品目コード
              type: 'text',
              id: 'interfaceKey',
              columnInfo: this.detailInfo.interface_key,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 作業区分
              type: 'text',
              id: 'workType',
              columnInfo: this.detailInfo.work_type,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 売上区分
              type: 'text',
              id: 'salesType',
              columnInfo: this.detailInfo.sales_type,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 税区分
              type: 'text',
              id: 'taxType',
              columnInfo: this.detailInfo.tax_type,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 税区分
              type: 'select',
              id: 'taxCategory',
              columnInfo: this.detailInfo.tax_category,
              options: getListOptions(this.MASTER_CODE.TAX_CATEGORY),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 税率
              type: 'text',
              id: 'taxPercentage',
              columnInfo: this.detailInfo.tax_percentage,
              minValue: 0,
              maxValue: 100,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 予備1
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve_01,
              maxLength: 50,
            },
            {
              // 予備2
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve_02,
              maxLength: 50,
            },
            {
              // 予備3
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve_03,
              maxLength: 50,
            },
            {
              // 予備4
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve_04,
              maxLength: 50,
            },
            {
              // 予備5
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve_05,
              maxLength: 50,
            },
            {
              // 予備6
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve_06,
              maxLength: 50,
            },
            {
              // 予備7
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve_07,
              maxLength: 50,
            },
            {
              // 予備8
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve_08,
              maxLength: 50,
            },
            {
              // 予備9
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve_09,
              maxLength: 50,
            },
            {
              // 予備10
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve_10,
              maxLength: 50,
            },
            {
              // ＃2786
              // 部署グループ
              type: 'text',
              id: 'departmentGroup',
              columnInfo: this.detailInfo.department_group,
              maxLength: 2,
            },
            // {
            //   // 登録日時
            //   type: 'text',
            //   id: 'registrationTimeDisplay',
            //   columnInfo: this.detailInfo.registration_time_display,
            // },
            // {
            //   // 登録ユーザ
            //   type: 'text',
            //   id: 'registrationUser',
            //   columnInfo: this.detailInfo.registration_user,
            // },
            // {
            //   // 更新日時
            //   type: 'text',
            //   id: 'updateTimeDisplay',
            //   columnInfo: this.detailInfo.update_time_display,
            // },
            // {
            //   // 更新ユーザ
            //   type: 'text',
            //   id: 'updateUser',
            //   columnInfo: this.detailInfo.update_user,
            // },
            // {
            //   // バージョン
            //   type: 'text',
            //   id: 'version',
            //   columnInfo: this.detailInfo.version,
            // },
            // {
            //   // トリガ更新日時
            //   type: 'text',
            //   id: 'updateByTrigger',
            //   columnInfo: this.detailInfo.update_by_trigger,
            // },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },

  // mounted() {
  //     this.$refs.map.initialize()
  // }
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}
</style>
