<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableDown>
            <div>
              <!--区間詳細画面-->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isAddBtnSelected"
                :columnInfo="detailCtrlInfo.add"
                icon="plus-circle"
                @click="addRow(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.edit"
                icon="minus-circle"
                @click="editRow(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.delete"
                icon="minus-circle"
                @click="tableRowRemove(obj)"
              />
            </div>
            <!-- テーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="fields"
              :items="tableItems"
              selectMode="single"
              @row-selected="onRowSelected"
              @row-clicked="onRowClicked"
            >
            </BaseTable>
          </template>
        </BaseTabGroup>
        <p></p>
      </template>
    </DetailPageLayout>
    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      :useFullPlaceKind="true"
      @click="select('place-modal')"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 区間詳細編集モーダル -->
    <SectionDivisionEdit
      id="section-division-edit"
      type="entry"
      :getDatas.sync="sectionDivisionEditModalCondition"
      @after-close-set="afterCloseSetSectionDivisionEdit"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import SectionDivisionInfo from './SectionDivisionInfo'
import {
  getListOptions,
  openSubModal,
  changePlaceKind,
} from '@/common/Common.js'
import { SCREEN_ID, SLOT_NAME } from '@/common/const.js'
import { goRegister, getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  register,
  addRow,
  editRow,
  clear,
  tableRowRemove,
  afterCloseEditModal,
  reflectPlace,
} from '@/master/sectionDivision/SectionDivisionDetail.js'

const screenId = SCREEN_ID.SECTION_DIVISION_DETAIL

export default {
  name: 'sectionDivisionDetail',

  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    SectionDivisionEdit: () =>
      import('@/master/sectionDivision/SectionDivisionEdit.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    sectionDivisionId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      placeModalKbn: 0,
      tableItems: [],
      selectedItem: [],
      selectLonLat: null,
      formData: new SectionDivisionInfo(),
      obj: this,
      detailRowIndex: -1,
      registerMessage: null,
      oriData: new SectionDivisionInfo(),
      sectionDivisionEditModalCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
      info.add.visibility = this.VISIBILITY.VISIBLE
      return info
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 基本情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 分割パターン名
              type: 'text',
              id: 'dividePatternName',
              columnInfo: this.detailInfo.divide_pattern_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 搬出場所区分
              type: 'select',
              id: 'departurePlaceType',
              columnInfo: this.detailInfo.departure_place_type,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
              onChangeCallback: () => {},
            },
            {
              // 搬出場所
              type: 'referenceCodeName',
              codeId: 'departurePlaceCode',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_code,
              nameColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 搬入場所区分
              type: 'select',
              id: 'arrivalPlaceType',
              columnInfo: this.detailInfo.arrival_place_type,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
              onChangeCallback: () => {},
            },
            {
              // 搬入場所
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceCode',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_code,
              nameColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.placeModalKbn = 2
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // デフォルト区分
              type: 'select',
              id: 'defaultType',
              columnInfo: this.detailInfo.default_type,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.DEFAULT_TYPE),
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // 経由地タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isAddBtnSelected() {
      if (this.obj.formData.sectionDivisionDetailDtos.length != 0) {
        return this.selectedItem.length > 0
      }
      return true
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    addRow,
    editRow,
    afterCloseEditModal,
    back,
    tableRowRemove,
    reflectPlace,
    changePlaceKind,

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.formData.departurePlaceId = selectedItem.place_id
        this.formData.departurePlaceCode = selectedItem.place_code
        this.formData.departurePlaceName = selectedItem.place_name
        //搬出場所区分
        let placeType = changePlaceKind(selectedItem.place_kind)
        this.formData.departurePlaceType = placeType
      } else if (this.placeModalKbn == 2) {
        this.formData.arrivalPlaceId = selectedItem.place_id
        this.formData.arrivalPlaceCode = selectedItem.place_code
        this.formData.arrivalPlaceName = selectedItem.place_name
        //搬入場所区分
        let placeType = changePlaceKind(selectedItem.place_kind)
        this.formData.arrivalPlaceType = placeType
      }
      //経由地タブに設定情報反映
      reflectPlace(this, this.placeModalKbn)
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.formData.departurePlaceId = null
        this.formData.departurePlaceCode = null
        this.formData.departurePlaceName = null
        //搬出場所区分
        this.formData.departurePlaceType = null
      } else if (this.placeModalKbn == 2) {
        this.formData.arrivalPlaceId = null
        this.formData.arrivalPlaceCode = null
        this.formData.arrivalPlaceName = null
        //搬入場所区分
        this.formData.arrivalPlaceType = null
      }
    },

    afterCloseSetSectionDivisionEdit(info) {
      afterCloseEditModal(this.obj, info)
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
    onRowClicked(item, index) {
      this.detailRowIndex = index
    },
  },

  // mounted() {
  //     this.$refs.map.initialize()
  // }
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}

.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
