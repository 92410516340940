import { openMsgModal, openSubModal } from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import FreightItemService from './FreightItemService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    FreightItemService.get(obj.freightItemId)
      .then(
        (response) => {
          obj.formData = response.data
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.freightItemId = null
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
          } else {
            obj.formData.freightItemId = obj.freightItemId
          }
          obj.oriData = _.cloneDeep(obj.formData)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = FreightItemService.create(obj.formData)
  } else {
    // 詳細
    callApi = FreightItemService.update(obj.freightItemId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
