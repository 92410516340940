<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableDown>
            <div>
              <!--区間詳細画面-->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isAddBtnSelected"
                :columnInfo="detailCtrlInfo.add"
                icon="plus-circle"
                @click="add(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.edit"
                icon="minus-circle"
                @click="edit(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.delete"
                icon="minus-circle"
                @click="remove(obj)"
              />
            </div>
            <!-- テーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="contactEmailFields"
              :items="tableItems"
              selectMode="single"
              @row-selected="onRowSelected"
              @row-clicked="onRowClicked"
            >
            </BaseTable>
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <!-- タリフ参照モーダル -->
    <TariffModal
      id="tariff-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetTariff"
      @after-close-unset="afterCloseUnsetTariff"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <ContactEmailEditModal
      id="contact-email-edit"
      type="entry"
      :getDatas.sync="sectionContactEmailDetail"
      @after-close-set="afterCloseSet"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import DepartmentInfo from './DepartmentInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, SLOT_NAME } from '@/common/const.js'
import { goRegister, getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  add,
  edit,
  addRow,
  register,
  remove,
  clear,
} from '@/master/department/DepartmentDetail.js'

const screenId = SCREEN_ID.DEPARTMENT_DETAIL

export default {
  name: 'departmentDetail',
  components: {
    TariffModal: () => import('@/master/tariff/TariffModal.vue'),
    ContactEmailEditModal: () =>
      import('@/master/department/ContactEmailEditModal.vue'),
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    departmentId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      tableItems: [],
      selectedItem: [],
      selectLonLat: null,
      formData: new DepartmentInfo(),
      obj: this,
      registerMessage: null,
      detailScreenKbn: 0,
      detailRowIndex: -1,
      sectionContactEmailDetail: [],
      oriData: new DepartmentInfo(),
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    contactEmailFields() {
      return this.$store.getters['init/getFields'](screenId)(
        'result_fields_contact_email'
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 部署マスタ情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 部署ID
              type: 'text',
              id: 'departmentId',
              columnInfo: this.detailInfo.department_id,
            },
            {
              // ブロック外公開フラグ
              type: 'select',
              id: 'publicFlg',
              columnInfo: this.detailInfo.public_flg,
              options: getListOptions(this.MASTER_CODE.PUBLIC_FLG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 部署コード
              type: 'text',
              id: 'departmentCode',
              columnInfo: this.detailInfo.department_code,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
            },
            {
              // 部署名
              type: 'text',
              id: 'departmentName',
              columnInfo: this.detailInfo.department_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 電話番号
              type: 'text',
              id: 'tel',
              columnInfo: this.detailInfo.tel,
            },
            {
              // FAX番号
              type: 'text',
              id: 'fax',
              columnInfo: this.detailInfo.fax,
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 部署区分
              type: 'select',
              id: 'departmentKind',
              columnInfo: this.detailInfo.department_kind,
              options: getListOptions(this.MASTER_CODE.DEPARTMENT_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 請求支払作業区分
              type: 'select',
              id: 'accountingWorkKind',
              columnInfo: this.detailInfo.accounting_work_kind,
              options: getListOptions(this.MASTER_CODE.RECORD_WORK_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // タリフ
              type: 'referenceCodeName',
              codeId: 'tariffCode',
              nameId: 'tariffName',
              codeColumnInfo: this.detailInfo.tariff_code,
              nameColumnInfo: this.detailInfo.tariff_name,
              btnColumnInfo: this.detailInfo.sub_modal_tariff,
              onClickCallback: () => {
                this.openSubModal(this, 'tariff-modal')
              },
            },
            {
              // マスタ区分
              type: 'select',
              id: 'isOnetimeMaster',
              columnInfo: this.detailInfo.is_onetime_master,
              options: getListOptions(this.MASTER_CODE.IS_ONETIME_MASTER),
              onChangeCallback: () => {},
            },
            {
              // 部署予備1
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
            },
            {
              // 部署予備2
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
            },
            {
              // 部署予備3
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
            },
            {
              // 部署予備4
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
            },
            {
              // 部署予備5
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
            },
            {
              // 部署予備6
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
            },
            {
              // 部署予備7
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              // 部署予備8
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              // 部署予備9
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              // 部署予備10
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
            },
            {
              // 部署グループ
              type: 'text',
              id: 'departmentGroup',
              columnInfo: this.detailInfo.department_group,
              maxLength: 2,
              rules: [this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC],
            },
            {
              // 部署グループ
              type: 'text',
              id: 'orderAmountKind',
              columnInfo: this.detailInfo.order_amount_kind,
              maxLength: 1,
              rules: [this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC],
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // バージョン
              type: 'text',
              id: 'version',
              columnInfo: this.detailInfo.version,
            },
            {
              // トリガ更新日時
              type: 'text',
              id: 'updateByTrigger',
              columnInfo: this.detailInfo.update_by_trigger,
            },
          ],
        },
        {
          // 経由地タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isAddBtnSelected() {
      // if (this.obj.formData.contactEmailDtos.length != 0) {
      //   return this.selectedItem.length > 0
      // }
      return true
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    add,
    edit,
    addRow,
    remove,
    clear,

    /**
     * タリフマスタから取得データ設定
     */
    afterCloseSetTariff(id, code, name) {
      this.formData.tariffId = id
      this.formData.tariffCode = code
      this.formData.tariffName = name
    },

    /**
     * タリフ情報解除
     */
    afterCloseUnsetTariff() {
      this.formData.tariffId = null
      this.formData.tariffCode = null
      this.formData.tariffName = null
    },

    afterCloseSet(info) {
      addRow(this.obj, info)
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },
    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
    onRowClicked(item, index) {
      this.detailRowIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}
#BaseView {
  height: 730px;
}
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
