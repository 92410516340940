/**
 * 定期オーダーデータ.
 * @class
 * @exports
 */
export default class regularOrder {
  regularOrderId
  // placeCode = null
  regularOrderName = null
  // planningGroupCode = null
  // ownerCode = null
  // ownerName = null

  productName = null
  tariffProductCode = null
  length = null
  width = null
  height = null
  weight = null
  volume = null

  //荷主
  ownerId = null
  ownerCode = null
  ownerName = null

  //運送会社
  carrierId = null
  carrierCode = null
  carrierName = null

  //輸送手段
  vehicleId = null
  vehicleCode = null
  vehicleName = null

  //搬出場所
  departurePlaceId = null
  departurePlaceCode = null
  departurePlaceName = null

  //到着加算日
  arrivalAddDays = 0

  // 搬出時間
  departureBeginTime = null
  departureEndTime = null

  //搬入場所
  arrivalPlaceId = null
  arrivalPlaceCode = null
  arrivalPlaceName = null

  // 搬入時間
  arrivalBeginTime = null
  arrivalEndTime = null

  //オーダー生成数
  generateCount = null

  // 有効期間
  validBeginDate = null
  validEndDate = null

  // 生成タイミング
  generateTiming = null

  // 曜日（月）〜（日）・・ED
  mondayed = false
  tuesdayed = false
  wednesdayed = false
  thursdayed = false
  fridayed = false
  saturdayed = false
  sundayed = false

  // 搬入日加算日数
  daysToArrival = 0

  vehicleCondition01 = null
  vehicleCondition01Name = null
  vehicleCondition02 = null
  vehicleCondition02Name = null
  vehicleCondition03 = null
  vehicleCondition03Name = null
  vehicleCondition04 = null
  vehicleCondition04Name = null
  vehicleCondition05 = null
  vehicleCondition05Name = null
  vehicleCondition06 = null
  vehicleCondition06Name = null
  vehicleCondition07 = null
  vehicleCondition07Name = null
  vehicleCondition08 = null
  vehicleCondition08Name = null
  vehicleCondition09 = null
  vehicleCondition09Name = null
  vehicleCondition10 = null
  vehicleCondition10Name = null
  vehicleCondition11 = null
  vehicleCondition11Name = null
  vehicleCondition12 = null
  vehicleCondition12Name = null
  vehicleCondition13 = null
  vehicleCondition13Name = null
  vehicleCondition14 = null
  vehicleCondition14Name = null
  vehicleCondition15 = null
  vehicleCondition15Name = null
  vehicleCondition16 = null
  vehicleCondition16Name = null
  vehicleCondition17 = null
  vehicleCondition17Name = null
  vehicleCondition18 = null
  vehicleCondition18Name = null
  vehicleCondition19 = null
  vehicleCondition19Name = null
  vehicleCondition20 = null
  vehicleCondition20Name = null

  deleted = 0
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
}
