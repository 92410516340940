<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />

    <!-- CSV取込モーダル -->
    <UploadModal
      id="upload-modal"
      :columnInfos="fileInfo"
      @upload="setUploadFile"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  init,
  search,
  clear,
  goDetail,
  goNew,
  // goCopy,
  goRemove,
  remove,
  download,
  upload,
} from '@/master/freightItem/FreightItemList.js'

const screenId = SCREEN_ID.FREIGHT_ITEM_LIST

export default {
  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 運賃項目名
            type: 'text',
            id: 'freightItemName',
            columnInfo: searchConditionInfo.freight_item_name,
            maxLength: 50,
          },
          {
            // 請求支払区分
            type: 'select',
            id: 'costType',
            columnInfo: searchConditionInfo.cost_type,
            options: getListOptions(this.MASTER_CODE.COST_TYPE),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew()
            },
          },
          // // コピーボタン
          // {
          //   variant: 'primary',
          //   icon: 'copy',
          //   columnInfo: this.resultControlInfo.copy,
          //   disabled: !this.isSelected,
          //   onClickCallback: () => {
          //     goCopy(this)
          //   },
          // },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // ﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              openSubModal(this, 'download-modal')
            },
          },
          // ﾌｧｲﾙ取込ボタン
          {
            variant: 'primary',
            icon: 'upload',
            columnInfo: this.resultControlInfo.file_read,
            onClickCallback: () => {
              openSubModal(this, 'upload-modal')
            },
          },
        ],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    search,
    clear,
    remove,
    download,
    upload,

    /**
     * デフォルト検索パラメータを返します.
     * ★freight_item_id: null,
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        freightItemName: null,
        costType: null,
        deleted: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },

    setUploadFile(file) {
      upload(this, file)
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
