<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="OperationTimePattern"
          :inputComponents="inputComponents"
          @clear="clear(obj)"
        ></BaseTabGroup>
      </template>
      <template #bottom>
        <div class="data-group-role">
          <div id="BaseView">
            <section data-class="work-list-table">
              <div>
                <BaseButton
                  class="edit-button"
                  variant="success"
                  :columnInfo="detailCtrlInfo.add"
                  icon="plus-circle"
                  @click="add(obj)"
                />
                <BaseButton
                  class="edit-button"
                  variant="success"
                  :disabled="!isSelected"
                  :columnInfo="detailCtrlInfo.update"
                  icon="pen"
                  @click="edit(obj)"
                />
                <BaseButton
                  class="edit-button"
                  variant="success"
                  :disabled="!isSelected"
                  :columnInfo="detailCtrlInfo.delete"
                  icon="minus-circle"
                  @click="remove(obj)"
                />
              </div>
              <!-- テーブル -->
              <BaseTable
                select-mode="range"
                ref="table"
                :fields="fields"
                :items="tableItems"
                @row-selected="onRowSelected"
                @row-clicked="onRowClicked"
              />
            </section>
          </div>
        </div>
      </template>
    </DetailPageLayout>
    <!-- テーブル編集モーダル -->
    <EditModal
      id="edit-modal"
      type="entry"
      :getDatas.sync="selectedOperationTimePatternDetail"
      @after-close-set="afterCloseSetTableEdit"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="operationTimePattern-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import OperationTimePatternInfo from './OperationTimePatternInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  goRegister,
  register,
  add,
  edit,
  remove,
  clear,
  addRow,
} from '@/master/operationTimePattern/OperationTimePatternDetail.js'

export default {
  name: 'operationTimePatternDetail',

  components: {
    EditModal: () =>
      import(
        '@/master/operationTimePattern/OperationTimePatternDetailEdit.vue'
      ),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    operationTimePatternId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      tableItems: [],
      selectedItem: [],
      OperationTimePattern: new OperationTimePatternInfo(),
      obj: this,
      registerMessage: null,
      detailScreenKbn: 0,
      detailRowIndex: -1,
      selectedOperationTimePatternDetail: [],
      oriData: new OperationTimePatternInfo(),
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.OPERATION_TIME_PATTERN_DETAIL
      )('detail_ctrl_info')

      return info
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.OPERATION_TIME_PATTERN_DETAIL
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.OPERATION_TIME_PATTERN_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 稼動時間パターンタブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 稼動時間パターン名
              type: 'text',
              id: 'operationTimePatternName',
              columnInfo: this.detailInfo.operation_time_pattern_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // ブロックコード
              type: 'text',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時表示
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時表示
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.OPERATION_TIME_PATTERN_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    clear,
    add,
    edit,
    remove,
    addRow,

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetTableEdit(info) {
      addRow(this.obj, info)
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
    onRowClicked(item, index) {
      this.detailRowIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.data-group-role {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 14px;
}
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
