<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          :inputModel="InflowImpossible"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
      <template #bottom>
        <div id="BaseView">
          <div v-if="ctrlItems">
            <BaseButton
              v-for="(item, index) in ctrlItems"
              :key="index"
              class="edit-button mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
          <section data-class="work-list-table">
            <!-- テーブル -->
            <BaseTable
              select-mode="range"
              :fields="fields"
              :items="tableItems"
              @row-selected="onRowSelected"
            />
          </section>
        </div>
      </template>
    </DetailPageLayout>
    <!-- 都道府県モーダル -->
    <PrefectureModal
      id="prefecture-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addPrefecture"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 市区町村モーダル -->
    <CityModal
      id="city-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addCity"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="inflowImpossible-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
  </div>
</template>

<script>
import InflowImpossibleInfo from './InflowImpossibleInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  clear,
  addPrefecture,
  addCity,
  addPlace,
  remove,
  goRegister,
  register,
} from './InflowImpossible.js'

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    PrefectureModal: () => import('@/master/prefecture/PrefectureModal.vue'),
    CityModal: () => import('@/master/city/CityModal.vue'),
  },

  data() {
    return {
      tableItems: [],
      selectedItem: [],
      InflowImpossible: new InflowImpossibleInfo(),
      obj: this,
      registerMessage: null,
      oriData: null,
      selectedPrefecture: null,
      isDisabled: true,
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    vehicleId: {
      type: Number,
    },
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.VEHICLE_INFLOW_IMPOSSIBLE
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.VEHICLE_INFLOW_IMPOSSIBLE
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.VEHICLE_INFLOW_IMPOSSIBLE
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            disabled: this.isDisabled,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    ctrlItems() {
      return [
        // 都道府県追加ボタン
        {
          variant: 'success',
          icon: 'plus-circle',
          columnInfo: this.detailCtrlInfo.add_prefecture,
          onClickCallback: () => {
            openSubModal(this, 'prefecture-modal')
          },
        },
        // 市区町村追加ボタン
        {
          variant: 'success',
          icon: 'plus-circle',
          columnInfo: this.detailCtrlInfo.add_city,
          onClickCallback: () => {
            openSubModal(this, 'city-modal')
          },
        },
        // 場所追加ボタン
        {
          variant: 'success',
          icon: 'plus-circle',
          columnInfo: this.detailCtrlInfo.add_place,
          onClickCallback: () => {
            openSubModal(this, 'place-modal')
          },
        },
        // 削除ボタン
        {
          variant: 'success',
          icon: 'minus-circle',
          columnInfo: this.detailCtrlInfo.delete,
          onClickCallback: () => {
            remove(this)
          },
        },
      ]
    },

    inputComponents() {
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 輸送手段名
              type: 'text',
              id: 'vehicleName',
              columnInfo: this.detailInfo.vehicle_name,
            },
            {
              // 運送会社名
              type: 'referenceCodeName',
              codeId: 'carrierCode',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_code,
              nameColumnInfo: this.detailInfo.carrier_name,
            },
            {
              // ナンバープレート
              type: 'text',
              id: 'numberplate',
              columnInfo: this.detailInfo.numberplate,
            },
            {
              // 車型
              type: 'select',
              id: 'vehicleModel',
              columnInfo: this.detailInfo.vehicle_model,
              options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段カテゴリ
              type: 'select',
              id: 'vehicleCategory',
              columnInfo: this.detailInfo.vehicle_category,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CATEGORY),
              onChangeCallback: () => {},
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.VEHICLE_INFLOW_IMPOSSIBLE
      )(this.DOMAIN_NAME.FIELDS)
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    register,
    back,

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    addPlace(selectedPlaces) {
      addPlace(this, selectedPlaces)
    },

    addPrefecture(selectedPrefectures) {
      addPrefecture(this, selectedPrefectures)
    },

    addCity(selectedCities) {
      addCity(this, selectedCities)
    },
  },
}
</script>
<style lang="scss" scoped>
.edit-button {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
