import AreaService from './AreaService'
import {
  openMsgModal,
  openSubModal,
  getMasterName,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import {
  MASTER_CODE,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  AREA_ENTRY_KIND,
} from '@/common/const.js'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    AreaService.get(obj.areaId)
      .then(
        (response) => {
          obj.Area = response.data

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.Area.areaId = null
            obj.Area.deleted = '0'
            obj.Area.registrationTimeDisplay = null
            obj.Area.registrationUser = null
            obj.Area.updateTimeDisplay = null
            obj.Area.updateUser = null
            obj.Area.areaDetails.forEach((element) => {
              element.areaId = null
              element.deleted = '0'
              element.registrationTimeDisplay = null
              element.registrationUser = null
              element.updateTimeDisplay = null
              element.updateUser = null
            })
          }

          // details
          var details = convertSnakeCace(obj.Area.areaDetails)
          obj.Area.areaDetails = details
          // コピー
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.Area.areaDetails.map(function (x) {
              x.areaDetailId = ''
              return x
            })
          }
          obj.Area.areaDetails.forEach((element) => {
            // 0: 積地 1: 卸地
            if (element.load_flag == 0) {
              obj.departureTableItems.push(element)
            } else {
              obj.arrivalTableItems.push(element)
            }
          })

          obj.oriData = _.cloneDeep(obj.Area)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    obj.Area.areaKind = obj.areaKind
  }
}

export function clear(obj) {
  obj.Area = _.cloneDeep(obj.oriData)
  obj.departureTableItems = []
  obj.arrivalTableItems = []
  // 新規の時、obj.oriData=null
  if (
    obj.Area != null &&
    obj.Area.areaDetails != null &&
    obj.Area.areaDetails.length > 0
  ) {
    obj.Area.areaDetails.forEach((element) => {
      // 0: 積地 1: 卸地
      if (element.load_flag == 0) {
        obj.departureTableItems.push(element)
      } else {
        obj.arrivalTableItems.push(element)
      }
    })
  }
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function addPrefecture(obj, selectedPrefectures) {
  // 都道府県を、方面コース登録区分 = 10(都道府県)として新規行(最下部)に反映する
  // 既に方面コース一覧に同じデータが存在している場合は、積地一覧に追加しない。
  if (selectedPrefectures != undefined && selectedPrefectures.length != 0) {
    let tableItems
    let loadFlag
    if (obj.isDeparturePlaceClick) {
      tableItems = obj.departureTableItems
      loadFlag = 0
    } else {
      tableItems = obj.arrivalTableItems
      loadFlag = 1
    }

    selectedPrefectures.forEach((element) => {
      let info = {
        // 方面登録区分(コード)
        area_entry_kind: AREA_ENTRY_KIND.PREFECTURE,
        // 方面登録区分
        area_entry_kind_name: getMasterName(
          MASTER_CODE.AREA_ENTRY_KIND,
          AREA_ENTRY_KIND.PREFECTURE
        ),
        // 場所ID
        place_id: element.place_id,
        // 都道府県コード
        prefecture_code: element.prefecture_code,
        // 都道府県名
        prefecture_name: element.prefecture_name,
        // 緯度
        latitude: element.latitude,
        // 経度
        longitude: element.longitude,
        // 積卸区分
        load_flag: loadFlag,
        // 優先順
        zone_priority_order: 0,
      }

      let exists = false
      tableItems.forEach((item) => {
        if (
          item.area_entry_kind == AREA_ENTRY_KIND.PREFECTURE &&
          item.prefecture_code == element.prefecture_code
        ) {
          exists = true
          return true
        }
      })

      if (exists == false) {
        tableItems.push(info)
      }
    })
  }
}

export function addCity(obj, selectedCities) {
  if (selectedCities != undefined && selectedCities.length != 0) {
    let tableItems
    let loadFlag
    if (obj.isDeparturePlaceClick) {
      tableItems = obj.departureTableItems
      loadFlag = 0
    } else {
      tableItems = obj.arrivalTableItems
      loadFlag = 1
    }

    selectedCities.forEach((element) => {
      let info = {
        // 方面登録区分(コード)
        area_entry_kind: AREA_ENTRY_KIND.CITY,
        // 方面登録区分
        area_entry_kind_name: getMasterName(
          MASTER_CODE.AREA_ENTRY_KIND,
          AREA_ENTRY_KIND.CITY
        ),
        // 場所ID
        place_id: element.place_id,
        // 都道府県コード
        prefecture_code: element.prefecture_code,
        // 都道府県名
        prefecture_name: element.prefecture_name,
        //市区町村コード
        city_code: element.city_code,
        // 市区町村名
        city_name: element.city_name,
        // 緯度
        latitude: element.latitude,
        // 経度
        longitude: element.longitude,
        // 積卸区分
        load_flag: loadFlag,
        // 優先順
        zone_priority_order: 0,
      }

      let exists = false
      tableItems.forEach((item) => {
        if (
          item.area_entry_kind == AREA_ENTRY_KIND.CITY &&
          item.city_code == element.city_code
        ) {
          exists = true
          return true
        }
      })

      if (exists == false) {
        tableItems.push(info)
      }
    })
  }
}

export function addPlace(obj, selectedPlaces) {
  if (selectedPlaces != undefined && selectedPlaces.length != 0) {
    let tableItems
    let loadFlag
    if (obj.isDeparturePlaceClick) {
      tableItems = obj.departureTableItems
      loadFlag = 0
    } else {
      tableItems = obj.arrivalTableItems
      loadFlag = 1
    }

    selectedPlaces.forEach(async (element) => {
      let postalCodeId = ''
      obj.$store.dispatch('init/setServiceLoading')
      await AreaService.getPostalCodeId(element.postal_code)
        .then(
          (response) => {
            postalCodeId = response.data.postalCodeId
          },
          (e) => {
            console.log(e)
            postalCodeId = ''
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      let info = {
        // 方面登録区分(コード)
        area_entry_kind: AREA_ENTRY_KIND.PLACE,
        // 方面登録区分
        area_entry_kind_name: getMasterName(
          MASTER_CODE.AREA_ENTRY_KIND,
          AREA_ENTRY_KIND.PLACE
        ),
        // 都道府県コード
        prefecture_code: element.prefecture_code,
        // 都道府県名
        prefecture_name: getMasterName(
          MASTER_CODE.PREFECTURE,
          element.prefecture_code
        ),
        //市区町村コード
        city_code: element.city_code,
        // 市区町村名
        city_name: element.city_name,
        // 場所ID
        place_id: element.place_id,
        // 場所コード
        place_code: element.place_code,
        // 場所名
        place_name: element.place_name,
        // 郵便番号ID
        postal_code_id: postalCodeId,
        // 郵便番号
        postal_code: element.postal_code,
        // 町域名
        town_name: element.address1,
        // 緯度
        latitude: element.latitude,
        // 経度
        longitude: element.longitude,
        // 積卸区分
        load_flag: loadFlag,
        // 優先順
        zone_priority_order: 0,
      }

      let exists = false
      tableItems.forEach((item) => {
        if (
          item.area_entry_kind == AREA_ENTRY_KIND.PLACE &&
          item.place_id == element.place_id
        ) {
          exists = true
          return true
        }
      })

      if (exists == false) {
        tableItems.push(info)
      }
    })
  }
}

export function remove(obj, isDeparture) {
  let selectedItem
  let tableItems
  if (isDeparture) {
    selectedItem = obj.departureSelectedItem
    tableItems = obj.departureTableItems
  } else {
    selectedItem = obj.arrivalSelectedItem
    tableItems = obj.arrivalTableItems
  }

  // 一覧の選択行が存在しない場合、エラーを出力し、以降の処理は行わない。
  if (selectedItem.length == 0) {
    // 一覧より行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  // 選択行を一覧より、削除する。
  selectedItem.forEach((element) => {
    const index = tableItems.indexOf(element)
    tableItems.splice(index, 1)
  })
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 積地リスト、卸地リストの両方空の場合は、チェックメッセージを表示し、それ以降の処理は行わない。
  if (
    obj.departureTableItems.length == 0 &&
    obj.arrivalTableItems.length == 0
  ) {
    // 積地、卸地が未設定のため、登録できません。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('ED01S002_MG011E')
    )
    return
  }

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export function register(obj) {
  obj.$bvModal.hide('register-modal')

  obj.Area.areaDetails = []
  obj.departureTableItems.forEach((element) => {
    obj.Area.areaDetails.push(element)
  })
  obj.arrivalTableItems.forEach((element) => {
    obj.Area.areaDetails.push(element)
  })

  // details
  var details = convertCamelCace(obj.Area.areaDetails)
  obj.Area.areaDetails = details

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = AreaService.create(obj.Area)
  } else {
    // 詳細
    callApi = AreaService.update(obj.areaId, obj.Area)
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'area-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
