<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goRemove,
  remove,
} from '@/order/regularorder/RegularOrderList.js'

const screenId = SCREEN_ID.REGULAR_ORDER_LIST

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      placeModalKbn: -1,
      removeMessage: null,
      selectedRepresentativePlaceIds: [],
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 定期便名
            type: 'text',
            id: 'regularOrderName',
            maxLength: 30,
            columnInfo: searchConditionInfo.regular_order_name,
          },
          {
            // 搬出場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 搬入場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 2
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CARRIER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 有効期間
            type: 'dateFromTo',
            fromId: 'validBeginDate',
            toId: 'validEndDate',
            fromColumnInfo: searchConditionInfo.valid_begin_date,
            toColumnInfo: searchConditionInfo.valid_end_date,
            locale: this.locale,
            fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew()
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    reSearch,
    search,
    clear,
    remove,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        blockCode: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        carrierCode: null,
        carrierName: null,
        validBeginDate: null,
        validEndDate: null,
        deleted: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    /**
     * 部署マスタから取得データ設定(運送会社)
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
