import { openMsgModal, openSubModal } from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN, USER_EROLE } from '@/common/const.js'
import ProductService from './ProductService'
import _ from 'lodash'

export function init(obj) {
  setUserDepartmentInfo(obj)

  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    ProductService.get(obj.productId).then(
      (response) => {
        obj.formData = response.data
        if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
          obj.formData.productId = null
          obj.formData.deleted = '0'
          obj.formData.registrationTimeDisplay = null
          obj.formData.registrationUser = null
          obj.formData.updateTimeDisplay = null
          obj.formData.updateUser = null
        } else {
          obj.formData.productId = obj.productId
        }
        obj.oriData = _.cloneDeep(obj.formData)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.formData.deleted == '1') {
    obj.formData.deleted = '0'
  }

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = ProductService.create(obj.formData)
  } else {
    // 詳細
    callApi = ProductService.update(obj.productId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function setUserDepartmentInfo(obj) {
  let loginUserRoleId = obj.$store.getters['authentication/getRoleId']
  // ロール＝OWNERの場合
  // 荷主はログインユーザの部署を設定し変更不可
  if (loginUserRoleId == USER_EROLE.OWNER) {
    obj.formData.ownerId =
      obj.$store.getters['authentication/getUserDepartmentId']
    obj.formData.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.formData.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']

    obj.oriData.ownerId =
      obj.$store.getters['authentication/getUserDepartmentId']
    obj.oriData.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.oriData.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']
  }
}
