<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableDown>
            <div>
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.add_vehicle"
                icon="plus-circle"
                @click="addVehicle(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedVehicle"
                :columnInfo="detailCtrlInfo.delete_vehicle"
                icon="minus-circle"
                @click="removeVehicle(obj)"
              />
            </div>
            <!-- テーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="fieldsVehicle"
              :items="tableItemsVehicle"
              @row-selected="onRowSelectedVehicle"
              @row-clicked="onRowClickedVehicle"
            />
          </template>
          <template v-slot:tab3TableDown>
            <div>
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.add_place"
                icon="plus-circle"
                @click="addPlace(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedPlace"
                :columnInfo="detailCtrlInfo.delete_place"
                icon="minus-circle"
                @click="removePlace(obj)"
              />
            </div>
            <!-- テーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="fieldsPlace"
              :items="tableItemsPlace"
              @row-selected="onRowSelectedPlace"
              @row-clicked="onRowClickedPlace"
            />
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 実車番マスタモーダル -->
    <RealVehicleModal
      id="real-vehicle-modal-four"
      type="entry"
      displayModals="four"
      :selectedSearchCondition="realVehicleModalSearchCondition"
      @after-close-set="afterCloseSetRealVehicle"
      @after-close-unset="afterCloseUnsetRealVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      :useFullPlaceKind="true"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import PassagePermitInfo from './PassagePermitInfo'
import {
  getListOptions,
  openSubModal,
  changePlaceKind,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  DEPARTMENT_KIND,
  REAL_VEHICLE_KIND_SEARCH,
  INTEGER_MAX_MIN,
  SLOT_NAME,
} from '@/common/const.js'
import { goRegister, getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  register,
  clear,
  addVehicle,
  removeVehicle,
  addPlace,
  removePlace,
} from '@/master/passagePermit/PassagePermitDetail.js'

const screenId = SCREEN_ID.PASSAGE_PERMIT_DETAIL

export default {
  name: 'passagePermitDetail',
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    RealVehicleModal: () => import('@/master/realVehicle/RealVehicleModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    passagePermitId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      selectLonLat: null,
      formData: new PassagePermitInfo(),
      obj: this,
      registerMessage: null,
      oriData: new PassagePermitInfo(),
      placeModalKbn: -1,
      realVehicleModalKbn: -1,
      departmentModalKbn: -1,
      detailRowIndexVehicle: -1,
      detailRowIndexPlace: -1,
      tableItemsVehicle: [],
      tableItemsPlace: [],
      oriTableItemsVehicle: [],
      oriTableItemsPlace: [],
      selectedVehicle: [],
      selectedPlace: [],
      realVehicleModalSearchCondition: {},
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 許可証情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 許可証ID
              type: 'text',
              id: 'passagePermitId',
              columnInfo: this.detailInfo.passage_permit_id,
            },
            {
              // ブロックコード
              type: 'text',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
            },
            {
              // 運送会社
              type: 'referenceCodeName',
              codeId: 'carrierCode',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_code,
              nameColumnInfo: this.detailInfo.carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_carrier,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 1
                this.departmentModalSearchCondition = {
                  departmentKind: DEPARTMENT_KIND.CARRIER,
                }
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // 許可番号
              type: 'text',
              id: 'permitNo',
              columnInfo: this.detailInfo.permit_no,
              maxLength: 50,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                //this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
            },
            {
              // 有効開始日付
              type: 'date',
              id: 'validBeginDate',
              columnInfo: this.detailInfo.valid_begin_date,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 有効終了日付
              type: 'date',
              id: 'validEndDate',
              columnInfo: this.detailInfo.valid_end_date,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 許可証条件
              type: 'select',
              id: 'permitCondition',
              columnInfo: this.detailInfo.permit_condition,
              options: getListOptions(this.MASTER_CODE.PERMIT_CONDITION),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 通行区分(往復区分)
              type: 'select',
              id: 'onewayOrRound',
              columnInfo: this.detailInfo.oneway_or_round,
              options: getListOptions(this.MASTER_CODE.ONEWAY_OR_ROUND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 誘導者有無(要誘導)
              type: 'select',
              id: 'needGuide',
              columnInfo: this.detailInfo.need_guide,
              options: getListOptions(this.MASTER_CODE.NEED_GUIDE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 通行可能時間帯From
              type: 'time',
              id: 'permitTimeFrom',
              columnInfo: this.detailInfo.permit_time_from,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 通行可能時間帯To
              type: 'time',
              id: 'permitTimeTo',
              columnInfo: this.detailInfo.permit_time_to,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 長さL(許可長さ)
              type: 'text',
              id: 'length',
              columnInfo: this.detailInfo.length,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 幅W(許可幅)
              type: 'text',
              id: 'width',
              columnInfo: this.detailInfo.width,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 高さH(許可高さ)
              type: 'text',
              id: 'height',
              columnInfo: this.detailInfo.height,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 総重量(許可総重量)
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              // 高速使用有無(高速利用可)
              type: 'select',
              id: 'useHighway',
              columnInfo: this.detailInfo.use_highway,
              options: getListOptions(this.MASTER_CODE.HIGHWAY_USE_YN_FLAG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // ルートの説明(経路説明)
              type: 'text',
              id: 'routeDescription',
              columnInfo: this.detailInfo.route_description,
              maxLength: 50,
            },
            {
              // 経路数
              type: 'text',
              id: 'routeCount',
              columnInfo: this.detailInfo.route_count,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // ルート数(総経路数)
              type: 'text',
              id: 'totalRouteCount',
              columnInfo: this.detailInfo.total_route_count,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 担当部門(運送会社)
              type: 'referenceCodeName',
              codeId: 'chargeDepartmentCode',
              nameId: 'chargeDepartmentName',
              codeColumnInfo: this.detailInfo.charge_department_code,
              nameColumnInfo: this.detailInfo.charge_department_name,
              btnColumnInfo: this.detailInfo.sub_modal_charge_department,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 2
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.OWN_COMPANY
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // 新規時許可証番号
              type: 'text',
              id: 'firstPermitNo',
              columnInfo: this.detailInfo.first_permit_no,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.NUMERIC],
            },
            {
              // 前回許可証番号
              type: 'text',
              id: 'previousPermitNo',
              columnInfo: this.detailInfo.previous_permit_no,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.NUMERIC],
            },
            {
              // 予備1（支払済フラグ）
              type: 'select',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve_01,
              options: getListOptions(this.MASTER_CODE.PAID_UP),
              onChangeCallback: () => {},
            },
            {
              // 予備2（支払処理日付）
              type: 'date',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve_02,
              maxLength: 14,
            },
            {
              // 予備3
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve_03,
              maxLength: 50,
            },
            {
              // 予備4
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve_04,
              maxLength: 50,
            },
            {
              // 予備5
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve_05,
              maxLength: 50,
            },
            {
              // 予備6
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve_06,
              maxLength: 50,
            },
            {
              // 予備7
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve_07,
              maxLength: 50,
            },
            {
              // 予備8
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve_08,
              maxLength: 50,
            },
            {
              // 予備9
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve_09,
              maxLength: 50,
            },
            {
              // 予備10
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve_10,
              maxLength: 50,
            },
            // {
            //   // 新規時許可証番号
            //   type: 'text',
            //   id: 'firstPermitNo',
            //   columnInfo: this.detailInfo.first_permit_no,
            //   rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            // },
            // {
            //   // 前回許可証番号
            //   type: 'text',
            //   id: 'previousPermitNo',
            //   columnInfo: this.detailInfo.previous_permit_no,
            //   rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            // },
          ],
        },
        {
          // 許可車両情報タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [
            {
              // トラック/トレーラー区分
              type: 'select',
              id: 'permitRealVehicleKind',
              columnInfo: this.detailInfo.permit_real_vehicle_kind,
              options: getListOptions(this.MASTER_CODE.REAL_VEHICLE_KIND),
              onChangeCallback: () => {},
            },
            {
              // トラック/トラクタ (TR)
              type: 'referenceCodeName',
              codeId: 'realVehicleId',
              nameId: 'realVehicleNumberplate',
              codeColumnInfo: this.detailInfo.real_vehicle_id,
              nameColumnInfo: this.detailInfo.real_vehicle_numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_real_vehicle,
              onClickCallback: () => {
                this.realVehicleModalKbn = 1
                this.realVehicleModalSearchCondition = {}
                this.realVehicleModalSearchCondition.realVehicleKind =
                  REAL_VEHICLE_KIND_SEARCH.TRTC
                this.openSubModal(this, 'real-vehicle-modal-four')
              },
            },
            {
              // トレーラー (TL)
              type: 'referenceCodeName',
              codeId: 'realTrailerId',
              nameId: 'realTrailerNumberplate',
              codeColumnInfo: this.detailInfo.real_trailer_id,
              nameColumnInfo: this.detailInfo.real_trailer_numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_real_trailer,
              onClickCallback: () => {
                this.realVehicleModalKbn = 2
                this.realVehicleModalSearchCondition = {}
                this.realVehicleModalSearchCondition.realVehicleKind =
                  REAL_VEHICLE_KIND_SEARCH.TL
                this.openSubModal(this, 'real-vehicle-modal-four')
              },
            },
          ],
        },
        {
          // 許可場所情報タブ
          label: this.detailInfo.tab3.label,
          slot: SLOT_NAME.TAB3_TABLE_DOWN,
          inputComponents: [
            {
              // 場所区分
              type: 'select',
              id: 'placeKind',
              columnInfo: this.detailInfo.place_kind,
              options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
              onChangeCallback: () => {},
            },
            {
              // 場所
              type: 'referenceCodeName',
              codeId: 'placeId',
              nameId: 'placeName',
              codeColumnInfo: this.detailInfo.place_id,
              nameColumnInfo: this.detailInfo.place_name,
              btnColumnInfo: this.detailInfo.sub_modal_place,
              onClickCallback: () => {
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal')
              },
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsVehicle() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsPlace() {
      return this.$store.getters['init/getFields'](screenId)(
        'result_fields_place'
      )
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },

    isSelectedVehicle() {
      return this.selectedVehicle.length > 0
    },
    isSelectedPlace() {
      return this.selectedPlace.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    addVehicle,
    removeVehicle,
    addPlace,
    removePlace,
    changePlaceKind,

    onRowSelectedVehicle(selectedItems) {
      this.selectedVehicle = selectedItems
    },
    onRowClickedVehicle(item, index) {
      this.detailRowIndexVehicle = index
    },

    onRowSelectedPlace(selectedItems) {
      this.selectedPlace = selectedItems
    },
    onRowClickedPlace(item, index) {
      this.detailRowIndexPlace = index
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      if (this.departmentModalKbn == 1) {
        this.formData.carrierId = id
        this.formData.carrierCode = code
        this.formData.carrierName = name
      } else if (this.departmentModalKbn == 2) {
        this.formData.chargeDepartmentId = id
        this.formData.chargeDepartmentCode = code
        this.formData.chargeDepartmentName = name
      }
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      if (this.departmentModalKbn == 1) {
        this.formData.carrierId = null
        this.formData.carrierCode = null
        this.formData.carrierName = null
      } else if (this.departmentModalKbn == 2) {
        this.formData.chargeDepartmentId = null
        this.formData.chargeDepartmentCode = null
        this.formData.chargeDepartmentName = null
      }
    },

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetRealVehicle(selectedItem) {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.formData.realVehicleId = selectedItem.real_vehicle_id
        this.formData.realVehicleNumberplate = selectedItem.numberplate
        this.formData.realVehicleModel = selectedItem.vehicle_model
        this.formData.realVehicleModelName = selectedItem.vehicle_model_name
        this.formData.realVehicleKind = selectedItem.real_vehicle_kind
        this.formData.realVehicleKindName = selectedItem.real_vehicle_kind_name
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.formData.realTrailerId = selectedItem.real_vehicle_id
        this.formData.realTrailerNumberplate = selectedItem.numberplate
        this.formData.realTrailerModel = selectedItem.vehicle_model
        this.formData.realTrailerModelName = selectedItem.vehicle_model_name
        this.formData.realTrailerKind = selectedItem.real_vehicle_kind
        this.formData.realTrailerKindName = selectedItem.real_vehicle_kind_name
      }

      // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
      this.formData.permitVehicleDtos.sort(function (a, b) {
        return a.permit_vehicle_id - b.permit_vehicle_id
      })
    },
    afterCloseUnsetRealVehicle() {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.formData.realVehicleId = null
        this.formData.realVehicleNumberplate = null
        this.formData.realVehicleModel = null
        this.formData.realVehicleModelName = null
        this.formData.realVehicleKind = null
        this.formData.realVehicleKindName = null
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.formData.realTrailerId = null
        this.formData.realTrailerNumberplate = null
        this.formData.realTrailerModel = null
        this.formData.realTrailerModelName = null
        this.formData.realTrailerKind = null
        this.formData.realTrailerKindName = null
      }
      // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
      this.formData.permitVehicleDtos.sort(function (a, b) {
        return a.permit_vehicle_id - b.permit_vehicle_id
      })
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.formData.placeId = selectedItem.place_id
        //this.formData.placeKind = selectedItem.place_kind
        //搬出場所区分
        let placeType = changePlaceKind(selectedItem.place_kind)
        this.formData.placeKind = placeType
        this.formData.placeKindName = selectedItem.place_kind_name
        this.formData.placeCode = selectedItem.place_code
        this.formData.placeName = selectedItem.place_name
        this.formData.address1 = selectedItem.address1
        this.formData.address2 = selectedItem.address2
        this.formData.postalCode = selectedItem.postal_code
        this.formData.latitude = selectedItem.latitude
        this.formData.longitude = selectedItem.longitude
      }
      // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
      this.formData.permitRoutePlaceDtos.sort(function (a, b) {
        return a.permit_route_place_id - b.permit_route_place_id
      })
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.formData.placeId = null
        this.formData.placeKind = null
        this.formData.placeKindName = null
        this.formData.placeCode = null
        this.formData.placeName = null
        this.formData.address1 = null
        this.formData.address2 = null
        this.formData.postalCode = null
        this.formData.latitude = null
        this.formData.longitude = null
      }
      this.$forceUpdate()
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },

  // mounted() {
  //     this.$refs.map.initialize()
  // }
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}

.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
