export default class department {
  departmentId
  blockCode = null
  publicFlg = null
  publicFlgName = null
  departmentCode = null
  departmentName = null
  tel = null
  fax = null
  note = null
  departmentKind = null
  departmentKindName = null
  accountingWorkKind = null
  accountingWorkKindName = null
  tariffId = null
  tariffCode = null
  tariffName = null
  isOnetimeMaster = '0'
  isOnetimeMasterName = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  departmentGroup = null
  orderAmountKind = null
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  contactEmailDtos = []
}
