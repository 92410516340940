import { openMsgModal, openSubModal, formatYYYYMM } from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import router from '@/router'
import CalendarService from './CalendarService'
import _ from 'lodash'

const screenId = SCREEN_ID.CALENDAR_LIST
const detailScreenId = SCREEN_ID.CALENDAR_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  const searchCondition = _.cloneDeep(obj.searchCondition)

  searchCondition.calendarPeriodFrom = formatYYYYMM(
    obj,
    searchCondition.calendarPeriodFrom
  )
  searchCondition.calendarPeriodTo = formatYYYYMM(
    obj,
    searchCondition.calendarPeriodTo
  )

  CalendarService.search(searchCondition).then(
    (response) => {
      obj.tableItems = response.data.resultList
      if (obj.tableItems.length < 1) {
        // 警告モーダル表示
        openMsgModal(
          obj,
          MSG_TYPE.W,
          obj.$store.getters['init/getMessage']('YZ00MG907I')
        )
      }
    },
    (e) => {
      var msg
      if (e.response == undefined) {
        msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
      } else {
        msg = e.response.data.message
      }
      // エラーモーダル表示
      openMsgModal(obj, MSG_TYPE.E, msg)
    }
  )

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        selectedItem: obj.selectedItem[0],
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        selectedItem: obj.selectedItem[0],
        screenKbn: DETAIL_SCREEN_KBN.COPY,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「削除」ボタンクリック
export function goRemove(obj) {
  let errorMsg = false
  //標準カレンダーのレコードを含んでいるかチェック
  obj.selectedItem.some((element) => {
    if (element.calendar_id == 0) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('MJ01S001_MG001E')
      )
      errorMsg = true
      return true
    }
  })

  obj.selectedItem.some((element) => {
    // 削除不可のデータのため、削除できません。
    if (element.delete_enable_kind == '1') {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EF01S001_MG001E')
      )
      errorMsg = true
      return true
    }
  })

  obj.selectedItem.some((element) => {
    // 該当データは既に削除されています。
    if (element.deleted == '1') {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('YZ00MG904E')
      )
      errorMsg = true
      return true
    }
  })

  if (errorMsg) {
    return
  }

  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.delete.label
  )
  openSubModal(obj, 'remove-modal')
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      const calendarId = obj.selectedItem[index].calendar_id
      const yymm = obj.selectedItem[index].yymm
      obj.$store.dispatch('init/setServiceLoading')
      await CalendarService.detail(calendarId, yymm)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await CalendarService.removeList(removeList)
        .then(
          () => {
            // 警告モーダル表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}
