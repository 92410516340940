import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import VehicleGroupService from './VehicleGroupService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    VehicleGroupService.get(obj.vehicleGroupId)
      .then(
        (response) => {
          obj.VehicleGroup = response.data

          //details
          var vehicles = convertSnakeCace(obj.VehicleGroup.vehicles)

          vehicles.sort(function (a, b) {
            return a.vehicle_id - b.vehicle_id
          })

          obj.VehicleGroup.vehicles = vehicles

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.VehicleGroup.vehicleGroupId = null
            obj.VehicleGroup.deleted = '0'
            obj.VehicleGroup.registrationTimeDisplay = null
            obj.VehicleGroup.registrationUser = null
            obj.VehicleGroup.updateTimeDisplay = null
            obj.VehicleGroup.updateUser = null
            obj.tableItems = obj.VehicleGroup.vehicles
            //obj.VehicleGroup.id = null
          } else {
            obj.VehicleGroup.vehicleGroupId = obj.vehicleGroupId
            obj.tableItems = obj.VehicleGroup.vehicles
          }
          obj.oriData = _.cloneDeep(obj.VehicleGroup)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.VehicleGroup.vehicles = changeCamelCase(obj)
  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = VehicleGroupService.create(obj.VehicleGroup)
  } else {
    // 詳細
    callApi = VehicleGroupService.update(obj.vehicleGroupId, obj.VehicleGroup)
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'vehiclegroup-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  if (obj.VehicleGroup.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }
  openSubModal(obj, 'register-modal')
}

export function remove(obj) {
  // 複数個削除
  for (let info of obj.selectedItem) {
    const index = obj.VehicleGroup.vehicles.indexOf(info)
    obj.VehicleGroup.vehicles.splice(index, 1)
  }
}

export function clear(obj) {
  obj.VehicleGroup = _.cloneDeep(obj.oriData)
  obj.tableItems = obj.VehicleGroup.vehicles || []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function changeCamelCase(obj) {
  var vehicles_camel = convertCamelCace(obj.VehicleGroup.vehicles)

  return vehicles_camel
}
