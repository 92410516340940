import VehicleService from './VehicleService'
import {
  openMsgModal,
  getMasterName,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MASTER_CODE, MSG_TYPE, PLACE_LEVEL } from '@/common/const.js'
import _ from 'lodash'

export function init(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  VehicleService.getInflowImpossible(obj.vehicleId)
    .then(
      (response) => {
        obj.InflowImpossible = response.data

        // inflowImpossibles
        var inflowImpossibles = convertSnakeCace(
          obj.InflowImpossible.inflowImpossibles
        )

        obj.InflowImpossible.inflowImpossibles = inflowImpossibles
        obj.tableItems = obj.InflowImpossible.inflowImpossibles

        console.log('obj.tableItems', obj.tableItems)

        obj.oriData = _.cloneDeep(obj.InflowImpossible)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.InflowImpossible = _.cloneDeep(obj.oriData)
  obj.tableItems = _.cloneDeep(obj.oriData.inflowImpossibles)
}

export function addPrefecture(obj, selectedPrefectures) {
  // 都道府県を、流入不可登録区分 = 10(都道府県)として新規行(最下部)に反映する
  // 既に流入不可一覧に同じデータが存在している場合は、場所一覧に追加しない。
  if (selectedPrefectures != undefined && selectedPrefectures.length != 0) {
    selectedPrefectures.forEach((element) => {
      let info = {
        // 流入不可登録区分（コード）
        inflow_impossible_entry_kind: PLACE_LEVEL.PREFECTURE,
        // 流入不可登録区分
        inflow_impossible_entry_kind_name: getMasterName(
          MASTER_CODE.PLACE_LEVEL,
          PLACE_LEVEL.PREFECTURE
        ),
        // 都道府県コード
        prefecture_code: element.prefecture_code,
        // 都道府県名
        prefecture_name: element.prefecture_name,
        // 削除済み区分（コード）
        deleted: 0,
        // 削除済み区分
        deleted_name: getMasterName(MASTER_CODE.DELETED, 0),
      }

      let exists = false
      obj.tableItems.forEach((item) => {
        if (
          item.inflow_impossible_entry_kind == PLACE_LEVEL.PREFECTURE &&
          item.prefecture_code == element.prefecture_code
        ) {
          exists = true
          return true
        }
      })

      if (exists == false) {
        obj.tableItems.push(info)
        obj.isDisabled = false
      }
    })
  }
}

export function addCity(obj, selectedCities) {
  if (selectedCities != undefined && selectedCities.length != 0) {
    selectedCities.forEach((element) => {
      let info = {
        // 流入不可登録区分（コード）
        inflow_impossible_entry_kind: PLACE_LEVEL.CITY,
        // 流入不可登録区分
        inflow_impossible_entry_kind_name: getMasterName(
          MASTER_CODE.PLACE_LEVEL,
          PLACE_LEVEL.CITY
        ),
        // 都道府県コード
        prefecture_code: element.prefecture_code,
        // 都道府県名
        prefecture_name: element.prefecture_name,
        //市区町村コード
        city_code: element.city_code,
        // 市区町村名
        city_name: element.city_name,
        // 削除済み区分（コード）
        deleted: 0,
        // 削除済み区分
        deleted_name: getMasterName(MASTER_CODE.DELETED, 0),
      }

      let exists = false
      obj.tableItems.forEach((item) => {
        if (
          item.inflow_impossible_entry_kind == PLACE_LEVEL.CITY &&
          item.city_code == element.city_code
        ) {
          exists = true
          return true
        }
      })

      if (exists == false) {
        obj.tableItems.push(info)
        obj.isDisabled = false
      }
    })
  }
}

export function addPlace(obj, selectedPlaces) {
  if (selectedPlaces != undefined && selectedPlaces.length != 0) {
    selectedPlaces.forEach((element) => {
      let info = {
        // 流入不可登録区分（コード）
        inflow_impossible_entry_kind: PLACE_LEVEL.PLACE,
        // 流入不可登録区分
        inflow_impossible_entry_kind_name: getMasterName(
          MASTER_CODE.PLACE_LEVEL,
          PLACE_LEVEL.PLACE
        ),
        // 都道府県コード
        prefecture_code: element.prefecture_code,
        // 都道府県名
        prefecture_name: element.prefecture_name,
        //市区町村コード
        city_code: element.city_code,
        // 市区町村名
        city_name: element.city_name,
        // 場所ID
        place_id: element.place_id,
        // 場所コード
        place_code: element.place_code,
        // 場所名
        place_name: element.place_name,
        // 住所：都道府県名＋市区町村名＋町域名＋建物名
        address:
          element.prefecture_name +
          element.city_name +
          element.address1 +
          element.address2,
        // 備考
        note: element.note,
        // 削除済み区分（コード）
        deleted: 0,
        // 削除済み区分
        deleted_name: getMasterName(MASTER_CODE.DELETED, 0),
      }

      let exists = false
      obj.tableItems.forEach((item) => {
        if (
          item.inflow_impossible_entry_kind == PLACE_LEVEL.PLACE &&
          item.place_id == element.place_id
        ) {
          exists = true
          return true
        }
      })

      if (exists == false) {
        obj.tableItems.push(info)
        obj.isDisabled = false
      }
    })
  }
}

export function remove(obj) {
  // 流入不可一覧の選択行が存在しない場合、エラーを出力し、以降の処理は行わない。
  if (obj.selectedItem.length == 0) {
    // 流入不可一覧より行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MC02S001_MG003E')
    )
    return
  }

  // 選択行を流入不可一覧より、削除する。
  obj.selectedItem.forEach((element) => {
    const index = obj.tableItems.indexOf(element)
    obj.tableItems.splice(index, 1)
  })
  obj.isDisabled = false
}

export function goRegister(obj) {
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export function register(obj) {
  obj.$bvModal.hide('register-modal')

  let registerData = _.cloneDeep(obj.InflowImpossible)
  registerData.inflowImpossibles = changeToCamelCaseKey(
    registerData.inflowImpossibles
  )

  obj.$store.dispatch('init/setServiceLoading')
  VehicleService.registerInflowImpossible(registerData.vehicleId, registerData)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'inflowImpossible-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function changeToCamelCaseKey(items) {
  var camelItems = convertCamelCace(items)

  return camelItems
}
