/**
 * 流入不可マスタデータ.
 * @class
 * @exports
 */
export default class InflowImpossible {
  inflowImpossibleId = null
  vehicleId = null
  vehicleName = null
  inflowImpossibleEntryKind = null
  prefectureCode = null
  cityCode = null
  placeId = null
  note = null
  deleted = null
  registration_time = null
  registration_user = null
  update_time = null
  update_user = null
  update_by_trigger = null
  carrierCode = null
  carrierName = null
  numberplate = null
  vehicleCategory = null
  vehicleModel = null
}
