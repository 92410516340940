/**
 * 運賃項目
 */
export default class freightItem {
  freightItemId
  blockCode = null
  blockName = null
  //運賃項目名
  freightItemName = null
  //請求支払区分
  costType = null
  costTypeName = null
  //品目コード
  interfaceKey = null
  //作業区分
  workType = null
  //売上区分
  salesType = null
  //税区分
  taxType = null
  //税区分
  taxCategory = null
  taxCategoryName = null
  // 税率
  taxPercentage = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  departmentGroup = null //#2786
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null
}
