<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #side>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
      <template #main>
        <b-button
          v-if="getVisibleFromColumnInfo(detailCtrlInfo.position_select)"
          :disabled="getDisableFromColumnInfo(detailCtrlInfo.position_select)"
          class="base-button"
          size="sm"
          :pressed.sync="_isPointSelectMode"
          :variant="togglePointSelectVariant"
          >{{ detailCtrlInfo.position_select.label }}</b-button
        >
        <div class="mt-1">
          <span>{{ editMessage }}</span>
        </div>
        <div id="BaseView" class="mt-1">
          <BaseMap
            :center="center"
            :markerPosition="markerPosition"
            @clicked="onMapClicked"
          />
        </div>
      </template>
    </DetailPageLayout>
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal-edit"
      type="entry"
      displayModals="two"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 郵便番号モーダル -->
    <PostalCodeModal
      id="postal-code-modal"
      type="entry"
      @after-close-set="afterCloseSetPostalCode"
      @after-close-unset="afterCloseUnsetPostalCode"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 市区町村モーダル -->
    <CityModal
      id="city-modal"
      type="entry"
      :selectedSearchCondition.sync="cityModalSearchCondition"
      @after-close-set="afterCloseSetCity"
      @after-close-unset="afterCloseUnsetCity"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import PlaceInfo from './PlaceInfo'
import {
  getListOptions,
  getMasterName,
  openSubModal,
  checkCoordinatesRange,
  getDefaultCoordinates,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  DEPARTMENT_KIND,
  VISIBILITY,
  INTEGER_MAX_MIN,
} from '@/common/const.js'
import {
  clear,
  goRegister,
  getBreadcrumbItems,
  back,
  getVisibleFromColumnInfo,
  getDisableFromColumnInfo,
} from '@/common/screen-common.js'
import {
  init,
  register,
  convertAddressToCoordinates,
} from '@/master/place/PlaceDetail.js'

const screenId = SCREEN_ID.PLACE_DETAIL

export default {
  name: 'placeDetail',

  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    PostalCodeModal: () => import('@/master/postalCode/PostalCodeModal.vue'),
    CityModal: () => import('@/master/city/CityModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    placeId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      selectLonLat: null,
      formData: new PlaceInfo(),
      obj: this,
      registerMessage: null,
      oriData: new PlaceInfo(),
      cityModalSearchCondition: {},
      departmentModalSearchCondition: {},
      placeModalKbn: -1,
      isPointSelectMode: false,
      editMessage: '',
    }
  },

  created() {
    init(this)
  },

  computed: {
    _isPointSelectMode: {
      get() {
        return this.isPointSelectMode
      },
      set(v) {
        this.isPointSelectMode = v
        if (this.isPointSelectMode === true) {
          this.editMessage = '緯度経度を設定する位置を選択してください。'
        } else {
          this.editMessage = ''
        }
      },
    },
    togglePointSelectVariant() {
      return this.isPointSelectMode ? 'danger' : 'primary'
    },

    center() {
      if (
        checkCoordinatesRange(this.formData.latitude, this.formData.longitude)
      ) {
        return [this.formData.latitude, this.formData.longitude]
      } else {
        return getDefaultCoordinates()
      }
    },
    markerPosition() {
      if (
        checkCoordinatesRange(this.formData.latitude, this.formData.longitude)
      ) {
        return [this.formData.latitude, this.formData.longitude]
      } else {
        return getDefaultCoordinates()
      }
    },
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      let detailInfo = this.$store.getters['init/getScreens'](screenId)(
        'detail_info'
      )

      // 常に非活性項目
      detailInfo.accuracy_level.visibility = VISIBILITY.DISABLE
      detailInfo.representative_place_code.visibility = VISIBILITY.DISABLE
      detailInfo.representative_place_name.visibility = VISIBILITY.DISABLE

      return detailInfo
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 住所位置変換ボタン
          {
            variant: 'primary',
            icon: 'map-marker',
            columnInfo: this.detailCtrlInfo.address_position_conversion,
            onClickCallback: () => {
              convertAddressToCoordinates(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 場所タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // ブロック外公開フラグ
              type: 'select',
              id: 'publicFlg',
              columnInfo: this.detailInfo.public_flg,
              options: getListOptions(this.MASTER_CODE.PUBLIC_FLG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 場所コード
              type: 'text',
              id: 'placeCode',
              columnInfo: this.detailInfo.place_code,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
            },
            {
              // 場所名
              type: 'text',
              id: 'placeName',
              columnInfo: this.detailInfo.place_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            // {
            //   // 配車計画グループ
            //   type: 'select',
            //   id: 'planningGroupCode',
            //   columnInfo: this.detailInfo.planning_group_code,
            //   options: getListOptions(this.MASTER_CODE.PLANNING_GROUP),
            //   onChangeCallback: () => {},
            // },
            {
              // 荷主
              type: 'referenceCodeName',
              codeId: 'ownerCode',
              nameId: 'ownerName',
              codeColumnInfo: this.detailInfo.owner_code,
              nameColumnInfo: this.detailInfo.owner_name,
              btnColumnInfo: this.detailInfo.sub_modal_owner,
              onClickCallback: () => {
                this.departmentModalSearchCondition = {
                  departmentKind: DEPARTMENT_KIND.OWNER,
                }
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // 郵便番号
              type: 'referenceCode',
              codeId: 'postalCode',
              codeColumnInfo: this.detailInfo.postal_code,
              btnColumnInfo: this.detailInfo.sub_modal_postal,
              codeRules: [this.INPUT_TYPE_CHECK.ZIPNUMERIC],
              onClickCallback: () => {
                this.openSubModal(this, 'postal-code-modal')
              },
            },
            {
              // 市区町村
              type: 'referenceCode',
              codeId: 'cityCode',
              codeColumnInfo: this.detailInfo.city_code,
              btnColumnInfo: this.detailInfo.sub_modal_city,
              onClickCallback: () => {
                this.cityModalSearchCondition = {}
                this.openSubModal(this, 'city-modal')
              },
            },
            {
              // 住所1
              type: 'text',
              id: 'address1',
              columnInfo: this.detailInfo.address1,
            },
            {
              // 住所2
              type: 'text',
              id: 'address2',
              columnInfo: this.detailInfo.address2,
            },
            {
              // 電話番号
              type: 'text',
              id: 'tel',
              columnInfo: this.detailInfo.tel,
              rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC],
            },
            {
              // ＦＡＸ番号
              type: 'text',
              id: 'fax',
              columnInfo: this.detailInfo.fax,
              rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC],
            },
            {
              // 連絡先担当者名
              type: 'text',
              id: 'representativeName',
              columnInfo: this.detailInfo.representative_name,
            },
            {
              // 緯度
              type: 'text',
              id: 'latitude',
              columnInfo: this.detailInfo.latitude,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              // 経度
              type: 'text',
              id: 'longitude',
              columnInfo: this.detailInfo.longitude,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              // 位置変換済区分
              type: 'select',
              id: 'accuracyLevel',
              columnInfo: this.detailInfo.accuracy_level,
              options: getListOptions(this.MASTER_CODE.ACCURACY_LEVEL),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 場所区分
              type: 'select',
              id: 'placeKind',
              columnInfo: this.detailInfo.place_kind,
              options: getListOptions(this.MASTER_CODE.PLACE_KIND_SEARCH),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // 出発タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 出発開始時刻１
              type: 'time',
              id: 'departureBeginTime',
              columnInfo: this.detailInfo.departure_begin_time,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発終了時刻１
              type: 'time',
              id: 'departureEndTime',
              columnInfo: this.detailInfo.departure_end_time,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発準備時間１
              type: 'text',
              id: 'departurePreparationTime',
              columnInfo: this.detailInfo.departure_preparation_time,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 出発単位時間１
              type: 'text',
              id: 'departureUnitTime',
              columnInfo: this.detailInfo.departure_unit_time,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 出発時間厳格度１
              type: 'select',
              id: 'departureTimeStrict',
              columnInfo: this.detailInfo.departure_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              onChangeCallback: () => {},
            },
            {
              // 出発不可開始時刻１Ａ
              type: 'time',
              id: 'impossibleDepartureBeginTime1',
              columnInfo: this.detailInfo.impossible_departure_begin_time_1,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発不可終了時刻１Ａ
              type: 'time',
              id: 'impossibleDepartureEndTime1',
              columnInfo: this.detailInfo.impossible_departure_end_time_1,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発不可開始時刻１Ｂ
              type: 'time',
              id: 'impossibleDepartureBeginTime2',
              columnInfo: this.detailInfo.impossible_departure_begin_time_2,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発不可終了時刻１Ｂ
              type: 'time',
              id: 'impossibleDepartureEndTime2',
              columnInfo: this.detailInfo.impossible_departure_end_time_2,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発不可開始時刻１Ｃ
              type: 'time',
              id: 'impossibleDepartureBeginTime3',
              columnInfo: this.detailInfo.impossible_departure_begin_time_3,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 出発不可終了時刻１Ｃ
              type: 'time',
              id: 'impossibleDepartureEndTime3',
              columnInfo: this.detailInfo.impossible_departure_end_time_3,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
          ],
        },
        {
          // 到着タブ
          label: this.detailInfo.tab3.label,
          inputComponents: [
            {
              // 到着開始時刻１
              type: 'time',
              id: 'arrivalBeginTime',
              columnInfo: this.detailInfo.arrival_begin_time,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着終了時刻１
              type: 'time',
              id: 'arrivalEndTime',
              columnInfo: this.detailInfo.arrival_end_time,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着準備時間１
              type: 'text',
              id: 'arrivalPreparationTime',
              columnInfo: this.detailInfo.arrival_preparation_time,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 到着単位時間１
              type: 'text',
              id: 'arrivalUnitTime',
              columnInfo: this.detailInfo.arrival_unit_time,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 到着時間厳格度１
              type: 'select',
              id: 'arrivalTimeStrict',
              columnInfo: this.detailInfo.arrival_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              onChangeCallback: () => {},
            },
            {
              // 到着不可開始時刻１Ａ
              type: 'time',
              id: 'impossibleArrivalBeginTime1',
              columnInfo: this.detailInfo.impossible_arrival_begin_time_1,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着不可終了時刻１Ａ
              type: 'time',
              id: 'impossibleArrivalEndTime1',
              columnInfo: this.detailInfo.impossible_arrival_end_time_1,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着不可開始時刻１Ｂ
              type: 'time',
              id: 'impossibleArrivalBeginTime2',
              columnInfo: this.detailInfo.impossible_arrival_begin_time_2,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着不可終了時刻１Ｂ
              type: 'time',
              id: 'impossibleArrivalEndTime2',
              columnInfo: this.detailInfo.impossible_arrival_end_time_2,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着不可開始時刻１Ｃ
              type: 'time',
              id: 'impossibleArrivalBeginTime3',
              columnInfo: this.detailInfo.impossible_arrival_begin_time_3,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
            {
              // 到着不可終了時刻１Ｃ
              type: 'time',
              id: 'impossibleArrivalEndTime3',
              columnInfo: this.detailInfo.impossible_arrival_end_time_3,
              rules: [this.INPUT_TYPE_CHECK.TIME],
            },
          ],
        },
        {
          // 荷積タブ
          label: this.detailInfo.tab4.label,
          inputComponents: [
            {
              // 入構制限
              type: 'select',
              id: 'maxAvailableEntrySize',
              columnInfo: this.detailInfo.max_available_entry_size,
              options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
              onChangeCallback: () => {},
            },
            {
              // 荷積バース数
              type: 'text',
              id: 'berthCount',
              columnInfo: this.detailInfo.berth_count,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 荷積評価用固定費
              type: 'text',
              id: 'fixedCost',
              columnInfo: this.detailInfo.fixed_cost,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 荷積評価用変動費
              type: 'text',
              id: 'variableCost',
              columnInfo: this.detailInfo.variable_cost,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
          ],
        },
        {
          // 輸送手段タブ
          label: this.detailInfo.tab5.label,
          inputComponents: [
            {
              // 輸送手段条件１
              type: 'select',
              id: 'vehicleCondition01',
              columnInfo: this.detailInfo.vehicle_condition01,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２
              type: 'select',
              id: 'vehicleCondition02',
              columnInfo: this.detailInfo.vehicle_condition02,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件３
              type: 'select',
              id: 'vehicleCondition03',
              columnInfo: this.detailInfo.vehicle_condition03,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件４
              type: 'select',
              id: 'vehicleCondition04',
              columnInfo: this.detailInfo.vehicle_condition04,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件５
              type: 'select',
              id: 'vehicleCondition05',
              columnInfo: this.detailInfo.vehicle_condition05,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件６
              type: 'select',
              id: 'vehicleCondition06',
              columnInfo: this.detailInfo.vehicle_condition06,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件７
              type: 'select',
              id: 'vehicleCondition07',
              columnInfo: this.detailInfo.vehicle_condition07,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件８
              type: 'select',
              id: 'vehicleCondition08',
              columnInfo: this.detailInfo.vehicle_condition08,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件９
              type: 'select',
              id: 'vehicleCondition09',
              columnInfo: this.detailInfo.vehicle_condition09,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１０
              type: 'select',
              id: 'vehicleCondition10',
              columnInfo: this.detailInfo.vehicle_condition10,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１１
              type: 'select',
              id: 'vehicleCondition11',
              columnInfo: this.detailInfo.vehicle_condition11,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１２
              type: 'select',
              id: 'vehicleCondition12',
              columnInfo: this.detailInfo.vehicle_condition12,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１３
              type: 'select',
              id: 'vehicleCondition13',
              columnInfo: this.detailInfo.vehicle_condition13,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１４
              type: 'select',
              id: 'vehicleCondition14',
              columnInfo: this.detailInfo.vehicle_condition14,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１５
              type: 'select',
              id: 'vehicleCondition15',
              columnInfo: this.detailInfo.vehicle_condition15,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１６
              type: 'select',
              id: 'vehicleCondition16',
              columnInfo: this.detailInfo.vehicle_condition16,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１７
              type: 'select',
              id: 'vehicleCondition17',
              columnInfo: this.detailInfo.vehicle_condition17,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１８
              type: 'select',
              id: 'vehicleCondition18',
              columnInfo: this.detailInfo.vehicle_condition18,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１９
              type: 'select',
              id: 'vehicleCondition19',
              columnInfo: this.detailInfo.vehicle_condition19,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２０
              type: 'select',
              id: 'vehicleCondition20',
              columnInfo: this.detailInfo.vehicle_condition20,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // その他タブ
          label: this.detailInfo.tab6.label,
          inputComponents: [
            {
              // 代表場所コード
              type: 'text',
              id: 'representativePlaceCode',
              columnInfo: this.detailInfo.representative_place_code,
            },
            {
              // 代表場所名
              type: 'text',
              id: 'representativePlaceName',
              columnInfo: this.detailInfo.representative_place_name,
            },
            {
              // 場所纏め区分
              type: 'select',
              id: 'placeType',
              columnInfo: this.detailInfo.place_type,
              options: getListOptions(this.MASTER_CODE.PLACE_TYPE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 積置きフラグ
              type: 'select',
              id: 'preloadable',
              columnInfo: this.detailInfo.preloadable,
              options: getListOptions(this.MASTER_CODE.PRELOADABLE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 最寄IC
              type: 'referenceCodeName',
              codeId: 'nearestHighwayIcCode',
              nameId: 'nearestHighwayIcName',
              codeColumnInfo: this.detailInfo.nearest_highway_ic_code,
              nameColumnInfo: this.detailInfo.nearest_highway_ic_name,
              btnColumnInfo: this.detailInfo.sub_modal_highway_ic,
              onClickCallback: () => {
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal-edit')
              },
            },
            {
              // カレンダーID
              type: 'select',
              id: 'calendarId',
              columnInfo: this.detailInfo.calendar_id,
              options: getListOptions(this.MASTER_CODE.CALENDAR),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // マスタ区分
              type: 'select',
              id: 'isOnetimeMaster',
              columnInfo: this.detailInfo.is_onetime_master,
              options: getListOptions(this.MASTER_CODE.IS_ONETIME_MASTER),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 場所予備1
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
            },
            {
              // 場所予備2
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
            },
            {
              // 場所予備3
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
            },
            {
              // 場所予備4
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
            },
            {
              // 場所予備5
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
            },
            {
              // 場所予備6
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
            },
            {
              // 場所予備7
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              // 場所予備8
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              // 場所予備9
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              // 場所予備10
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // バージョン
              type: 'text',
              id: 'version',
              columnInfo: this.detailInfo.version,
            },
            {
              // トリガ更新日時
              type: 'text',
              id: 'updateByTrigger',
              columnInfo: this.detailInfo.update_by_trigger,
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    getMasterName,
    openSubModal,
    register,
    back,
    convertAddressToCoordinates,
    checkCoordinatesRange,
    getVisibleFromColumnInfo,
    getDisableFromColumnInfo,
    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.formData.ownerId = id
      this.formData.ownerCode = code
      this.formData.ownerName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.formData.ownerId = null
      this.formData.ownerCode = null
      this.formData.ownerName = null
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.formData.nearestHighwayIcId = selectedItem.place_id
        this.formData.nearestHighwayIcCode = selectedItem.place_code
        this.formData.nearestHighwayIcName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.formData.nearestHighwayIcId = null
        this.formData.nearestHighwayIcCode = null
        this.formData.nearestHighwayIcName = null
      }
      this.$forceUpdate()
    },

    /**
     * 郵便番号マスタから取得データ設定
     */
    afterCloseSetPostalCode(selectedItem) {
      // 郵便番号
      this.formData.postalCode = selectedItem.postal_code
      //市区町村コード
      this.formData.cityCode = selectedItem.city_code
      // 市区町村名
      this.formData.cityName = selectedItem.city_name
      // 住所1
      this.formData.address1 =
        selectedItem.prefecture_name + selectedItem.city_name
      // 住所2
      this.formData.address2 = selectedItem.town_name
      // 緯度
      this.formData.latitude = selectedItem.latitude
      // 経度
      this.formData.longitude = selectedItem.longitude
      // 位置変換区分
      if (selectedItem.accuracy_level) {
        this.formData.accuracyLevel = selectedItem.accuracy_level
      } else {
        this.formData.accuracyLevel = '0' // 未設定
      }
    },
    /**
     * 郵便番号情報解除
     */
    afterCloseUnsetPostalCode() {
      // 郵便番号
      this.formData.postalCode = null
      //市区町村コード
      this.formData.cityCode = null
      // 市区町村名
      this.formData.cityName = null
      // 住所1
      this.formData.address1 = null
      // 住所2
      this.formData.address2 = null
      // 緯度
      this.formData.latitude = null
      // 経度
      this.formData.longitude = null
      // 位置変換区分
      this.formData.accuracyLevel = '0' // 未設定
    },

    /**
     * 市区町村マスタから取得データ設定
     */
    afterCloseSetCity(selectedItem) {
      console.log('selectedItem', selectedItem)
      // 郵便番号
      this.formData.postalCode = null
      //市区町村コード
      this.formData.cityCode = selectedItem.city_code
      // 市区町村名
      this.formData.cityName = selectedItem.city_name
      // 住所1
      this.formData.address1 = selectedItem.address1
      // 住所2
      this.formData.address2 = null
      // 緯度
      this.formData.latitude = selectedItem.latitude
      // 経度
      this.formData.longitude = selectedItem.longitude
      // 位置変換区分
      if (selectedItem.accuracy_level) {
        this.formData.accuracyLevel = selectedItem.accuracy_level
      } else {
        this.formData.accuracyLevel = '0' // 未設定
      }
    },
    /**
     * 市区町村情報解除
     */
    afterCloseUnsetCity() {
      // 郵便番号
      this.formData.postalCode = null
      //市区町村コード
      this.formData.cityCode = null
      // 市区町村名
      this.formData.cityName = null
      // 住所1
      this.formData.address1 = null
      // 住所2
      this.formData.address2 = null
      // 緯度
      this.formData.latitude = null
      // 経度
      this.formData.longitude = null
      // 位置変換区分
      this.formData.accuracyLevel = '0' // 未設定
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onMapClicked(latlng) {
      if (this._isPointSelectMode) {
        this.formData.latitude = parseFloat(latlng.lat).toFixed(5)
        this.formData.longitude = parseFloat(latlng.lng).toFixed(5)
        // 位置変換区分
        this.formData.accuracyLevel = '6' // 号以上
        this._isPointSelectMode = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 700px;
}

.base-button {
  font-size: 0.8rem;
  margin-right: 4px;
  height: 32px;
}
</style>
