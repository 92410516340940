/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class tariff {
  //--------------TAB1------------//
  tariffId
  branchNo = null
  adjustmentMoneyKind = null
  blockCode = null
  tariffCode = null
  tariffName = null
  isPayment = null
  adjustmentMoneyRate = null
  validBeginDate = null
  validEndDate = null
  deleted = 0
  tariffValidPeriodDtos = []
  surchargeDtos = []
  //--------------TAB1------------//

  //--------------TAB2------------//

  //--------------TAB2------------//

  //--------------TAB3------------//

  //--------------TAB3------------//
}
