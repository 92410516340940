<template>
  <div>
    <!-- <MapView v-on:mapClick="getLonLat" ref="map"/> -->
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="VehicleGroup"
          :inputComponents="inputComponents"
          @clear="clear(obj)"
        ></BaseTabGroup>
      </template>
      <template #bottom>
        <div class="data-group-role">
          <div id="BaseView">
            <section data-class="work-list-table">
              <div>
                <BaseButton
                  class="edit-button"
                  variant="success"
                  :columnInfo="detailCtrlInfo.add"
                  icon="plus-circle"
                  @click="openSubModal(obj, 'vehicle-modal')"
                />
                <BaseButton
                  class="edit-button"
                  variant="success"
                  :disabled="!selectedItem"
                  :columnInfo="detailCtrlInfo.delete"
                  icon="minus-circle"
                  @click="remove(obj)"
                />
              </div>
              <!-- テーブル -->
              <BaseTable
                select-mode="range"
                :fields="fields"
                :items="tableItems"
                @row-selected="onRowSelected"
              />
            </section>
          </div>
        </div>
      </template>
    </DetailPageLayout>
    <!-- 車両マスタモーダル -->
    <VehicleModal
      id="vehicle-modal"
      type="entry"
      :useUnset="false"
      @after-close-set="afterCloseSetVehicle"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="vehiclegroup-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import VehicleGroupInfo from './VehicleGroupInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  goRegister,
  register,
  remove,
  clear,
} from '@/master/vehicleGroup/VehicleGroupDetail.js'

export default {
  name: 'vehicleGroupDetail',

  components: {
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    vehicleGroupId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      tableItems: [],
      selectedItem: [],
      VehicleGroup: new VehicleGroupInfo(),
      obj: this,
      registerMessage: null,
      oriData: new VehicleGroupInfo(),
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.VEHICLE_GROUP_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      let detailInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.VEHICLE_GROUP_DETAIL
      )('detail_info')

      if (this.screenKbn == this.DETAIL_SCREEN_KBN.DETAIL) {
        detailInfo.vehicle_group_id.visibility = this.VISIBILITY.DISABLE
      }

      return detailInfo
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.VEHICLE_GROUP_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 車両パターンタブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 車両パターンID
              type: 'text',
              id: 'vehicleGroupId',
              columnInfo: this.detailInfo.vehicle_group_id,
            },
            {
              // 車両パターン名
              type: 'text',
              id: 'vehicleGroupName',
              columnInfo: this.detailInfo.vehicle_group_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // ブロックコード
              type: 'text',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // バージョン
              type: 'text',
              id: 'version',
              columnInfo: this.detailInfo.version,
            },
            {
              // トリガ更新日時
              type: 'text',
              id: 'updateByTrigger',
              columnInfo: this.detailInfo.update_by_trigger,
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.VEHICLE_GROUP_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    remove,
    clear,

    /**
     * 車両マスタ参照から取得データ設定
     */
    afterCloseSetVehicle(vehicleInfos) {
      for (let vehicleInfo of vehicleInfos) {
        if (
          this.VehicleGroup.vehicles.some(
            (list) => list.vehicle_id === vehicleInfo.vehicle_id
          )
        ) {
          continue
        } else {
          this.VehicleGroup.vehicles.push({
            vehicle_id: vehicleInfo.vehicle_id,
            block_code: vehicleInfo.block_code,
            vehicle_code: vehicleInfo.vehicle_code,
            vehicle_name: vehicleInfo.vehicle_name,
            //carrier_id: vehicleInfo.carrier_id,
            numberplate: vehicleInfo.numberplate,
            vehicle_model: vehicleInfo.vehicle_model,
            vehicle_category: vehicleInfo.vehicle_category,
            max_loading_weight: vehicleInfo.max_loading_weight,
            max_loading_volume: vehicleInfo.max_loading_volume,
            max_loading_amount: vehicleInfo.max_loading_amount,
            management_vehicle_kind: vehicleInfo.management_vehicle_kind,
            tel: vehicleInfo.tel,
            preparation_time: vehicleInfo.preparation_time,
            vehicle_base_place_id: vehicleInfo.vehicle_base_place_id,
            auto_planning_exclude_flag: vehicleInfo.auto_planning_exclude_flag,
            transport_distance_kind: vehicleInfo.transport_distance_kind,
            preloadable: vehicleInfo.preloadable,
            transport_type_kind: vehicleInfo.transport_type_kind,
            vehicle_weight: vehicleInfo.vehicle_weight,
            vehicle_length: vehicleInfo.vehicle_length,
            vehicle_width: vehicleInfo.vehicle_width,
            vehicle_height: vehicleInfo.vehicle_height,
            vehicle_priority_order: vehicleInfo.vehicle_priority_order,
            charge_area_id_01: vehicleInfo.charge_area_id_01,
            charge_area_id_02: vehicleInfo.charge_area_id_02,
            charge_area_id_03: vehicleInfo.charge_area_id_03,
            charge_area_id_04: vehicleInfo.charge_area_id_04,
            charge_area_id_05: vehicleInfo.charge_area_id_05,
            planning_group_code: vehicleInfo.planning_group_code,
            evaluation_fix_value: vehicleInfo.evaluation_fix_value,
            evaluation_tariff_id: vehicleInfo.evaluation_tariff_id,
            vehicle_condition01: vehicleInfo.vehicle_condition01,
            vehicle_condition02: vehicleInfo.vehicle_condition02,
            vehicle_condition03: vehicleInfo.vehicle_condition03,
            vehicle_condition04: vehicleInfo.vehicle_condition04,
            vehicle_condition05: vehicleInfo.vehicle_condition05,
            vehicle_condition06: vehicleInfo.vehicle_condition06,
            vehicle_condition07: vehicleInfo.vehicle_condition07,
            vehicle_condition08: vehicleInfo.vehicle_condition08,
            vehicle_condition09: vehicleInfo.vehicle_condition09,
            vehicle_condition10: vehicleInfo.vehicle_condition10,
            vehicle_condition11: vehicleInfo.vehicle_condition11,
            vehicle_condition12: vehicleInfo.vehicle_condition12,
            vehicle_condition13: vehicleInfo.vehicle_condition13,
            vehicle_condition14: vehicleInfo.vehicle_condition14,
            vehicle_condition15: vehicleInfo.vehicle_condition15,
            vehicle_condition16: vehicleInfo.vehicle_condition16,
            vehicle_condition17: vehicleInfo.vehicle_condition17,
            vehicle_condition18: vehicleInfo.vehicle_condition18,
            vehicle_condition19: vehicleInfo.vehicle_condition19,
            vehicle_condition20: vehicleInfo.vehicle_condition20,
            highway_use_yn_flag: vehicleInfo.highway_use_yn_flag,
            operation_time_group_id: vehicleInfo.operation_time_group_id,
            operation_time_group_valid_flag:
              vehicleInfo.operation_time_group_valid_flag,
            inspection_end_date: vehicleInfo.inspection_end_date,
            use_start_date: vehicleInfo.use_start_date,
            use_finish_date: vehicleInfo.use_finish_date,
            nonoperation_day_01_from: vehicleInfo.nonoperation_day_01_from,
            nonoperation_day_01_to: vehicleInfo.nonoperation_day_01_to,
            nonoperation_day_02_from: vehicleInfo.nonoperation_day_02_from,
            nonoperation_day_02_to: vehicleInfo.nonoperation_day_02_to,
            nonoperation_day_03_from: vehicleInfo.nonoperation_day_03_from,
            nonoperation_day_03_to: vehicleInfo.nonoperation_day_03_to,
            nonoperation_day_04_from: vehicleInfo.nonoperation_day_04_from,
            nonoperation_day_04_to: vehicleInfo.nonoperation_day_04_to,
            nonoperation_day_05_from: vehicleInfo.nonoperation_day_05_from,
            nonoperation_day_05_to: vehicleInfo.nonoperation_day_05_to,
            nonoperation_day_06_from: vehicleInfo.nonoperation_day_06_from,
            nonoperation_day_06_to: vehicleInfo.nonoperation_day_06_to,
            note: vehicleInfo.note,
            is_onetime_master: vehicleInfo.is_onetime_master,
            vehicle_reserve_01: vehicleInfo.vehicle_reserve_01,
            vehicle_reserve_02: vehicleInfo.vehicle_reserve_02,
            vehicle_reserve_03: vehicleInfo.vehicle_reserve_03,
            vehicle_reserve_04: vehicleInfo.vehicle_reserve_04,
            vehicle_reserve_05: vehicleInfo.vehicle_reserve_05,
            vehicle_reserve_06: vehicleInfo.vehicle_reserve_06,
            vehicle_reserve_07: vehicleInfo.vehicle_reserve_07,
            vehicle_reserve_08: vehicleInfo.vehicle_reserve_08,
            vehicle_reserve_09: vehicleInfo.vehicle_reserve_09,
            vehicle_reserve_10: vehicleInfo.vehicle_reserve_10,
            deleted: vehicleInfo.deleted,
            registration_time_display: vehicleInfo.registration_time_display,
            registration_user: vehicleInfo.registration_user,
            update_time_display: vehicleInfo.update_time_display,
            update_user: vehicleInfo.update_user,
            // version: vehicleInfo.version,
            // update_by_trigger: vehicleInfo.update_by_trigger,
          })
        }
      }
      // this.VehicleGroup.vehicles.sort(function (a, b) {
      //   return a.vehicleId - b.vehicleId
      // })
      //Array.sort()
      this.obj.tableItems = this.VehicleGroup.vehicles
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },
    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
  },
}
</script>

<style lang="scss" scoped>
.data-group-role {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 14px;
}
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
