import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}calendars/`

class CalendarService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  detail(calendarId, yymm) {
    return createAuthenticaionService(`${BASE_URL}detail/`).get(
      `/?calendarId=${calendarId}&yymm=${yymm}`
    )
  }

  create(data) {
    return createAuthenticaionService(BASE_URL).post('/', data)
  }

  updateDetail(data) {
    return createAuthenticaionService(`${BASE_URL}detail/`).put('/', data)
  }

  removeList(data) {
    return createAuthenticaionService(`${BASE_URL}removelist/`).put('/', data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }
}

export default new CalendarService()
