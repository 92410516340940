<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #side>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="Calendar"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
      <template #main>
        <div id="BaseView">
          <table border="1" style="width: 100%; text-align: center">
            <thead style="background: lightGreen">
              <th style="color: red">{{ detailInfo['sunday'].label }}</th>
              <th>{{ detailInfo['monday'].label }}</th>
              <th>{{ detailInfo['tuesday'].label }}</th>
              <th>{{ detailInfo['wednesday'].label }}</th>
              <th>{{ detailInfo['thursday'].label }}</th>
              <th>{{ detailInfo['friday'].label }}</th>
              <th>{{ detailInfo['saturday'].label }}</th>
            </thead>
            <tbody>
              <tr
                v-for="(weekArray, index) in allWorkingList"
                :key="index"
                style="height: 40px"
              >
                <td
                  v-for="(days, index) in weekArray"
                  :key="index"
                  :style="{
                    background: setBgColor(obj, days.model),
                    color: setFontColor(obj, days.model),
                  }"
                  @click="workingDayClick(obj, days)"
                >
                  <label>
                    {{ days.label }}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </DetailPageLayout>
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="calendar-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 前月、翌月モーダル -->
    <ConfirmModal
      id="moveMonth-modal"
      :columnInfos="messageInfo"
      :message="moveMonthMessage"
      @confirm="moveMonth(obj)"
    />
  </div>
</template>

<script>
import CalendarInfo from './CalendarInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  initDisplayData,
  setBgColor,
  setFontColor,
  workingDayClick,
  clear,
  moveMonth,
  goRegister,
  register,
} from './CalendarDetail.js'
export default {
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    selectedItem: {
      type: Object,
    },
    screenKbn: {
      type: Number,
    },
  },
  data() {
    return {
      Calendar: new CalendarInfo(),
      obj: this,
      registerMessage: null,
      oriData: new CalendarInfo(),
      allWorkingList: null,
      isEdit: false,
      isNext: false,
      isCreate: false,
    }
  },

  async created() {
    await init(this)
    initDisplayData(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.CALENDAR_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.CALENDAR_DETAIL
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.CALENDAR_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 前月ボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.previousMonth,
            onClickCallback: () => {
              this.isNext = false
              openSubModal(this, 'moveMonth-modal')
            },
          },
          // 翌月ボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.nextMonth,
            onClickCallback: () => {
              this.isNext = true
              openSubModal(this, 'moveMonth-modal')
            },
          },
        ],
      ]
    },

    inputComponents() {
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // カレンダーID
              type: 'text',
              id: 'calendarId',
              columnInfo: this.detailInfo.calendar_id,
            },
            {
              // カレンダー名
              type: 'text',
              id: 'calendarName',
              columnInfo: this.detailInfo.calendar_name,
              maxLength: 30,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 削除可否区分
              type: 'select',
              id: 'deleteEnableKind',
              columnInfo: this.detailInfo.delete_enable_kind,
              options: getListOptions(this.MASTER_CODE.DELETE_IMPOSSIBLE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 年月
              type: 'text',
              id: 'yymm',
              columnInfo: this.detailInfo.yymm,
            },
          ],
        },
      ]
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },

    moveMonthMessage() {
      return this.$store.getters['init/getMessage']('YZ00MG909Q')
    },
  },

  methods: {
    setBgColor,
    setFontColor,
    workingDayClick,
    moveMonth,
    back,
    register,
  },
}
</script>
