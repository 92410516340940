/**
 * 実車番
 */
export default class realVehicle {
  realVehicleId
  blockCode = null
  blockName = null
  //車番
  numberplate = null
  //運送会社
  carrierId = null
  carrierCode = null
  carrierName = null
  //車両種類
  realVehicleKind = null
  realVehicleKindName = null
  //車型
  vehicleModel = null
  vehicleModelName = null
  note = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null
}
