/**
 * 稼動時間パターンデータ.
 *
 * @class
 * @exports
 */
export default class operationTimePattern {
  //--------------TAB1------------//
  operationTimePatternId = null
  blockCode = null
  operationTimePatternName = null
  note = null
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null
  resultDetail = []
}
