/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class SectionDivisionEdit {
  //--------------TAB1------------//
  sectionNo = 0
  sectionDivisionId = 0
  sectionDivisionDetailId = 0
  //targetBlockCode = 'B000'
  targetBlockCode = null
  targetBlockName = null
  departurePlaceId = null
  departurePlaceName = null
  arrivalPlaceId = null
  arrivalPlaceName = null
  deleted = 0
  modalType = 0
  carrierId = null
  carrierName = null
  autoPlanningExcludeFlag = 0
  autoPlanningExcludeFlagName = null
  vehicleId = null
  numberplate = null
  departureTimeFrom = null
  departureTimeTo = null
  arrivalTimeFrom = null
  arrivalTimeTo = null
  arrivalTime = null
  departureAddDays = 0
  arrivalAddDays = 0
}
