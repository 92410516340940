/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class vehicleGroup {
  //--------------TAB1------------//
  vehicleGroupId
  vehicleGroupName = null
  blockCode = null
  note = null
  deleted = '0'
  registrationTime = null
  registrationTimeDisplay = null
  registrationUser = null
  updateTime = null
  updateTimeDisplay = null
  updateUser = null
  version = 0
  updateByTrigger = null
  vehicles = []
}
