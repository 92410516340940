<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          style="height: 800px"
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableDown>
            <div>
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.tariff_add"
                icon="plus-circle"
                @click="tariffAddRow(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedTariff"
                :columnInfo="detailCtrlInfo.tariff_edit"
                icon="minus-circle"
                @click="tariffEditRow(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!existTariff"
                :columnInfo="detailCtrlInfo.tariff_delete"
                icon="minus-circle"
                @click="tariffRemoveRow(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!existTariff"
                :columnInfo="detailCtrlInfo.tariff_copy"
                icon="minus-circle"
                @click="tariffCopyRow(obj)"
              />
            </div>
            <!-- タリフテーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="tariffFields"
              :items="tariffTableItems"
              selectMode="single"
              @row-selected="onRowSelectedTariff"
              @row-clicked="onRowClickedTariff"
            />
          </template>
          <template v-slot:tab3TableDown>
            <div>
              <!-- 新規登録 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.add"
                icon="plus-circle"
                @click="surchargeAddRow(obj, 'surcharge-edit-modal')"
              />
              <!-- 編集 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedSurcharge"
                :columnInfo="detailCtrlInfo.edit"
                @click="surchargeEditRow(obj, 'surcharge-edit-modal')"
              />
              <!-- 削除 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!existSurcharge"
                :columnInfo="detailCtrlInfo.delete"
                icon="minus-circle"
                @click="surchargeRemoveRow(obj)"
              />
            </div>
            <!-- 燃調金テーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="surchargeFields"
              :items="surchargeTableItems"
              selectMode="single"
              @row-selected="onRowSelectedSurcharge"
              @row-clicked="onRowClickedSurcharge"
            />
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- タリフ編集モーダル -->
    <TariffEditModal
      id="tariff-edit-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :getDatas.sync="selectedTariffDetail"
      @after-close-set="afterCloseSetTariff"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
    />
    <!-- 燃調金編集モーダル -->
    <SurchargeEditModal
      id="surcharge-edit-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :getDatas.sync="selectedSurchargeDetail"
      @after-close-set="afterCloseSetSurcharge"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
    />
  </div>
</template>

<script>
import TariffInfo from './TariffInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, SLOT_NAME } from '@/common/const.js'
import { getBreadcrumbItems, back, goRegister } from '@/common/screen-common.js'
import {
  init,
  register,
  tariffAddRow,
  tariffEditRow,
  tariffRemoveRow,
  tariffCopyRow,
  surchargeAddRow,
  surchargeEditRow,
  surchargeRemoveRow,
  afterCloseSetTariffInfo,
  afterCloseSetSurchargeInfo,
  clear,
} from '@/master/tariff/TariffDetail.js'

const screenId = SCREEN_ID.TARIFF_DETAIL

export default {
  name: 'tariffDetail',

  components: {
    TariffEditModal: () => import('@/master/tariff/TariffEditModal.vue'),
    SurchargeEditModal: () => import('@/master/tariff/SurchargeEditModal.vue'),
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    tariffId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      selectLonLat: null,
      formData: new TariffInfo(),
      tariffTableItems: [],
      surchargeTableItems: [],
      selectedTariff: [],
      selectedSurcharge: [],
      detailRowIndexTariff: -1,
      detailRowIndexSurcharge: -1,
      tariffDetailScreenKbn: -1,
      suchargeDetailScreenKbn: -1,
      obj: this,
      registerMessage: null,
      oriData: new TariffInfo(),
      cityModalSearchCondition: {},
      selectedTariffDetail: {},
      selectedSurchargeDetail: {},
      oriTariffTableItems: [],
      delSurchargeTableItems: [],
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    tariffFields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    surchargeFields() {
      return this.$store.getters['init/getFields'](screenId)(
        'result_fields_surcharges'
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 基本情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // タリフNO
              type: 'text',
              id: 'tariffCode',
              columnInfo: this.detailInfo.tariff_code,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              //タリフ名
              type: 'text',
              id: 'tariffName',
              columnInfo: this.detailInfo.tariff_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 請求支払区分
              type: 'select',
              id: 'isPayment',
              columnInfo: this.detailInfo.is_payment,
              options: getListOptions(this.MASTER_CODE.COST_TYPE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // タリフタブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [],
        },
        {
          // 燃調金タブ
          label: this.detailInfo.tab3.label,
          slot: SLOT_NAME.TAB3_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },

    isSelectedSurcharge() {
      return this.selectedSurcharge.length > 0
    },
    isSelectedTariff() {
      return this.selectedTariff.length > 0
    },
    existTariff() {
      return this.formData.tariffValidPeriodDtos.length > 0
    },
    existSurcharge() {
      return this.formData.surchargeDtos.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    tariffAddRow,
    tariffEditRow,
    tariffRemoveRow,
    tariffCopyRow,
    surchargeAddRow,
    surchargeEditRow,
    surchargeRemoveRow,
    afterCloseSetTariffInfo,
    afterCloseSetSurchargeInfo,

    onRowSelectedTariff(selectedItems) {
      this.selectedTariff = selectedItems
    },
    onRowClickedTariff(item, index) {
      this.detailRowIndexTariff = index
    },

    onRowSelectedSurcharge(selectedItems) {
      this.selectedSurcharge = selectedItems
    },
    onRowClickedSurcharge(item, index) {
      this.detailRowIndexSurcharge = index
    },

    afterCloseSetTariff(info) {
      afterCloseSetTariffInfo(this.obj, info)
    },

    afterCloseSetSurcharge(info) {
      afterCloseSetSurchargeInfo(this.obj, info)
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },
    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },
    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 500px;
}

#BaseView {
  height: 730px;
}

.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
