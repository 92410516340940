/**
 * 荷姿パターンデータ.
 *
 * @class
 * @exports
 */
export default class productPattern {
  //--------------TAB1------------//
  productPatternId = null
  blockCode = null
  publicFlg = null
  productPatternName = null
  //productPatternKey = null
  standardKind = null
  productModel = null
  productType = null
  customizeName = null
  forOverseas = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null

  //List<ProductPatternDetailDto>
  productPatternDetails = []
}
