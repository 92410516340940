/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class product {
  //--------------TAB1------------//
  productId
  blockCode = null
  productCode = null
  productName = null
  ownerId = null
  ownerCode = null
  ownerName = null
  weight = 0
  volume = 0
  countUnit = null
  mixloadImpossibleFlag = '0'
  note = null
  isOnetimeMaster = '0' //マスタ区分=通常マスタ
  autoSectionDivisions = '0' //自動区間分割可否
  autoPlanningExcludeFlag = null
  vehicleCondition01 = null
  vehicleCondition02 = null
  vehicleCondition03 = null
  vehicleCondition04 = null
  vehicleCondition05 = null
  vehicleCondition06 = null
  vehicleCondition07 = null
  vehicleCondition08 = null
  vehicleCondition09 = null
  vehicleCondition10 = null
  vehicleCondition11 = null
  vehicleCondition12 = null
  vehicleCondition13 = null
  vehicleCondition14 = null
  vehicleCondition15 = null
  vehicleCondition16 = null
  vehicleCondition17 = null
  vehicleCondition18 = null
  vehicleCondition19 = null
  vehicleCondition20 = null
  vehicleCondition01Name = null
  vehicleCondition02Name = null
  vehicleCondition03Name = null
  vehicleCondition04Name = null
  vehicleCondition05Name = null
  vehicleCondition06Name = null
  vehicleCondition07Name = null
  vehicleCondition08Name = null
  vehicleCondition09Name = null
  vehicleCondition10Name = null
  vehicleCondition11Name = null
  vehicleCondition12Name = null
  vehicleCondition13Name = null
  vehicleCondition14Name = null
  vehicleCondition15Name = null
  vehicleCondition16Name = null
  vehicleCondition17Name = null
  vehicleCondition18Name = null
  vehicleCondition19Name = null
  vehicleCondition20Name = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  warningMessage = null
  deleted = '0' //削除済み区分=通常
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = 0
  updateByTrigger = null
  //--------------TAB1------------//
}
