<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="Area"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
      <template #bottom>
        <div
          id="BaseView"
          style="margin-top: 10px; border: solid #d8d8d8 1px; padding: 15px"
        >
          <div>
            <div class="row" style="margin-left: 5px">
              <BaseLabel
                :columnInfo="detailInfo.departure"
                style="position: relative; top: 5px"
              />
              <div v-if="ctrlItems" style="margin-left: 5px">
                <BaseButton
                  v-for="(item, index) in ctrlItems[0]"
                  :key="index"
                  class="mr-2"
                  :variant="item.variant"
                  :icon="item.icon"
                  :columnInfo="item.columnInfo"
                  :disabled="item.disabled"
                  @click="item.onClickCallback"
                />
              </div>
            </div>
            <section data-class="work-list-table" style="margin-top: 10px">
              <!-- テーブル -->
              <b-table
                data-class="base-table"
                class="table table-bordered base-table"
                select-mode="single"
                bordered
                hover
                no-border-collapse
                responsive
                small
                sticky-header
                selectable
                outlined
                :fields="fields"
                :items="departureTableItems"
                @row-selected="onRowDepartureSelected"
                v-bind="$attrs"
                v-on="$listeners"
              >
                <template v-slot:cell(key1)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(zone_priority_order)="row">
                  <b-form-input
                    v-model="row.item.zone_priority_order"
                    size="sm"
                    type="number"
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                </template>
              </b-table>
            </section>
          </div>

          <div>
            <div class="row" style="margin-left: 5px">
              <BaseLabel
                :columnInfo="detailInfo.arrival"
                style="position: relative; top: 5px"
              />
              <div v-if="ctrlItems" style="margin-left: 5px">
                <BaseButton
                  v-for="(item, index) in ctrlItems[1]"
                  :key="index"
                  class="mr-2"
                  :variant="item.variant"
                  :icon="item.icon"
                  :columnInfo="item.columnInfo"
                  :disabled="item.disabled"
                  @click="item.onClickCallback"
                />
              </div>
            </div>
            <section data-class="work-list-table" style="margin-top: 10px">
              <!-- テーブル -->
              <b-table
                data-class="base-table"
                class="table table-bordered base-table"
                select-mode="single"
                bordered
                hover
                no-border-collapse
                responsive
                small
                sticky-header
                selectable
                outlined
                :fields="fields"
                :items="arrivalTableItems"
                @row-selected="onRowArrivalSelected"
                v-bind="$attrs"
                v-on="$listeners"
              >
                <template v-slot:cell(key1)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(zone_priority_order)="row">
                  <b-form-input
                    v-model="row.item.zone_priority_order"
                    size="sm"
                    type="number"
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                </template>
              </b-table>
            </section>
          </div>
        </div>
      </template>
    </DetailPageLayout>
    <!-- 都道府県モーダル -->
    <PrefectureModal
      id="prefecture-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addPrefecture"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 市区町村モーダル -->
    <CityModal
      id="city-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addCity"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="area-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
  </div>
</template>

<script>
import AreaInfo from './AreaInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  clear,
  addPrefecture,
  addCity,
  addPlace,
  remove,
  goRegister,
  register,
} from './AreaDetail.js'

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    PrefectureModal: () => import('@/master/prefecture/PrefectureModal.vue'),
    CityModal: () => import('@/master/city/CityModal.vue'),
  },

  data() {
    return {
      departureTableItems: [],
      departureSelectedItem: [],
      arrivalTableItems: [],
      arrivalSelectedItem: [],
      Area: new AreaInfo(),
      obj: this,
      registerMessage: null,
      oriData: new AreaInfo(),
      isDeparturePlaceClick: false,
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    areaId: {
      type: Number,
    },
    areaKind: {
      type: String,
    },
    screenKbn: {
      type: Number,
    },
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.AREA_DETAIL
      )('detail_ctrl_info')

      info.add_prefecture.visibility = this.isVisible
      info.add_city.visibility = this.isVisible
      info.add_postal_code.visibility = this.isVisible

      return info
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.AREA_DETAIL
      )('detail_info')
      info.area_name.visibility = this.isDisable
      return info
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.AREA_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    ctrlItems() {
      return [
        [
          // 都道府県追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add_prefecture,
            onClickCallback: () => {
              this.isDeparturePlaceClick = true
              openSubModal(this, 'prefecture-modal')
            },
          },
          // 市区町村追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add_city,
            onClickCallback: () => {
              this.isDeparturePlaceClick = true
              openSubModal(this, 'city-modal')
            },
          },
          // 場所追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add_place,
            onClickCallback: () => {
              this.isDeparturePlaceClick = true
              openSubModal(this, 'place-modal')
            },
          },
          // 削除ボタン
          {
            variant: 'success',
            icon: 'minus-circle',
            columnInfo: this.detailCtrlInfo.delete,
            onClickCallback: () => {
              remove(this, true)
            },
          },
        ],
        [
          // 都道府県追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add_prefecture,
            onClickCallback: () => {
              this.isDeparturePlaceClick = false
              openSubModal(this, 'prefecture-modal')
            },
          },
          // 市区町村追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add_city,
            onClickCallback: () => {
              this.isDeparturePlaceClick = false
              openSubModal(this, 'city-modal')
            },
          },
          // 場所追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add_place,
            onClickCallback: () => {
              this.isDeparturePlaceClick = false
              openSubModal(this, 'place-modal')
            },
          },
          // 削除ボタン
          {
            variant: 'success',
            icon: 'minus-circle',
            columnInfo: this.detailCtrlInfo.delete,
            onClickCallback: () => {
              remove(this, false)
            },
          },
        ],
      ]
    },

    inputComponents() {
      let inputComponentArray = []
      if (this.areaKind == '0') {
        // 方面
        inputComponentArray[0] = {
          // 方面名
          type: 'text',
          id: 'areaName',
          columnInfo: this.detailInfo.area_name,
          rules: [this.INPUT_TYPE_CHECK.REQUIRED],
        }
      } else {
        // コース
        inputComponentArray[0] = {
          // コース名
          type: 'text',
          id: 'areaName',
          columnInfo: this.detailInfo.couse_name,
          rules: [this.INPUT_TYPE_CHECK.REQUIRED],
        }
      }
      inputComponentArray[1] = {
        // 優先順
        type: 'text',
        id: 'areaPriorityOrder',
        columnInfo: this.detailInfo.area_priority_order,
        rules: [this.INPUT_TYPE_CHECK.REQUIRED],
      }
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: inputComponentArray,
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](this.SCREEN_ID.AREA_DETAIL)(
        this.DOMAIN_NAME.FIELDS
      )
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    isVisible() {
      if (this.areaKind == '0') {
        // 方面
        return this.VISIBILITY.VISIBLE
      } else {
        // コース
        return this.VISIBILITY.INVISIBLE
      }
    },

    isDisable() {
      if (this.screenKbn == this.DETAIL_SCREEN_KBN.DETAIL) {
        // 詳細
        return this.VISIBILITY.DISABLE
      } else {
        return this.VISIBILITY.VISIBLE
      }
    },
  },

  methods: {
    getListOptions,
    register,
    back,

    onRowDepartureSelected(selectedItems) {
      this.departureSelectedItem = selectedItems
    },

    onRowArrivalSelected(selectedItems) {
      this.arrivalSelectedItem = selectedItems
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    addPlace(selectedPlaces) {
      addPlace(this, selectedPlaces)
    },

    addPrefecture(selectedPrefectures) {
      addPrefecture(this, selectedPrefectures)
    },

    addCity(selectedCities) {
      addCity(this, selectedCities)
    },
  },
}
</script>
