export default class vehicle {
  vehicleId = null
  vehicleCode = null
  vehicleName = null

  carrierCode = null
  carrierName = null

  numberplate = null

  vehicleModel = null

  vehicleCategory = null

  tariffType = null

  tariffVehicleModel = null

  maxLoadingWeight = null
  maxLoadingVolume = null
  maxLoadingAmount = null

  vehicleKind = null
  managementVehicleKind = null
  tel = null
  preparationTime = null

  vehicleBasePlaceCode = null
  vehicleBasePlaceName = null

  autoPlanningExcludeFlag = null

  transportDistanceKind = null

  preloadable = '0' //not-null
  transportTypeKind = null

  vehicleWeight = null
  vehicleLength = null
  vehicleWidth = null
  vehicleHeight = null

  vehiclePriorityOrder = null

  chargeAreaId01 = null
  chargeAreaName01 = null
  chargeAreaId02 = null
  chargeAreaName02 = null
  chargeAreaId03 = null
  chargeAreaName03 = null
  chargeAreaId04 = null
  chargeAreaName04 = null
  chargeAreaId05 = null
  chargeAreaName05 = null

  evaluationFixValue = null

  vehicleCondition01 = null
  vehicleCondition02 = null
  vehicleCondition03 = null
  vehicleCondition04 = null
  vehicleCondition05 = null
  vehicleCondition06 = null
  vehicleCondition07 = null
  vehicleCondition08 = null
  vehicleCondition09 = null
  vehicleCondition10 = null
  vehicleCondition11 = null
  vehicleCondition12 = null
  vehicleCondition13 = null
  vehicleCondition14 = null
  vehicleCondition15 = null
  vehicleCondition16 = null
  vehicleCondition17 = null
  vehicleCondition18 = null
  vehicleCondition19 = null
  vehicleCondition20 = null

  highwayUseYnFlag = null

  operationTimePatternId = null
  operationTimePatternName = null

  operationTimePatternValidFlag = null

  inspectionEndDate = null
  useStartDate = null
  useFinishDate = null

  nonoperationDay01From = null
  nonoperationDay01To = null
  nonoperationDay02From = null
  nonoperationDay02To = null
  nonoperationDay03From = null
  nonoperationDay03To = null
  nonoperationDay04From = null
  nonoperationDay04To = null
  nonoperationDay05From = null
  nonoperationDay05To = null
  nonoperationDay06From = null
  nonoperationDay06To = null

  note = null

  isOnetimeMaster = '0' //not-null

  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null

  blockCode = ''
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null
}
