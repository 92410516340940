/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class SectionDivision {
  //--------------TAB1------------//
  sectionDivisionId
  sectionDivisionCount = 0
  dividePatternName = null
  departurePlaceType = null
  departurePlaceId = null
  departurePlaceName = null
  arrivalPlaceType = null
  arrivalPlaceId = null
  arrivalPlaceName = null
  defaultType = null
  deleted = 0
  targetBlockCode = null
  sectionDivisionDetailDtos = []
}
