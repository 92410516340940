import {
  openMsgModal,
  openSubModal,
  formatHHmmss,
  getMasterName,
  displayHHmmss,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN, MASTER_CODE } from '@/common/const.js'
import SectionDivisionService from './SectionDivisionService'
import SectionDivisionEdit from './SectionDivisionEditInfo'
import _ from 'lodash'
import { isNil } from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    SectionDivisionService.get(obj.sectionDivisionId)
      .then(
        (response) => {
          obj.formData = response.data
          //HH:mm:ss表示
          obj.formData.sectionDivisionDetailDtos.map(function (x) {
            x.arrivalTimeFrom = displayHHmmss(x.arrivalTimeFrom)
            x.arrivalTimeTo = displayHHmmss(x.arrivalTimeTo)
            x.departureTimeFrom = displayHHmmss(x.departureTimeFrom)
            x.departureTimeTo = displayHHmmss(x.departureTimeTo)
          })
          var sections = convertSnakeCace(
            obj.formData.sectionDivisionDetailDtos
          )
          obj.formData.sectionDivisionDetailDtos = sections
          obj.tableItems = sections

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.sectionDivisionId = null
          } else {
            obj.formData.sectionDivisionId = obj.sectionDivisionId
          }
          obj.oriData = _.cloneDeep(obj.formData)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    //DETAIL_SCREEN_KBN.NEW
    obj.formData.sectionDivisionDetailDtos = []
  }
}

export function addRow(obj) {
  if (obj.formData.departurePlaceId == null) {
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('H01220_MG001I')
    )
  } else if (obj.formData.arrivalPlaceId == null) {
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('H01220_MG002I')
    )
  } else {
    obj.detailScreenKbn = DETAIL_SCREEN_KBN.NEW

    let newPara = newData(obj, DETAIL_SCREEN_KBN.NEW)
    obj.sectionDivisionEditModalCondition = newPara
    openSubModal(obj, 'section-division-edit')
  }
}

export function editRow(obj) {
  obj.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL

  let newPara = newData(obj, DETAIL_SCREEN_KBN.DETAIL)
  obj.sectionDivisionEditModalCondition = newPara
  openSubModal(obj, 'section-division-edit')
}

function newData(obj, paraDetailScreenKbn) {
  let rowKbn = -1
  if (obj.tableItems.length == 0) {
    //Row==0
    rowKbn = 0
  } else if (obj.detailRowIndex == 0 && obj.tableItems.length != 0) {
    //firstRow
    rowKbn = 1
  } else if (
    obj.detailRowIndex == obj.tableItems.length - 1 &&
    obj.tableItems.length != 0
  ) {
    //lastRow
    rowKbn = 2
  } else if (
    obj.detailRowIndex > 0 &&
    obj.detailRowIndex != obj.tableItems.length - 1 &&
    obj.tableItems.length != 0
  ) {
    //middleRow
    rowKbn = 3
  }
  let newObj = {
    detailScreenKbn: paraDetailScreenKbn,
    rowKbn: rowKbn, // 0:, 1:firstRow, 2:lastRow, 3:middleRow
    // （基本情報）出発地ＩＤ
    baseDeparturePlaceId: obj.formData.departurePlaceId,
    baseDeparturePlaceName: obj.formData.departurePlaceName,
    // （基本情報）到着地ＩＤ
    baseArrivalPlaceId: obj.formData.arrivalPlaceId,
    baseArrivalPlaceName: obj.formData.arrivalPlaceName,
    // 選択行
    selectedItem: _.cloneDeep(obj.selectedItem),
  }
  return newObj
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.formData.sectionDivisionDetailDtos = _.cloneDeep(
    obj.oriData.sectionDivisionDetailDtos
  )
  obj.tableItems = obj.formData.sectionDivisionDetailDtos || []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.formData.deleted == '1') {
    obj.formData.deleted = '0'
  }

  obj.formData.sectionDivisionDetailDtos = convertCamelCace(
    obj.formData.sectionDivisionDetailDtos
  )

  obj.formData.sectionDivisionDetailDtos.map(function (x) {
    x.arrivalTimeFrom = formatHHmmss(x.arrivalTimeFrom)
    x.arrivalTimeTo = formatHHmmss(x.arrivalTimeTo)
    x.departureTimeFrom = formatHHmmss(x.departureTimeFrom)
    x.departureTimeTo = formatHHmmss(x.departureTimeTo)
  })

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = SectionDivisionService.create(obj.formData)
  } else {
    // 詳細
    callApi = SectionDivisionService.update(obj.sectionDivisionId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function afterCloseEditModal(obj, info) {
  if (info != null) {
    let addInfo = setRowAddInfo(info)

    //「編集」ボタン
    if (obj.detailScreenKbn == DETAIL_SCREEN_KBN.DETAIL) {
      obj.selectedItem[0].arrival_add_days = info.arrivalAddDays
      obj.selectedItem[0].arrival_place_id = info.arrivalPlaceId
      obj.selectedItem[0].arrival_place_name = info.arrivalPlaceName
      obj.selectedItem[0].arrival_time_from = displayHHmmss(
        info.arrivalTimeFrom
      )
      obj.selectedItem[0].arrival_time_to = displayHHmmss(info.arrivalTimeTo)
      obj.selectedItem[0].target_block_code = info.targetBlockCode
      obj.selectedItem[0].target_block_name = getMasterName(
        MASTER_CODE.BLOCK,
        info.targetBlockCode
      )
      obj.selectedItem[0].carrier_id = info.carrierId
      obj.selectedItem[0].carrier_name = info.carrierName
      obj.selectedItem[0].departure_add_days = info.departureAddDays
      obj.selectedItem[0].departure_place_id = info.departurePlaceId
      obj.selectedItem[0].departure_place_name = info.departurePlaceName

      obj.selectedItem[0].departure_time_from = displayHHmmss(
        info.departureTimeFrom
      )
      obj.selectedItem[0].departure_time_to = displayHHmmss(
        info.departureTimeTo
      )
      obj.selectedItem[0].modal_type = info.modalType
      obj.selectedItem[0].modal_type_name = getMasterName(
        MASTER_CODE.MODAL_TYPE,
        info.modalType
      )
      obj.selectedItem[0].numberplate = info.numberplate
      obj.selectedItem[0].vehicle_id = info.vehicleId
      obj.selectedItem[0].auto_planning_exclude_flag =
        info.autoPlanningExcludeFlag
      obj.selectedItem[0].auto_planning_exclude_flag_name = getMasterName(
        MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG,
        info.autoPlanningExcludeFlag
      )
      editRowPlaceData(obj, info)
    } else {
      //「追加」ボタン
      if (obj.formData.sectionDivisionDetailDtos.length != 0) {
        // lastRow
        if (info.departurePlaceId != null) {
          let nowInfo = _.cloneDeep(obj.selectedItem[0])
          //[detailRowIndex]ブロック <= 既存ブロック
          //[detailRowIndex]出発地 <= 既存出発地
          //[detailRowIndex]到着地 <= new出発地
          nowInfo.arrival_place_id = info.departurePlaceId
          nowInfo.arrival_place_name = info.departurePlaceName

          //[detailRowIndex + 1]ブロック <= newブロック
          //[detailRowIndex + 1]出発地 <= new出発地
          //[detailRowIndex + 1]到着地 <= 既存到着地(Fixed)
          // 選択行入れ替える
          obj.formData.sectionDivisionDetailDtos.splice(
            obj.detailRowIndex,
            1,
            nowInfo
          )
          // 選択行 + 1追加
          obj.formData.sectionDivisionDetailDtos.splice(
            obj.detailRowIndex + 1,
            0,
            addInfo
          )
        }
      } else {
        //Row==0
        obj.formData.sectionDivisionDetailDtos.push(addInfo)
      }

      let num = 1
      obj.formData.sectionDivisionDetailDtos.forEach((element) => {
        element.section_no = num
        num++
      })

      obj.tableItems = obj.formData.sectionDivisionDetailDtos
    }
  }
}

function editRowPlaceData(obj, info) {
  //[選択行-1]到着地 <= new出発地
  //[選択行+1]出発地 <= new到着地
  if (obj.formData.sectionDivisionDetailDtos[obj.detailRowIndex - 1] != null) {
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex - 1
    ].arrival_place_id = info.departurePlaceId
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex - 1
    ].arrival_place_name = info.departurePlaceName
  }
  if (obj.formData.sectionDivisionDetailDtos[obj.detailRowIndex + 1] != null) {
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex + 1
    ].departure_place_id = info.arrivalPlaceId
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex + 1
    ].departure_place_name = info.arrivalPlaceName
  }
}

export function tableRowRemove(obj) {
  if (obj.detailRowIndex == 0) {
    if (obj.formData.sectionDivisionDetailDtos.length == 1) {
      this.tableItems.splice(0, 1)
    } else {
      //[detailRowIndex]ブロック <= 既存ブロック(Fixed)
      //[detailRowIndex]出発地   <= 既存出発地(Fixed)
      //[detailRowIndex]到着地   <= [detailRowIndex + 1]到着地
      obj.formData.sectionDivisionDetailDtos[
        obj.detailRowIndex
      ].arrival_place_id =
        obj.formData.sectionDivisionDetailDtos[
          obj.detailRowIndex + 1
        ].arrival_place_id
      obj.formData.sectionDivisionDetailDtos[
        obj.detailRowIndex
      ].arrival_place_name =
        obj.formData.sectionDivisionDetailDtos[
          obj.detailRowIndex + 1
        ].arrival_place_name

      // 複数個削除
      for (let info of obj.selectedItem) {
        const index = obj.formData.sectionDivisionDetailDtos.indexOf(info)
        this.tableItems.splice(index + 1, 1)
        //obj.formData.sectionDivisionDetailDtos.splice(index, 1)
      }
    }
  } else {
    //[detailRowIndex]ブロック <= [detailRowIndex - 1]ブロック
    //[detailRowIndex]出発地   <= [detailRowIndex - 1]出発地
    //[detailRowIndex]到着地   <= 既存到着地(Fixed)

    let prevRow = _.cloneDeep(
      obj.formData.sectionDivisionDetailDtos[obj.detailRowIndex - 1]
    )
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex
    ].departure_place_id = prevRow.departure_place_id
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex
    ].departure_place_name = prevRow.departure_place_name
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex
    ].target_block_code = prevRow.target_block_code
    obj.formData.sectionDivisionDetailDtos[
      obj.detailRowIndex
    ].target_block_name = getMasterName(
      MASTER_CODE.BLOCK,
      prevRow.target_block_code
    )

    // 複数個削除
    for (let info of obj.selectedItem) {
      const index = obj.formData.sectionDivisionDetailDtos.indexOf(info)
      this.tableItems.splice(index - 1, 1)
      //obj.formData.sectionDivisionDetailDtos.splice(index, 1)
    }
  }

  let num = 1
  obj.formData.sectionDivisionDetailDtos.forEach((element) => {
    element.section_no = num
    num++
  })

  obj.tableItems = obj.formData.sectionDivisionDetailDtos
}

/**
 * 行情報作成
 * @param {*} info
 */
function setRowAddInfo(info) {
  let addInfo = {
    //到着加算日数
    arrival_add_days: info.arrivalAddDays,
    //到着地ＩＤ
    arrival_place_id: info.arrivalPlaceId,
    //到着地名
    arrival_place_name: info.arrivalPlaceName,
    //着時間(From)
    arrival_time_from: info.arrivalTimeFrom,
    //着時間(To)
    arrival_time_to: info.arrivalTimeTo,
    //分割ブロックコード
    target_block_code: info.targetBlockCode,
    //分割ブロック名
    target_block_name: getMasterName(MASTER_CODE.BLOCK, info.targetBlockCode),
    //協力企業ＩＤ
    carrier_id: info.carrierId,
    //協力企業名
    carrier_name: info.carrierName,
    //出発加算日数
    departure_add_days: info.departureAddDays,
    //出発地ＩＤ
    departure_place_id: info.departurePlaceId,
    //出発地名
    departure_place_name: info.departurePlaceName,
    //発時間(From)
    departure_time_from: info.departureTimeFrom,
    //発時間(To)
    departure_time_to: info.departureTimeTo,
    //モーダル区分
    modal_type: info.modalType,
    //モーダル区分名
    modal_type_name: getMasterName(MASTER_CODE.MODAL_TYPE, info.modalType),
    //車両番号
    numberplate: info.numberplate,
    //車両ID
    vehicle_id: info.vehicleId,

    auto_planning_exclude_flag: info.autoPlanningExcludeFlag,
    auto_planning_exclude_flag_name: getMasterName(
      MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG,
      info.autoPlanningExcludeFlag
    ),

    deleted: 0,
  }
  return addInfo
}

/**
 * 基本情報タブの搬出場所・搬入場所が変更された時、経由地タブにも反映する。
 * @param {*} obj
 * @param {*} placeModalKbn
 */
export function reflectPlace(obj, placeModalKbn) {
  let dtoLength = obj.formData.sectionDivisionDetailDtos.length
  let info = {}
  let modifiedIndex = -1
  if (dtoLength > 0) {
    if (placeModalKbn == 1) {
      //[先頭行]出発地 <= new出発地
      modifiedIndex = 0
      info = _.cloneDeep(obj.formData.sectionDivisionDetailDtos[modifiedIndex])
      info.departure_place_id = obj.formData.departurePlaceId
      info.departure_place_name = obj.formData.departurePlaceName
    } else if (placeModalKbn == 2) {
      //[最後行]到着地 <= new到着地
      modifiedIndex = dtoLength - 1
      info = _.cloneDeep(obj.formData.sectionDivisionDetailDtos[modifiedIndex])
      info.arrival_place_id = obj.formData.arrivalPlaceId
      info.arrival_place_name = obj.formData.arrivalPlaceName
    }
    // 選択行入れ替える
    obj.formData.sectionDivisionDetailDtos.splice(modifiedIndex, 1, info)
    obj.tableItems = obj.formData.sectionDivisionDetailDtos
  }
  // デフォルト行追加（基本情報タブの搬出場所・搬入場所）
  if (dtoLength == 0) {
    if (
      isNil(obj.formData.departurePlaceId) == false &&
      isNil(obj.formData.arrivalPlaceId) == false
    ) {
      let loginUserBlockCode = obj.$store.getters['authentication/getBlockCode']
      let info = new SectionDivisionEdit()
      info.targetBlockCode = loginUserBlockCode
      info.departurePlaceId = obj.formData.departurePlaceId
      info.departurePlaceName = obj.formData.departurePlaceName
      info.arrivalPlaceId = obj.formData.arrivalPlaceId
      info.arrivalPlaceName = obj.formData.arrivalPlaceName
      let addInfo = setRowAddInfo(info)
      addInfo.section_no = 1
      //Row==0
      obj.formData.sectionDivisionDetailDtos.push(addInfo)
      obj.tableItems = obj.formData.sectionDivisionDetailDtos
    }
  }
}
