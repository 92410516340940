import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import ProductPatternService from './ProductPatternService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    ProductPatternService.get(obj.productPatternId)
      .then(
        (response) => {
          obj.ProductPattern = response.data

          var patterns = changeSnakeCase(obj)
          obj.ProductPattern.productPatternDetails = patterns

          obj.tableItems = obj.ProductPattern.productPatternDetails
          // コピー
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.ProductPattern.productPatternId = null
            obj.ProductPattern.deleted = '0'
            obj.ProductPattern.registrationTimeDisplay = null
            obj.ProductPattern.registrationUser = null
            obj.ProductPattern.updateTimeDisplay = null
            obj.ProductPattern.updateUser = null
            obj.ProductPattern.productPatternDetails.map(function (x) {
              x.product_pattern_detail_id = ''
              x.deleted = '0'
              x.registration_time_display = null
              x.registration_user = null
              x.update_time_display = null
              x.update_user = null
              return x
            })
          } else {
            obj.ProductPattern.productPatternId = obj.productPatternId
          }

          obj.oriData = _.cloneDeep(obj.ProductPattern)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    //DETAIL_SCREEN_KBN.NEW
    obj.ProductPattern.productPatternDetails = []
  }
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.tableItems.length === 0) {
    // 明細を追加してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MH01S002_MG001E')
    )
  } else {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.register.label
    )
    if (obj.ProductPattern.deleted == '1') {
      obj.registerMessage = obj.$store.getters['init/getMessage'](
        'YZ00MG905Q',
        obj.detailCtrlInfo.register.label
      )
    }
    openSubModal(obj, 'register-modal')
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')
  var OriData = obj.ProductPattern.productPatternDetails
  obj.ProductPattern.productPatternDetails = changeCamelCase(obj)
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = ProductPatternService.create(obj.ProductPattern)
  } else {
    // 詳細
    callApi = ProductPatternService.update(
      obj.productPatternId,
      obj.ProductPattern
    )
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'productPattern-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        obj.ProductPattern.productPatternDetails = OriData

        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function remove(obj) {
  // 複数個削除
  for (let info of obj.selectedItem) {
    const index = obj.ProductPattern.productPatternDetails.indexOf(info)
    obj.ProductPattern.productPatternDetails.splice(index, 1)
  }
  obj.tableItems = obj.ProductPattern.productPatternDetails
}

export function clear(obj) {
  obj.ProductPattern = _.cloneDeep(obj.oriData)
  obj.ProductPattern.productPatternDetails = _.cloneDeep(
    obj.oriData.productPatternDetails
  )
  obj.tableItems = obj.ProductPattern.productPatternDetails || []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function changeCamelCase(obj) {
  var patterns_camel = convertCamelCace(
    obj.ProductPattern.productPatternDetails
  )

  return patterns_camel
}

function changeSnakeCase(obj) {
  var patterns = convertSnakeCace(obj.ProductPattern.productPatternDetails)

  patterns.sort(function (a, b) {
    return a.product_id - b.product_id
  })

  return patterns
}
