import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  displaYyyymmdd,
  convertSnakeCace,
  convertCamelCace,
  getMasterName,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  ROW_KBN,
  MASTER_CODE,
} from '@/common/const.js'
import TariffService from './TariffService'
import _ from 'lodash'

const FINAL_VALID_END_DATE = '2099/12/31'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    TariffService.get(obj.tariffId)
      .then(
        (response) => {
          obj.formData = response.data

          obj.formData.tariffValidPeriodDtos.map(function (x) {
            x.validBeginDate = displaYyyymmdd(obj, x.validBeginDate)
            x.validEndDate = displaYyyymmdd(obj, x.validEndDate)
          })
          obj.formData.surchargeDtos.map(function (x) {
            x.validBeginDate = displaYyyymmdd(obj, x.validBeginDate)
            x.validEndDate = displaYyyymmdd(obj, x.validEndDate)
          })

          //tariffdetails
          var tariffDetails = convertSnakeCace(
            obj.formData.tariffValidPeriodDtos
          )
          //surchargeDetails
          var surchargeDetails = convertSnakeCace(obj.formData.surchargeDtos)

          obj.formData.tariffValidPeriodDtos = tariffDetails
          obj.formData.surchargeDtos = surchargeDetails

          obj.tariffTableItems = obj.formData.tariffValidPeriodDtos
          sortsurchargeDtos(obj)
          obj.surchargeTableItems = obj.formData.surchargeDtos

          if (obj.screenKbn == DETAIL_SCREEN_KBN.NEW) {
            obj.formData.tariffId = null
          } else {
            obj.formData.tariffId = obj.tariffId
          }
          obj.oriData = _.cloneDeep(obj.formData)
          obj.oriTariffTableItems = []
          obj.delSurchargeTableItems = []
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  let newFormData = _.cloneDeep(obj.formData)

  // 画面表示情報＋削除情報
  newFormData.tariffValidPeriodDtos = mergeCostDto(
    obj.oriTariffTableItems,
    newFormData.tariffValidPeriodDtos
  )
  newFormData.surchargeDtos = mergeCostDto(
    obj.delSurchargeTableItems,
    newFormData.surchargeDtos
  )

  var tariffDetails = convertCamelCace(newFormData.tariffValidPeriodDtos)
  var surchargeDateils = convertCamelCace(newFormData.surchargeDtos)
  newFormData.tariffValidPeriodDtos = tariffDetails
  newFormData.surchargeDtos = surchargeDateils

  // if (newFormData.deleted == '1') {
  //   newFormData.deleted = '0'
  // }

  newFormData.tariffValidPeriodDtos.map(function (x) {
    x.validBeginDate = formatYYYYMMDD(obj, x.validBeginDate)
    x.validEndDate = formatYYYYMMDD(obj, x.validEndDate)
  })
  newFormData.surchargeDtos.map(function (x) {
    x.validBeginDate = formatYYYYMMDD(obj, x.validBeginDate)
    x.validEndDate = formatYYYYMMDD(obj, x.validEndDate)
  })

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = TariffService.create(newFormData)
  } else {
    // 詳細
    callApi = TariffService.update(obj.tariffId, newFormData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function mergeCostDto(deleteItems, tableItems) {
  if (deleteItems.length > 0) {
    // 画面表示情報＋削除情報
    for (let info of deleteItems) {
      tableItems.push(_.cloneDeep(info))
    }
  }
  return tableItems
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.tariffTableItems = obj.formData.tariffValidPeriodDtos || []
  sortsurchargeDtos(obj)
  obj.surchargeTableItems = obj.formData.surchargeDtos || []
  obj.oriTariffTableItems = []
  obj.delSurchargeTableItems = []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function newDataTariff(obj, paraDetailScreenKbn) {
  let rowKbn = -1
  if (obj.tariffTableItems.length == 0) {
    //none
    rowKbn = ROW_KBN.NONE
  } else if (
    obj.detailRowIndexTariff == obj.tariffTableItems.length - 1 &&
    obj.tariffTableItems.length != 0
  ) {
    //lastRow
    rowKbn = ROW_KBN.LAST
  } else if (
    obj.detailRowIndexTariff > 0 &&
    obj.detailRowIndexTariff != obj.tariffTableItems.length - 1 &&
    obj.tariffTableItems.length != 0
  ) {
    //middleRow
    rowKbn = ROW_KBN.MIDDLE
  }

  // 最新有効開始日付
  let prevValidBeginDate = null
  switch (paraDetailScreenKbn) {
    case DETAIL_SCREEN_KBN.NEW:
      if (rowKbn != ROW_KBN.NONE) {
        prevValidBeginDate =
          obj.tariffTableItems[obj.tariffTableItems.length - 1].valid_begin_date
      }
      break
    case DETAIL_SCREEN_KBN.COPY:
      prevValidBeginDate =
        obj.tariffTableItems[obj.tariffTableItems.length - 1].valid_begin_date
      break
    case DETAIL_SCREEN_KBN.DETAIL:
      prevValidBeginDate = obj.selectedTariff[0].valid_begin_date
      break
    default:
      break
  }

  let newObj = {
    detailScreenKbn: paraDetailScreenKbn,
    rowKbn: rowKbn, // 1:firstRow, 2:lastRow, 3:middleRow
    prevValidBeginDate: prevValidBeginDate,
    // 選択行
    selectedItem: _.cloneDeep(obj.selectedTariff),
  }
  return newObj
}

export function tariffEditRow(obj) {
  obj.tariffDetailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
  let newPara = newDataTariff(obj, DETAIL_SCREEN_KBN.DETAIL)
  obj.selectedTariffDetail = newPara
  openSubModal(obj, 'tariff-edit-modal')
}

export function tariffAddRow(obj) {
  obj.tariffDetailScreenKbn = DETAIL_SCREEN_KBN.NEW
  let newPara = newDataTariff(obj, DETAIL_SCREEN_KBN.NEW)
  obj.selectedTariffDetail = newPara
  openSubModal(obj, 'tariff-edit-modal')
}

export function tariffCopyRow(obj) {
  obj.tariffDetailScreenKbn = DETAIL_SCREEN_KBN.COPY
  let newPara = newDataTariff(obj, DETAIL_SCREEN_KBN.COPY)
  obj.selectedTariffDetail = newPara
  openSubModal(obj, 'tariff-edit-modal')
}

export function tariffRemoveRow(obj) {
  let tableItemsLength = obj.formData.tariffValidPeriodDtos.length
  if (tableItemsLength > 0) {
    let lastInfo = _.cloneDeep(
      obj.formData.tariffValidPeriodDtos[tableItemsLength - 1]
    )
    if (lastInfo.tariff_valid_period_id != null) {
      // DB登録されている行に対して「削除」ボタン押下した場合
      lastInfo.deleted = 1
      obj.oriTariffTableItems.push(lastInfo)
      obj.formData.tariffValidPeriodDtos.splice(tableItemsLength - 1, 1)
    } else if (lastInfo.tariff_valid_period_id == null) {
      // 「新規登録」ボタンから追加した行に対して「削除」ボタン押下した場合
      obj.tariffTableItems.splice(tableItemsLength - 1, 1)
    }
    // 最後行入れ替える(有効終了日付再設定)
    tableItemsLength = obj.formData.tariffValidPeriodDtos.length
    if (tableItemsLength > 0) {
      let lastInfo2 = _.cloneDeep(
        obj.formData.tariffValidPeriodDtos[tableItemsLength - 1]
      )
      lastInfo2.valid_end_date = FINAL_VALID_END_DATE
      obj.formData.tariffValidPeriodDtos.splice(
        tableItemsLength - 1,
        1,
        lastInfo2
      )
    }
  }
  obj.tariffTableItems = obj.formData.tariffValidPeriodDtos
}

export function afterCloseSetTariffInfo(obj, info) {
  let newBeginDate = displaYyyymmdd(obj, info.validBeginDate)
  let addInfo = {
    original_copy_id: null,
    tariff_valid_period_id: null,
    valid_begin_date: newBeginDate,
    valid_end_date: FINAL_VALID_END_DATE,
    deleted: 0,
    registration_time: null,
    registration_user: null,
    update_by_trigger: null,
    update_time: null,
    update_time_display: null,
    update_user: null,
    version: null,
  }
  //「編集」ボタン
  if (obj.tariffDetailScreenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    obj.selectedTariff[0].valid_begin_date = newBeginDate
  } else {
    //「追加」ボタン
    //「コピー」ボタン
    let tableItemsLength = obj.formData.tariffValidPeriodDtos.length
    if (tableItemsLength > 0) {
      let lastInfo = _.cloneDeep(
        obj.formData.tariffValidPeriodDtos[tableItemsLength - 1]
      )
      // YYYY/MM/DD - days
      let newEndDate = subtractDaysToString(obj, 1, newBeginDate)
      lastInfo.valid_end_date = newEndDate

      // コピー元のidを設定
      if (obj.tariffDetailScreenKbn == DETAIL_SCREEN_KBN.COPY) {
        addInfo.original_copy_id = lastInfo.tariff_valid_period_id
      }

      // 最後行入れ替える
      obj.formData.tariffValidPeriodDtos.splice(
        tableItemsLength - 1,
        1,
        lastInfo
      )
    }
    obj.formData.tariffValidPeriodDtos.push(addInfo)
    obj.tariffTableItems = obj.formData.tariffValidPeriodDtos
  }
}

function newDataSurcharge(obj, paraDetailScreenKbn) {
  let rowKbn = -1
  if (obj.surchargeTableItems.length == 0) {
    //firstRow //none
    rowKbn = ROW_KBN.NONE
  } else if (
    obj.detailRowIndexSurcharge == obj.surchargeTableItems.length - 1 &&
    obj.surchargeTableItems.length != 0
  ) {
    //lastRow
    rowKbn = ROW_KBN.LAST
  } else if (
    obj.detailRowIndexSurcharge > 0 &&
    obj.detailRowIndexSurcharge != obj.surchargeTableItems.length - 1 &&
    obj.surchargeTableItems.length != 0
  ) {
    //middleRow
    rowKbn = ROW_KBN.MIDDLE
  }
  // 最新有効開始日付
  let prevValidBeginDate = null
  switch (paraDetailScreenKbn) {
    case DETAIL_SCREEN_KBN.NEW:
      if (rowKbn != ROW_KBN.NONE) {
        prevValidBeginDate =
          obj.surchargeTableItems[obj.surchargeTableItems.length - 1]
            .valid_begin_date
      }
      break
    case DETAIL_SCREEN_KBN.COPY:
      prevValidBeginDate =
        obj.surchargeTableItems[obj.surchargeTableItems.length - 1]
          .valid_begin_date
      break
    case DETAIL_SCREEN_KBN.DETAIL:
      prevValidBeginDate = obj.selectedSurcharge[0].valid_begin_date
      break
    default:
      break
  }
  let newObj = {
    detailScreenKbn: paraDetailScreenKbn,
    rowKbn: rowKbn, // 1:firstRow, 2:lastRow, 3:middleRow
    prevValidBeginDate: prevValidBeginDate,
    // 選択行
    selectedItem: _.cloneDeep(obj.selectedSurcharge),
    // 基本情報タブの請求支払区分
    isPayment: obj.formData.isPayment,
    //
    surchargeDtos: obj.formData.surchargeDtos,
  }
  return newObj
}

export function surchargeEditRow(obj) {
  obj.suchargeDetailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
  let newPara = newDataSurcharge(obj, DETAIL_SCREEN_KBN.DETAIL)
  obj.selectedSurchargeDetail = newPara
  openSubModal(obj, 'surcharge-edit-modal')
}

export function surchargeAddRow(obj) {
  obj.suchargeDetailScreenKbn = DETAIL_SCREEN_KBN.NEW
  let newPara = newDataSurcharge(obj, DETAIL_SCREEN_KBN.NEW)
  obj.selectedSurchargeDetail = newPara
  openSubModal(obj, 'surcharge-edit-modal')
}

export function surchargeRemoveRow(obj) {
  // 複数個削除
  // for (let info of obj.selectedItem) {
  //   const index = obj.formData.surchargeDtos.indexOf(info)
  //   obj.formData.surchargeDtos.splice(index, 1)
  // }
  // obj.tableItems = obj.formData.surchargeDtos

  if (obj.selectedSurcharge.length == 0) {
    return
  }

  //削除対象の輸送手段カテゴリ
  let deleteVehicleCategory = obj.selectedSurcharge[0].vehicle_category
  let deleteBranchNo = obj.selectedSurcharge[0].branch_no
  let deleteAdjustmentMoneyKind = obj.selectedSurcharge[0].adjustment_money_kind

  //削除対象の輸送手段カテゴリの最後のIndex
  let lastIndex = findLastIndex(
    obj,
    deleteVehicleCategory,
    deleteBranchNo,
    deleteAdjustmentMoneyKind
  )
  if (lastIndex != null) {
    let deleteInfo = _.cloneDeep(obj.formData.surchargeDtos[lastIndex])
    if (deleteInfo.surcharge_id != null) {
      deleteInfo.deleted = 1
      //DB削除削除情報に追加
      obj.delSurchargeTableItems.push(deleteInfo)
    }
    obj.formData.surchargeDtos.splice(lastIndex, 1)
  }

  //削除後、輸送手段カテゴリの最後のIndex
  lastIndex = findLastIndex(
    obj,
    deleteVehicleCategory,
    deleteBranchNo,
    deleteAdjustmentMoneyKind
  )
  if (lastIndex != null) {
    //輸送手段カテゴリの最後のIndexに「2099/12/31」
    obj.formData.surchargeDtos[lastIndex].valid_end_date = FINAL_VALID_END_DATE
  }

  // if (tableItemsLength > 0) {
  //   let lastInfo = _.cloneDeep(obj.formData.surchargeDtos[tableItemsLength - 1])
  //   if (lastInfo.surcharge_id != null) {
  //     // DB登録されている行に対して「削除」ボタン押下した場合
  //     lastInfo.deleted = 1
  //     obj.delSurchargeTableItems.push(lastInfo)
  //     obj.formData.surchargeDtos.splice(tableItemsLength - 1, 1)
  //   } else if (lastInfo.surcharge_id == null) {
  //     // 「新規登録」ボタンから追加した行に対して「削除」ボタン押下した場合
  //     obj.surchargeTableItems.splice(tableItemsLength - 1, 1)
  //   }
  //   // 最後行入れ替える(有効終了日付再設定)
  //   tableItemsLength = obj.formData.surchargeDtos.length
  //   if (tableItemsLength > 0) {
  //     let lastInfo2 = _.cloneDeep(
  //       obj.formData.surchargeDtos[tableItemsLength - 1]
  //     )
  //     lastInfo2.valid_end_date = FINAL_VALID_END_DATE
  //     obj.formData.surchargeDtos.splice(tableItemsLength - 1, 1, lastInfo2)
  //   }
  // }

  sortsurchargeDtos(obj)
  obj.surchargeTableItems = obj.formData.surchargeDtos
}

export function findLastIndex(
  obj,
  inVehicleCategory,
  branchNo,
  adjustmentMoneyKind
) {
  let lastIndex = null
  for (let idx = 0; idx < obj.formData.surchargeDtos.length; idx++) {
    let currVehicleCategory = obj.formData.surchargeDtos[idx].vehicle_category
    let currBranchNo = obj.formData.surchargeDtos[idx].branch_no
    let currAdjustmentMoneyKind =
      obj.formData.surchargeDtos[idx].adjustment_money_kind
    if (
      inVehicleCategory == currVehicleCategory &&
      branchNo == currBranchNo &&
      adjustmentMoneyKind == currAdjustmentMoneyKind
    ) {
      lastIndex = idx
    }
  }
  return lastIndex
}

export function findLastPrevIndex(
  obj,
  inVehicleCategory,
  branchNo,
  adjustmentMoneyKind
) {
  let lastPrevIndex = null
  for (let idx = 0; idx < obj.formData.surchargeDtos.length; idx++) {
    let currVehicleCategory = obj.formData.surchargeDtos[idx].vehicle_category
    let currBranchNo = obj.formData.surchargeDtos[idx].branch_no
    let currAdjustmentMoneyKind =
      obj.formData.surchargeDtos[idx].adjustment_money_kind
    if (
      inVehicleCategory == currVehicleCategory &&
      branchNo == currBranchNo &&
      adjustmentMoneyKind == currAdjustmentMoneyKind
    ) {
      lastPrevIndex = idx - 1
    }
  }
  if (lastPrevIndex != null) {
    if (lastPrevIndex < 0) {
      lastPrevIndex = null
    }
  }
  return lastPrevIndex
}

export function countByVehicleCategory(
  obj,
  inVehicleCategory,
  branchNo,
  adjustmentMoneyKind
) {
  let count = 0
  for (let idx = 0; idx < obj.formData.surchargeDtos.length; idx++) {
    let currVehicleCategory = obj.formData.surchargeDtos[idx].vehicle_category
    let currBranchNo = obj.formData.surchargeDtos[idx].branch_no
    let currAdjustmentMoneyKind =
      obj.formData.surchargeDtos[idx].adjustment_money_kind
    if (
      inVehicleCategory == currVehicleCategory &&
      branchNo == currBranchNo &&
      adjustmentMoneyKind == currAdjustmentMoneyKind
    ) {
      count++
    }
  }
  return count
}
export function afterCloseSetSurchargeInfo(obj, info) {
  let newBeginDate = displaYyyymmdd(obj, info.validBeginDate2)
  let addInfo = {
    surcharge_id: null,
    valid_begin_date: newBeginDate,
    valid_end_date: FINAL_VALID_END_DATE,
    vehicle_category: info.vehicleCategory,
    vehicle_category_name: getMasterName(
      MASTER_CODE.VEHICLE_CATEGORY,
      info.vehicleCategory
    ),
    branch_no: info.branchNo,
    adjustment_money_kind: info.adjustmentMoneyKind,
    adjustment_money_kind_name: getMasterName(
      MASTER_CODE.ADJUSTMENT_MONEY_KIND,
      info.adjustmentMoneyKind
    ),
    adjustment_money_rate: info.adjustmentMoneyRate,
    freight_item_id: info.freightItemId,
    freight_item_name: info.freightItemName,
    deleted: 0,
    registration_time: null,
    registration_time_display: null,
    registration_user: null,
    update_by_trigger: null,
    update_time: null,
    update_time_display: null,
    update_user: null,
    version: null,
  }
  //「編集」ボタン
  if (obj.suchargeDetailScreenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    obj.selectedSurcharge[0].vehicle_category = info.vehicleCategory
    obj.selectedSurcharge[0].vehicle_category_name = getMasterName(
      MASTER_CODE.VEHICLE_CATEGORY,
      info.vehicleCategory
    )
    obj.selectedSurcharge[0].branch_no = info.branchNo
    obj.selectedSurcharge[0].adjustment_money_kind = info.adjustmentMoneyKind
    obj.selectedSurcharge[0].adjustment_money_kind_name = getMasterName(
      MASTER_CODE.ADJUSTMENT_MONEY_KIND,
      info.adjustmentMoneyKind
    )
    obj.selectedSurcharge[0].valid_begin_date = newBeginDate
    obj.selectedSurcharge[0].adjustment_money_rate = info.adjustmentMoneyRate
    obj.selectedSurcharge[0].freight_item_id = info.freightItemId
    obj.selectedSurcharge[0].freight_item_name = info.freightItemName
  } else if (obj.suchargeDetailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //「追加」ボタン
    let tableItemsLength = countByVehicleCategory(
      obj,
      addInfo.vehicle_category,
      addInfo.branch_no,
      addInfo.adjustment_money_kind
    )
    let lastIndex = findLastIndex(
      obj,
      addInfo.vehicle_category,
      addInfo.branch_no,
      addInfo.adjustment_money_kind
    )

    if (tableItemsLength > 0) {
      // YYYY/MM/DD - days
      let newEndDate = subtractDaysToString(obj, 1, newBeginDate)
      if (lastIndex != null) {
        obj.formData.surchargeDtos[lastIndex].valid_end_date = newEndDate
      }
    }
    obj.formData.surchargeDtos.push(addInfo)
    sortsurchargeDtos(obj)
    obj.surchargeTableItems = obj.formData.surchargeDtos
  }
}
function sortsurchargeDtos(obj) {
  obj.formData.surchargeDtos.sort(
    dynamicSortMultiple(
      'vehicle_category',
      'valid_begin_date',
      'branch_no',
      'adjustment_money_kind'
    )
  )
}
/**
 * YYYY/MM/DD - days
 * @param {*} obj
 * @param {*} days
 * @param {*} target
 */
function subtractDaysToString(obj, days, target) {
  let tStartDate = obj.$moment(new Date(target))
  let addedStartDate = tStartDate.subtract(days, 'days').format('YYYYMMDD')
  addedStartDate = displaYyyymmdd(obj, addedStartDate)
  return addedStartDate
}

function dynamicSortMultiple() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = arguments
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2)
      i++
    }
    return result
  }
}

function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}
