import {
  openMsgModal,
  openSubModal,
  formatHHmmss,
  formatYYYYMMDD,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import RegularOrderService from './RegularOrderService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    RegularOrderService.get(obj.regularOrderId)
      .then(
        (response) => {
          obj.formData = response.data

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.regularOrderId = null
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
          } else {
            obj.formData.regularOrderId = obj.regularOrderId
          }
          obj.oriData = _.cloneDeep(obj.formData)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.formData.deleted == '1') {
    obj.formData.deleted = '0'
  }

  if (!obj.formData.arrivalAddDays) {
    obj.formData.arrivalAddDays = '0'
  }

  obj.formData.departureBeginTime = formatHHmmss(
    obj.formData.departureBeginTime
  )
  obj.formData.departureEndTime = formatHHmmss(obj.formData.departureEndTime)
  obj.formData.arrivalBeginTime = formatHHmmss(obj.formData.arrivalBeginTime)
  obj.formData.arrivalEndTime = formatHHmmss(obj.formData.arrivalEndTime)

  obj.formData.validBeginDate = formatYYYYMMDD(obj, obj.formData.validBeginDate)
  obj.formData.validEndDate = formatYYYYMMDD(obj, obj.formData.validEndDate)

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = RegularOrderService.create(obj.formData)
  } else {
    // 詳細
    callApi = RegularOrderService.update(obj.regularOrderId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function setPlaceInfo(obj, selectedItem) {
  if (obj.placeModalKbn == 1) {
    //搬入
    obj.formData.arrivalPlaceId = selectedItem.place_id
    obj.formData.arrivalPlaceCode = selectedItem.place_code
    obj.formData.arrivalPlaceName = selectedItem.place_name
  } else {
    //搬出
    obj.formData.departurePlaceId = selectedItem.place_id
    obj.formData.departurePlaceCode = selectedItem.place_code
    obj.formData.departurePlaceName = selectedItem.place_name
  }
  return
}
export function unsetPlaceInfo(obj) {
  if (obj.placeModalKbn == 1) {
    //搬入
    obj.formData.arrivalPlaceId = null
    obj.formData.arrivalPlaceCode = null
    obj.formData.arrivalPlaceName = null
  } else {
    //搬出
    obj.formData.departurePlaceId = null
    obj.formData.departurePlaceCode = null
    obj.formData.departurePlaceName = null
  }
  return
}
