/**
 * 方面・コースマスタデータ.
 * @class
 * @exports
 */
export default class Area {
  areaId = null
  areaName = null
  blockCode = null
  blockName = null
  areaPriorityOrder = 0
  areaKind = null
  areaKindName = null
  deleted = 0
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null
  areaDetails = []
}
