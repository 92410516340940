/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class place {
  //--------------TAB1------------//
  placeId
  publicFlg = '0' // 非公開
  publicFlgName = null
  placeCode = null
  placeName = null
  planningGroupCode = null
  ownerId = null
  ownerCode = null
  ownerName = null
  postalCode = null
  cityCode = null
  cityName = null
  address1 = null
  address2 = null
  tel = null
  fax = null
  representativeName = null
  latitude = null
  longitude = null
  accuracyLevel = '0' // 未設定
  note = null
  placeKind = '10' // 搬出
  //--------------TAB1------------//

  //--------------TAB2------------//
  departureBeginTime = null
  departureEndTime = null
  departurePreparationTime = 0
  departureUnitTime = 0
  departureTimeStrict = null
  impossibleDepartureBeginTime1 = null
  impossibleDepartureEndTime1 = null
  impossibleDepartureBeginTime2 = null
  impossibleDepartureEndTime2 = null
  impossibleDepartureBeginTime3 = null
  impossibleDepartureEndTime3 = null
  //--------------TAB2------------//

  //--------------TAB3------------//
  arrivalBeginTime = null
  arrivalEndTime = null
  arrivalPreparationTime = 0
  arrivalUnitTime = 0
  arrivalTimeStrict = null
  impossibleArrivalBeginTime1 = null
  impossibleArrivalEndTime1 = null
  impossibleArrivalBeginTime2 = null
  impossibleArrivalEndTime2 = null
  impossibleArrivalBeginTime3 = null
  impossibleArrivalEndTime3 = null
  //--------------TAB3------------//

  //--------------TAB4------------//
  maxAvailableEntrySize = null
  berthCount = null
  fixedCost = 0
  variableCost = 0
  //--------------TAB4------------//

  //--------------TAB5------------//
  vehicleCondition01 = null
  vehicleCondition02 = null
  vehicleCondition03 = null
  vehicleCondition04 = null
  vehicleCondition05 = null
  vehicleCondition06 = null
  vehicleCondition07 = null
  vehicleCondition08 = null
  vehicleCondition09 = null
  vehicleCondition10 = null
  vehicleCondition11 = null
  vehicleCondition12 = null
  vehicleCondition13 = null
  vehicleCondition14 = null
  vehicleCondition15 = null
  vehicleCondition16 = null
  vehicleCondition17 = null
  vehicleCondition18 = null
  vehicleCondition19 = null
  vehicleCondition20 = null
  //--------------TAB5------------//

  //--------------TAB6------------//
  representativePlaceCode = null
  representativePlaceName = null
  placeType = '10' // 代表無し
  preloadable = '0' // 不可
  nearestHighwayIcId = null
  nearestHighwayIcCode = null
  nearestHighwayIcName = null
  calendarId = 0
  isOnetimeMaster = '0' // 通常
  deleted = '0' // 通常
  blockCode = null
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  //--------------TAB6------------//
}
