<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal-three"
      type="entry"
      displayModals="three"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 輸送手段マスタモーダル -->
    <VehicleModal
      id="vehicle-modal-edit"
      type="entry"
      selectMode="single"
      displayModals="two"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 荷姿参照モーダル-->
    <ProductModal
      id="product-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetProduct"
      @after-close-unset="afterCloseUnsetProduct"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import RegularOrderInfo from './RegularOrderInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND, INTEGER_MAX_MIN } from '@/common/const.js'
import {
  clear,
  goRegister,
  getBreadcrumbItems,
  back,
} from '@/common/screen-common.js'
import {
  init,
  setPlaceInfo,
  unsetPlaceInfo,
  register,
} from '@/order/regularorder/RegularOrderDetail.js'

const screenId = SCREEN_ID.REGULAR_ORDER_DETAIL

export default {
  name: 'regularOrderDetail',

  components: {
    ProductModal: () => import('@/master/product/ProductModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    regularOrderId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      selectLonLat: null,
      formData: new RegularOrderInfo(),
      obj: this,
      registerMessage: null,
      oriData: new RegularOrderInfo(),
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 基本情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 定期オーダー名
              type: 'text',
              id: 'regularOrderName',
              columnInfo: this.detailInfo.regular_order_name,
              maxLength: 30,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 定期オーダー識別コード
              type: 'text',
              id: 'configCode',
              columnInfo: this.detailInfo.config_code,
              maxLength: 5,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 貨物
              type: 'referenceCodeName',
              codeId: 'productCode',
              nameId: 'productName',
              codeColumnInfo: this.detailInfo.product_code,
              nameColumnInfo: this.detailInfo.product_name,
              btnColumnInfo: this.detailInfo.sub_modal_product,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.openSubModal(this, 'product-modal')
              },
            },
            {
              // タリフ判別商品コード
              type: 'text',
              id: 'tariffProductCode',
              columnInfo: this.detailInfo.tariff_product_code,
              maxLength: 50,
            },
            {
              // 長さ
              type: 'text',
              id: 'length',
              columnInfo: this.detailInfo.length,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 幅
              type: 'text',
              id: 'width',
              columnInfo: this.detailInfo.width,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 高さ
              type: 'text',
              id: 'height',
              columnInfo: this.detailInfo.height,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 重量
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 容量
              type: 'text',
              id: 'volume',
              columnInfo: this.detailInfo.volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 搬出場所
              type: 'referenceCodeName',
              codeId: 'departurePlaceCode',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_code,
              nameColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              codeMaxLength: 30,
              nameMaxLength: 50,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 2
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 搬出時間
              type: 'timeFromTo',
              fromId: 'departureBeginTime',
              toId: 'departureEndTime',
              fromColumnInfo: this.detailInfo.departure_begin_time,
              toColumnInfo: this.detailInfo.departure_end_time,
              locale: this.locale,
              fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 搬入場所
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceCode',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_code,
              nameColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place,
              codeMaxLength: 30,
              nameMaxLength: 50,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              //到着加算日
              type: 'text',
              id: 'arrivalAddDays',
              columnInfo: this.detailInfo.arrival_add_days,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 搬入時間
              type: 'timeFromTo',
              fromId: 'arrivalBeginTime',
              toId: 'arrivalEndTime',
              fromColumnInfo: this.detailInfo.arrival_begin_time,
              toColumnInfo: this.detailInfo.arrival_end_time,
              locale: this.locale,
              fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 荷主
              type: 'referenceCodeName',
              codeId: 'ownerCode',
              nameId: 'ownerName',
              codeColumnInfo: this.detailInfo.owner_code,
              nameColumnInfo: this.detailInfo.owner_name,
              btnColumnInfo: this.detailInfo.sub_modal_owner,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 1
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.OWNER
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 指定運送会社
              type: 'referenceCodeName',
              codeId: 'carrierCode',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_code,
              nameColumnInfo: this.detailInfo.carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_carrier,
              codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
              onClickCallback: () => {
                this.departmentModalKbn = 2
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CARRIER
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 指定輸送手段
              type: 'referenceCodeName',
              codeId: 'vehicleCode',
              nameId: 'vehicleName',
              codeColumnInfo: this.detailInfo.vehicle_code,
              nameColumnInfo: this.detailInfo.vehicle_name,
              btnColumnInfo: this.detailInfo.sub_modal_vehicle,
              codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
              onClickCallback: () => {
                this.openSubModal(this, 'vehicle-modal-edit')
              },
            },

            {
              //オーダー生成数
              type: 'text',
              id: 'generateCount',
              columnInfo: this.detailInfo.generate_count,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 有効期間
              type: 'dateFromTo',
              fromId: 'validBeginDate',
              toId: 'validEndDate',
              fromColumnInfo: this.detailInfo.valid_begin_date,
              toColumnInfo: this.detailInfo.valid_end_date,
              locale: this.locale,
              fromRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATERANGE,
              ],
            },
            {
              //生成タイミング
              type: 'text',
              id: 'generateTiming',
              columnInfo: this.detailInfo.generate_timing,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 曜日（月）
              type: 'checkbox',
              id: 'mondayed',
              columnInfo: this.detailInfo.monday,
            },
            {
              // 曜日（火）
              type: 'checkbox',
              id: 'tuesdayed',
              columnInfo: this.detailInfo.tuesday,
            },
            {
              // 曜日（水）
              type: 'checkbox',
              id: 'wednesdayed',
              columnInfo: this.detailInfo.wednesday,
            },
            {
              // 曜日（木）
              type: 'checkbox',
              id: 'thursdayed',
              columnInfo: this.detailInfo.thursday,
            },
            {
              // 曜日（金）
              type: 'checkbox',
              id: 'fridayed',
              columnInfo: this.detailInfo.friday,
            },
            {
              // 曜日（土）
              type: 'checkbox',
              id: 'saturdayed',
              columnInfo: this.detailInfo.saturday,
            },
            {
              // 曜日（日）
              type: 'checkbox',
              id: 'sundayed',
              columnInfo: this.detailInfo.sunday,
            },
          ],
        },
        {
          // 輸送手段情報タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 輸送手段条件１
              type: 'select',
              id: 'vehicleCondition01',
              columnInfo: this.detailInfo.vehicle_condition01,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２
              type: 'select',
              id: 'vehicleCondition02',
              columnInfo: this.detailInfo.vehicle_condition02,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件３
              type: 'select',
              id: 'vehicleCondition03',
              columnInfo: this.detailInfo.vehicle_condition03,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件４
              type: 'select',
              id: 'vehicleCondition04',
              columnInfo: this.detailInfo.vehicle_condition04,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件５
              type: 'select',
              id: 'vehicleCondition05',
              columnInfo: this.detailInfo.vehicle_condition05,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件６
              type: 'select',
              id: 'vehicleCondition06',
              columnInfo: this.detailInfo.vehicle_condition06,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件７
              type: 'select',
              id: 'vehicleCondition07',
              columnInfo: this.detailInfo.vehicle_condition07,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件８
              type: 'select',
              id: 'vehicleCondition08',
              columnInfo: this.detailInfo.vehicle_condition08,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件９
              type: 'select',
              id: 'vehicleCondition09',
              columnInfo: this.detailInfo.vehicle_condition09,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１０
              type: 'select',
              id: 'vehicleCondition10',
              columnInfo: this.detailInfo.vehicle_condition10,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１１
              type: 'select',
              id: 'vehicleCondition11',
              columnInfo: this.detailInfo.vehicle_condition11,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１２
              type: 'select',
              id: 'vehicleCondition12',
              columnInfo: this.detailInfo.vehicle_condition12,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１３
              type: 'select',
              id: 'vehicleCondition13',
              columnInfo: this.detailInfo.vehicle_condition13,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１４
              type: 'select',
              id: 'vehicleCondition14',
              columnInfo: this.detailInfo.vehicle_condition14,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１５
              type: 'select',
              id: 'vehicleCondition15',
              columnInfo: this.detailInfo.vehicle_condition15,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１６
              type: 'select',
              id: 'vehicleCondition16',
              columnInfo: this.detailInfo.vehicle_condition16,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１７
              type: 'select',
              id: 'vehicleCondition17',
              columnInfo: this.detailInfo.vehicle_condition17,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１８
              type: 'select',
              id: 'vehicleCondition18',
              columnInfo: this.detailInfo.vehicle_condition18,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１９
              type: 'select',
              id: 'vehicleCondition19',
              columnInfo: this.detailInfo.vehicle_condition19,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２０
              type: 'select',
              id: 'vehicleCondition20',
              columnInfo: this.detailInfo.vehicle_condition20,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // バージョン
              type: 'text',
              id: 'version',
              columnInfo: this.detailInfo.version,
            },
            {
              // トリガ更新日時
              type: 'text',
              id: 'updateByTrigger',
              columnInfo: this.detailInfo.update_by_trigger,
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    setPlaceInfo,
    unsetPlaceInfo,
    register,
    back,

    /**
     * 荷姿マスタから取得データ設定
     */
    afterCloseSetProduct(productInfos) {
      for (let productInfo of productInfos) {
        // 貨物
        this.obj.formData.productCode = productInfo.product_code
        this.obj.formData.productName = productInfo.product_name

        // // 数量
        // this.obj.formData.quantity = 1
        // 容量
        this.obj.formData.volume = productInfo.volume
        // 重量
        this.obj.formData.weight = productInfo.weight
        // 長さ
        this.obj.formData.length = productInfo.length
        // 幅
        this.obj.formData.width = productInfo.width
        // 高さ
        this.obj.formData.height = productInfo.height

        //タリフ判別商品コード
        this.obj.formData.tariffProductCode = productInfo.tariff_product_code
        break
      }
    },
    /**
     * 荷姿情報解除
     */
    afterCloseUnsetProduct() {
      // 貨物
      this.obj.formData.productCode = null
      this.obj.formData.productName = null

      // // 数量
      // this.obj.formData.quantity = null
      // 容量
      this.obj.formData.volume = null
      // 重量
      this.obj.formData.weight = null
      // 長さ
      this.obj.formData.length = null
      // 幅
      this.obj.formData.width = null
      // 高さ
      this.obj.formData.height = null

      //タリフ判別商品コード
      this.obj.formData.tariffProductCode = null
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      if (this.departmentModalKbn == 1) {
        //荷主
        this.obj.formData.ownerId = id
        this.obj.formData.ownerCode = code
        this.obj.formData.ownerName = name
      } else if (this.departmentModalKbn == 2) {
        //運送会社
        this.obj.formData.carrierId = id
        this.obj.formData.carrierCode = code
        this.obj.formData.carrierName = name
      }
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      if (this.departmentModalKbn == 1) {
        //荷主
        this.obj.formData.ownerId = null
        this.obj.formData.ownerCode = null
        this.obj.formData.ownerName = null
      } else if (this.departmentModalKbn == 2) {
        //運送会社
        this.obj.formData.carrierId = null
        this.obj.formData.carrierCode = null
        this.obj.formData.carrierName = null
      }
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      setPlaceInfo(this.obj, selectedItem)
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      unsetPlaceInfo(this.obj)
    },

    /**
     * 輸送手段マスタから取得データ設定
     */
    afterCloseSetVehicle(selectedItem) {
      this.obj.formData.vehicleId = selectedItem.vehicle_id
      this.obj.formData.vehicleCode = selectedItem.vehicle_code
      this.obj.formData.vehicleName = selectedItem.vehicle_name
    },
    /**
     * 輸送手段情報解除
     */
    afterCloseUnsetVehicle() {
      this.obj.formData.vehicleId = null
      this.obj.formData.vehicleCode = null
      this.obj.formData.vehicleName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },

  // mounted() {
  //     this.$refs.map.initialize()
  // }
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}
</style>
