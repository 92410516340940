/**
 * 特殊車両通行許可
 */
export default class passagePermit {
  //--------------------
  // 許可証情報タブ
  //--------------------
  passagePermitId
  blockCode = null
  blockName = null

  //運送会社
  carrierId = null
  carrierCode = null
  carrierName = null
  //許可番号
  permitNo = null
  //有効開始日付
  validBeginDate = null
  //有効終了日付
  validEndDate = null
  //許可証条件
  permitCondition = null
  permitConditionName = null
  //通行区分(往復区分)
  onewayOrRound = null
  //誘導者有無(要誘導)
  needGuide = null
  // 通行可能時間帯From
  permitTimeFrom = null
  // 通行可能時間帯To
  permitTimeTo = null
  // 高速使用有無(高速利用可)
  useHighway = null
  //長さL
  length
  //幅W
  width
  //高さH
  height
  //総重量
  weight
  // ルートの説明(経路説明)
  routeDescription = null
  // 経路数
  routeCount
  // ルート数(総経路数)
  totalRouteCount
  // 担当部門
  chargeDepartmentId = null
  chargeDepartmentCode = null
  chargeDepartmentName = null

  // 予備1（支払済フラグ）
  reserve01 = null
  // 予備2（支払処理日付）
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null

  // 新規時許可証番号
  firstPermitNo = null
  // 前回許可証番号
  previousPermitNo = null

  //--------------------
  // 許可車両情報タブ
  //--------------------
  //許可車両ID
  permitVehicleId
  //車種(トラック／トレーラ区分)
  permitRealVehicleKind = null
  permitRealVehicleKindName = null

  //実車番ID(トラック・トラクタID)
  realVehicleId = null
  //車番(トラック・トラクタ)
  realVehicleNumberplate = null
  //車型(トラック・トラクタ)
  realVehicleModel = null
  realVehicleModelName = null
  //車種(トラック・トラクタ)
  realVehicleKind = null
  realVehicleKindName = null

  //実車番ID(トレーラID)
  realTrailerId = null
  //車番(トレーラ)
  realTrailerNumberplate = null
  //車型(トレーラ)
  realTrailerModel = null
  realTrailerModelName = null
  //車種(トレーラ)
  realTrailerKind = null
  realTrailerKindName = null

  //--------------------
  // 許可場所情報タブ
  //--------------------
  //許可車両ID
  permitRoutePlaceId
  // 場所区分
  placeKind = null
  placeKindName = null
  //場所
  placeId = null
  placeCode = null
  placeName = null
  address1 = null
  address2 = null
  postalCode = null
  latitude
  longitude

  permitVehicleDtos = []
  permitRoutePlaceDtos = []
}
