import {
  openMsgModal,
  openSubModal,
  displayHHmmss,
  getMasterName,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  MASTER_CODE,
} from '@/common/const.js'
import router from '@/router'
import ProductPatternService from './ProductPatternService'

const screenId = SCREEN_ID.PRODUCT_PATTERN_LIST
const detailScreenId = SCREEN_ID.PRODUCT_PATTERN_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  ProductPatternService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        obj.tableItems.forEach((element) => {
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          element.departure_end_time = displayHHmmss(element.departure_end_time)
          element.impossible_departure_begin_time_1 = displayHHmmss(
            element.impossible_departure_begin_time_1
          )
          element.impossible_departure_begin_time_2 = displayHHmmss(
            element.impossible_departure_begin_time_2
          )
          element.impossible_departure_begin_time_3 = displayHHmmss(
            element.impossible_departure_begin_time_3
          )
          element.impossible_departure_end_time_1 = displayHHmmss(
            element.impossible_departure_end_time_1
          )
          element.impossible_departure_end_time_2 = displayHHmmss(
            element.impossible_departure_end_time_2
          )
          element.impossible_departure_end_time_3 = displayHHmmss(
            element.impossible_departure_end_time_3
          )
          element.arrival_begin_time = displayHHmmss(element.arrival_begin_time)
          element.arrival_end_time = displayHHmmss(element.arrival_end_time)
          element.impossible_arrival_begin_time_1 = displayHHmmss(
            element.impossible_arrival_begin_time_1
          )
          element.impossible_arrival_begin_time_2 = displayHHmmss(
            element.impossible_arrival_begin_time_2
          )
          element.impossible_arrival_begin_time_3 = displayHHmmss(
            element.impossible_arrival_begin_time_3
          )
          element.impossible_arrival_end_time_1 = displayHHmmss(
            element.impossible_arrival_end_time_1
          )
          element.impossible_arrival_end_time_2 = displayHHmmss(
            element.impossible_arrival_end_time_2
          )
          element.impossible_arrival_end_time_3 = displayHHmmss(
            element.impossible_arrival_end_time_3
          )
          element.prefecture_name = getMasterName(
            MASTER_CODE.PREFECTURE,
            element.prefecture_code
          )
        })
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        productPatternId: obj.selectedItem[0].product_pattern_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「新規」ボタンクリック
export function goNew() {
  router.push({
    name: detailScreenId,
    params: {
      screenKbn: DETAIL_SCREEN_KBN.NEW,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: { isReSearch: true },
        },
      },
    },
  })
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        productPatternId: obj.selectedItem[0].product_pattern_id,
        screenKbn: DETAIL_SCREEN_KBN.COPY,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「削除」ボタンクリック
export function goRemove(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.delete.label
  )
  // obj.selectedItem.some((element) => {
  //     if (element.place_type == '20') {
  //         // 場所纏めデータ(代表)の場合（場所纏め区分=20(代表)(※複数選択した場合、1件でも代表データの場合)
  //         obj.removeMessage = obj.$store.getters['init/getMessage'](
  //             'MB01S001_MG001Q'
  //         )
  //         return true
  //     }
  // })
  openSubModal(obj, 'remove-modal')
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var productPatternId = obj.selectedItem[index].product_pattern_id
      obj.$store.dispatch('init/setServiceLoading')
      await ProductPatternService.get(productPatternId)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await ProductPatternService.removeList(removeList)
        .then(
          () => {
            // 警告モーダル表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}
