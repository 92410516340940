import {
  openMsgModal,
  openSubModal,
  getMasterName,
  formatYYYYMMDD,
  formatHHmmss,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN, MASTER_CODE } from '@/common/const.js'
import PassagePermitService from './PassagePermitService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    PassagePermitService.get(obj.passagePermitId)
      .then(
        (response) => {
          obj.formData = response.data

          var vehicles = changeSnakeCaseVehicle(obj)
          obj.formData.permitVehicleDtos = vehicles
          obj.tableItemsVehicle = obj.formData.permitVehicleDtos

          var places = changeSnakeCasePlace(obj)
          obj.formData.permitRoutePlaceDtos = places
          obj.tableItemsPlace = obj.formData.permitRoutePlaceDtos

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            // コピー
            obj.formData.passagePermitId = null
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
            // obj.formData.permitVehicleDtos.map(function (x) {
            //   x.permit_vehicle_detail_id = ''
            //   x.registration_time_display = null
            //   x.registration_user = null
            //   x.update_time_display = null
            //   x.update_user = null
            //   return x
            // })
          } else {
            obj.formData.passagePermitId = obj.passagePermitId
          }
          obj.oriData = _.cloneDeep(obj.formData)
          obj.oriTableItemsVehicle = _.cloneDeep(obj.tableItemsVehicle)
          obj.oriTableItemsPlace = _.cloneDeep(obj.tableItemsPlace)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    //DETAIL_SCREEN_KBN.NEW
    obj.formData.passagePermitId = null
    obj.formData.permitVehicleDtos = []
    obj.formData.permitRoutePlaceDtos = []
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  var OriDataVehicle = obj.formData.permitVehicleDtos
  obj.formData.permitVehicleDtos = changeCamelVehicle(obj)
  var OriDataPlace = obj.formData.permitRoutePlaceDtos
  obj.formData.permitRoutePlaceDtos = changeCamelPlace(obj)

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.formData.validBeginDate = formatYYYYMMDD(obj, obj.formData.validBeginDate)
  obj.formData.validEndDate = formatYYYYMMDD(obj, obj.formData.validEndDate)
  obj.formData.reserve02 = formatYYYYMMDD(obj, obj.formData.reserve02)

  obj.formData.permitTimeFrom = formatHHmmss(obj.formData.permitTimeFrom)
  obj.formData.permitTimeTo = formatHHmmss(obj.formData.permitTimeTo)

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = PassagePermitService.create(obj.formData)
  } else {
    // 詳細
    callApi = PassagePermitService.update(obj.passagePermitId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        obj.formData.permitVehicleDtos = OriDataVehicle
        obj.formData.permitRoutePlaceDtos = OriDataPlace
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.formData.permitVehicleDtos = _.cloneDeep(obj.oriData.permitVehicleDtos)
  obj.formData.permitRoutePlaceDtos = _.cloneDeep(
    obj.oriData.permitRoutePlaceDtos
  )
  obj.tableItemsVehicle = obj.formData.permitVehicleDtos
  obj.tableItemsPlace = obj.formData.permitRoutePlaceDtos

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function removeVehicle(obj) {
  // 複数個削除
  for (let info of obj.selectedVehicle) {
    const index = obj.formData.permitVehicleDtos.indexOf(info)
    obj.formData.permitVehicleDtos.splice(index, 1)
  }
  obj.tableItemsVehicle = obj.formData.permitVehicleDtos
}

export function removePlace(obj) {
  // 複数個削除
  for (let info of obj.selectedPlace) {
    const index = obj.formData.permitRoutePlaceDtos.indexOf(info)
    obj.formData.permitRoutePlaceDtos.splice(index, 1)
  }
  obj.tableItemsPlace = obj.formData.permitRoutePlaceDtos
}

export function addVehicle(obj) {
  if (obj == null) {
    return
  }
  obj.detailRowIndex = -1
  let addInfo = {
    permit_vehicle_id: null,
    permit_real_vehicle_kind: obj.formData.permitRealVehicleKind,
    permit_real_vehicle_kind_name: getMasterName(
      MASTER_CODE.REAL_VEHICLE_KIND,
      obj.formData.permitRealVehicleKind
    ),
    real_vehicle_id: obj.formData.realVehicleId,
    real_vehicle_numberplate: obj.formData.realVehicleNumberplate,
    real_vehicle_model: obj.formData.realVehicleModel,
    real_vehicle_model_name: obj.formData.realVehicleModelName,
    real_vehicle_kind: obj.formData.realVehicleKind,
    real_vehicle_kind_name: obj.formData.realVehicleKindName,
    real_trailer_id: obj.formData.realTrailerId,
    real_trailer_numberplate: obj.formData.realTrailerNumberplate,
    real_trailer_model: obj.formData.realTrailerModel,
    real_trailer_model_name: obj.formData.realTrailerModelName,
    real_trailer_kind: obj.formData.realTrailerKind,
    real_trailer_kind_name: obj.formData.realTrailerKindName,
  }

  // 行追加時、チェック
  if (
    addInfo.permit_real_vehicle_kind == null ||
    addInfo.real_vehicle_id == null
  ) {
    return
  }

  obj.formData.permitVehicleDtos.push(addInfo)
  obj.tableItemsVehicle = obj.formData.permitVehicleDtos
}

export function addPlace(obj) {
  obj.detailRowIndex = -1
  let addInfo = {
    permit_route_place_id: null,
    place_id: obj.formData.placeId,
    place_kind: obj.formData.placeKind,
    place_kind_name: getMasterName(
      MASTER_CODE.PLACE_LEVEL,
      obj.formData.placeKind
    ),
    place_code: obj.formData.placeCode,
    place_name: obj.formData.placeName,
    address1: obj.formData.address1,
    address2: obj.formData.address2,
    postal_code: obj.formData.postalCode,
    latitude: obj.formData.latitude,
    longitude: obj.formData.longitude,
  }

  // 行追加時、チェック
  if (addInfo.place_kind == null || addInfo.place_code == null) {
    return
  }

  obj.formData.permitRoutePlaceDtos.push(addInfo)
  obj.tableItemsPlace = obj.formData.permitRoutePlaceDtos
}

function changeCamelVehicle(obj) {
  var vehicles_camel = convertCamelCace(obj.formData.permitVehicleDtos)

  return vehicles_camel
}

function changeCamelPlace(obj) {
  var places_camel = convertCamelCace(obj.formData.permitRoutePlaceDtos)

  return places_camel
}

function changeSnakeCaseVehicle(obj) {
  var vehicles = convertSnakeCace(obj.formData.permitVehicleDtos)
  vehicles.sort(function (a, b) {
    return a.permit_vehicle_id - b.permit_vehicle_id
  })
  return vehicles
}

function changeSnakeCasePlace(obj) {
  var places = convertSnakeCace(obj.formData.permitRoutePlaceDtos)
  places.sort(function (a, b) {
    return a.permit_route_place_id - b.permit_route_place_id
  })
  return places
}
