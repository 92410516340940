<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goRemove,
  remove,
} from '@/master/sectionDivision/SectionDivisionList.js'

const screenId = SCREEN_ID.SECTION_DIVISION_LIST

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      placeModalKbn: 0,
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 搬出場所区分
            type: 'select',
            id: 'departurePlaceType',
            columnInfo: searchConditionInfo.departure_place_type,
            options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
            onChangeCallback: () => {},
          },
          {
            // 搬出場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 搬入場所区分
            type: 'select',
            id: 'arrivalPlaceType',
            columnInfo: searchConditionInfo.arrival_place_type,
            options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
            onChangeCallback: () => {},
          },
          {
            // 搬入場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.placeModalKbn = 2
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 分割パターン名
            type: 'text',
            id: 'dividePatternName',
            columnInfo: searchConditionInfo.divide_pattern_name,
          },
          {
            // デフォルト区分
            type: 'select',
            id: 'defaultType',
            columnInfo: searchConditionInfo.default_type,
            options: getListOptions(this.MASTER_CODE.DEFAULT_TYPE),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 編集ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew()
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    reSearch,
    search,
    clear,
    remove,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        departurePlaceType: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceType: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        dividePatternName: null,
        defaultType: null,
        deleted: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else if (this.placeModalKbn == 2) {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceId = null
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else if (this.placeModalKbn == 2) {
        this.searchCondition.arrivalPlaceId = null
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
