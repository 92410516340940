<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import ProductInfo from './ProductInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND, INTEGER_MAX_MIN } from '@/common/const.js'
import {
  clear,
  goRegister,
  getBreadcrumbItems,
  back,
} from '@/common/screen-common.js'
import { init, register } from '@/master/product/ProductDetail.js'

const screenId = SCREEN_ID.PRODUCT_DETAIL

export default {
  name: 'productDetail',

  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    productId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      formData: new ProductInfo(),
      obj: this,
      registerMessage: null,
      oriData: new ProductInfo(),
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 荷姿タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 荷姿ID
              type: 'text',
              id: 'productId',
              columnInfo: this.detailInfo.product_id,
            },
            {
              // ブロック外公開フラグ
              type: 'select',
              id: 'publicFlg',
              columnInfo: this.detailInfo.public_flg,
              options: getListOptions(this.MASTER_CODE.PUBLIC_FLG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 商品コード
              type: 'text',
              id: 'productCode',
              columnInfo: this.detailInfo.product_code,
              maxLength: 50,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
            },
            {
              // 商品名（代表）
              type: 'text',
              id: 'productName',
              columnInfo: this.detailInfo.product_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // タリフ判別商品コード
              type: 'text',
              id: 'tariffProductCode',
              columnInfo: this.detailInfo.tariff_product_code,
              maxLength: 50,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 荷主
              type: 'referenceCodeName',
              codeId: 'ownerCode',
              nameId: 'ownerName',
              codeColumnInfo: this.detailInfo.owner_code,
              nameColumnInfo: this.detailInfo.owner_name,
              btnColumnInfo: this.detailInfo.sub_modal_owner,
              onClickCallback: () => {
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.OWNER
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // 長さ
              type: 'text',
              id: 'length',
              columnInfo: this.detailInfo.length,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 幅
              type: 'text',
              id: 'width',
              columnInfo: this.detailInfo.width,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 高さ
              type: 'text',
              id: 'height',
              columnInfo: this.detailInfo.height,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 質量
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 容量
              type: 'text',
              id: 'volume',
              columnInfo: this.detailInfo.volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 数量単位
              type: 'select',
              id: 'countUnit',
              columnInfo: this.detailInfo.count_unit,
              options: getListOptions(this.MASTER_CODE.COUNT_UNIT),
              onChangeCallback: () => {},
            },
            {
              // 混載不可フラグ
              type: 'select',
              id: 'mixloadImpossibleFlag',
              columnInfo: this.detailInfo.mixload_impossible_flag,
              options: getListOptions(this.MASTER_CODE.MIXLOAD_IMPOSSIBLE_FLAG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // マスタ区分
              type: 'select',
              id: 'isOnetimeMaster',
              columnInfo: this.detailInfo.is_onetime_master,
              options: getListOptions(this.MASTER_CODE.IS_ONETIME_MASTER),
              onChangeCallback: () => {},
            },
            {
              // 自動区間分割可否
              type: 'select',
              id: 'autoSectionDivisions',
              columnInfo: this.detailInfo.auto_section_divisions,
              options: getListOptions(this.MASTER_CODE.AUTO_SECTION_DIVISIONS),
              onChangeCallback: () => {},
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 商品予備１
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
            },
            {
              // 商品予備２
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
            },
            {
              // 商品予備３
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
            },
            {
              // 商品予備４
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
            },
            {
              // 商品予備５
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
            },
            {
              // 商品予備６
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
            },
            {
              // 商品予備７
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              // 商品予備８
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              // 商品予備９
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              // 商品予備１０
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
            },
            {
              // 注意事項
              type: 'text',
              id: 'warningMessage',
              columnInfo: this.detailInfo.warning_message,
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // バージョン
              type: 'text',
              id: 'version',
              columnInfo: this.detailInfo.version,
            },
            {
              // トリガ更新日時
              type: 'text',
              id: 'updateByTrigger',
              columnInfo: this.detailInfo.update_by_trigger,
            },
          ],
        },
        {
          // 輸送手段条件情報タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 輸送手段条件１
              type: 'select',
              id: 'vehicleCondition01',
              columnInfo: this.detailInfo.vehicle_condition01,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２
              type: 'select',
              id: 'vehicleCondition02',
              columnInfo: this.detailInfo.vehicle_condition02,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件３
              type: 'select',
              id: 'vehicleCondition03',
              columnInfo: this.detailInfo.vehicle_condition03,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件４
              type: 'select',
              id: 'vehicleCondition04',
              columnInfo: this.detailInfo.vehicle_condition04,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件５
              type: 'select',
              id: 'vehicleCondition05',
              columnInfo: this.detailInfo.vehicle_condition05,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件６
              type: 'select',
              id: 'vehicleCondition06',
              columnInfo: this.detailInfo.vehicle_condition06,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件７
              type: 'select',
              id: 'vehicleCondition07',
              columnInfo: this.detailInfo.vehicle_condition07,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件８
              type: 'select',
              id: 'vehicleCondition08',
              columnInfo: this.detailInfo.vehicle_condition08,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件９
              type: 'select',
              id: 'vehicleCondition09',
              columnInfo: this.detailInfo.vehicle_condition09,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１０
              type: 'select',
              id: 'vehicleCondition10',
              columnInfo: this.detailInfo.vehicle_condition10,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１１
              type: 'select',
              id: 'vehicleCondition11',
              columnInfo: this.detailInfo.vehicle_condition11,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１２
              type: 'select',
              id: 'vehicleCondition12',
              columnInfo: this.detailInfo.vehicle_condition12,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１３
              type: 'select',
              id: 'vehicleCondition13',
              columnInfo: this.detailInfo.vehicle_condition13,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１４
              type: 'select',
              id: 'vehicleCondition14',
              columnInfo: this.detailInfo.vehicle_condition14,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１５
              type: 'select',
              id: 'vehicleCondition15',
              columnInfo: this.detailInfo.vehicle_condition15,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１６
              type: 'select',
              id: 'vehicleCondition16',
              columnInfo: this.detailInfo.vehicle_condition16,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１７
              type: 'select',
              id: 'vehicleCondition17',
              columnInfo: this.detailInfo.vehicle_condition17,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１８
              type: 'select',
              id: 'vehicleCondition18',
              columnInfo: this.detailInfo.vehicle_condition18,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１９
              type: 'select',
              id: 'vehicleCondition19',
              columnInfo: this.detailInfo.vehicle_condition19,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２０
              type: 'select',
              id: 'vehicleCondition20',
              columnInfo: this.detailInfo.vehicle_condition20,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.formData.ownerId = id
      this.formData.ownerCode = code
      this.formData.ownerName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.formData.ownerId = null
      this.formData.ownerCode = null
      this.formData.ownerName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}
</style>
