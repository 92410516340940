import {
  openMsgModal,
  getMasterName,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MASTER_CODE, MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import OperationTimePatternService from './OperationTimePatternService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    OperationTimePatternService.get(obj.operationTimePatternId)
      .then(
        (response) => {
          obj.OperationTimePattern = response.data

          var patterns = changeSnakeCase(obj)
          obj.OperationTimePattern.patternDetails = patterns

          obj.tableItems = obj.OperationTimePattern.patternDetails
          // コピー
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.OperationTimePattern.operationTimePatternId = null
            obj.OperationTimePattern.registrationTimeDisplay = null
            obj.OperationTimePattern.registrationUser = null
            obj.OperationTimePattern.updateTimeDisplay = null
            obj.OperationTimePattern.updateUser = null
            obj.OperationTimePattern.patternDetails.map(function (x) {
              x.operation_time_pattern_detail_id = ''
              x.registration_time_display = null
              x.registration_user = null
              x.update_time_display = null
              x.update_user = null
              return x
            })
          } else {
            obj.OperationTimePattern.operationTimePatternId =
              obj.operationTimePatternId
          }

          obj.oriData = _.cloneDeep(obj.OperationTimePattern)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    //DETAIL_SCREEN_KBN.NEW
    obj.OperationTimePattern.patternDetails = []
  }
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.tableItems.length === 0) {
    // 明細を追加してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MH01S002_MG001E')
    )
  } else {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.register.label
    )
    if (obj.OperationTimePattern.deleted == '1') {
      obj.registerMessage = obj.$store.getters['init/getMessage'](
        'YZ00MG905Q',
        obj.detailCtrlInfo.register.label
      )
    }
    openSubModal(obj, 'register-modal')
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')
  var OriData = obj.OperationTimePattern.patternDetails
  obj.OperationTimePattern.patternDetails = changeCamelCase(obj)
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = OperationTimePatternService.create(obj.OperationTimePattern)
  } else {
    // 詳細
    callApi = OperationTimePatternService.update(
      obj.operationTimePatternId,
      obj.OperationTimePattern
    )
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'operationTimePattern-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        obj.OperationTimePattern.patternDetails = OriData

        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function remove(obj) {
  // 複数個削除
  for (let info of obj.selectedItem) {
    const index = obj.OperationTimePattern.patternDetails.indexOf(info)
    obj.OperationTimePattern.patternDetails.splice(index, 1)
  }
  obj.tableItems = obj.OperationTimePattern.patternDetails
}

export function edit(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    obj.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
    obj.selectedOperationTimePatternDetail.push(obj)
    openSubModal(obj, 'edit-modal')
  }
}

export function add(obj) {
  obj.detailRowIndex = -1
  obj.detailScreenKbn = DETAIL_SCREEN_KBN.NEW
  obj.selectedOperationTimePatternDetail.push(obj)
  openSubModal(obj, 'edit-modal')
}

export function clear(obj) {
  obj.OperationTimePattern = _.cloneDeep(obj.oriData)
  obj.OperationTimePattern.patternDetails = _.cloneDeep(
    obj.oriData.patternDetails
  )
  obj.tableItems = obj.OperationTimePattern.patternDetails || []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function addRow(obj, info) {
  if (info != null) {
    let addInfo = {
      //稼動時間パターン明細ＩＤ
      operation_time_pattern_detail_id: info.operationTimePatternDetailId,
      //表示順
      display_order: info.displayOrder,
      //輸送形態区分
      transport_type_kind: info.transportTypeKind,
      //輸送形態区分名
      transport_type_kind_name: getMasterName(
        MASTER_CODE.TRANSPORT_TYPE_KIND,
        info.transportTypeKind
      ),
      //自動配車対象外フラグ
      auto_planning_exclude_flag: info.autoPlanningExcludeFlag,
      //自動配車対象外フラグ名
      auto_planning_exclude_flag_name: getMasterName(
        MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG,
        info.autoPlanningExcludeFlag
      ),
      //開始曜日
      begin_weekday: info.beginWeekday,
      //開始曜日名
      begin_weekday_name: getMasterName(
        MASTER_CODE.WEEKDAY_NAME,
        info.beginWeekday
      ),
      //開始時刻
      begin_time: info.beginTime.replace(/:/gi, ''),
      //終了曜日
      end_weekday: info.endWeekday,
      //終了曜日名
      end_weekday_name: getMasterName(
        MASTER_CODE.WEEKDAY_NAME,
        info.endWeekday
      ),
      //終了時刻
      end_time: info.endTime.replace(/:/gi, ''),
      //担当方面1
      charge_area_id01: info.chargeAreaId01,
      charge_area_name01: info.chargeAreaName01,
      //担当方面2
      charge_area_id02: info.chargeAreaId02,
      charge_area_name02: info.chargeAreaName02,
      //担当方面3
      charge_area_id03: info.chargeAreaId03,
      charge_area_name03: info.chargeAreaName03,
      //担当方面4
      charge_area_id04: info.chargeAreaId04,
      charge_area_name04: info.chargeAreaName04,
      //担当方面5
      charge_area_id05: info.chargeAreaId05,
      charge_area_name05: info.chargeAreaName05,
      //削除済み区分
      deleted: info.deleted,
      //削除済み区分名
      deleted_name: getMasterName(MASTER_CODE.DELETED, info.deleted),
      //登録日時
      registration_time_display: info.registrationTimeDisplay,
      //登録ユーザ
      registration_user: info.registrationUser,
      //更新日時
      update_time_display: info.updateTimeDisplay,
      //更新ユーザ
      update_user: info.updateUser,
    }
    if (obj.detailRowIndex != -1) {
      obj.selectedItem[0].operation_time_pattern_detail_id =
        info.operationTimePatternDetailId
      obj.selectedItem[0].display_order = info.displayOrder
      obj.selectedItem[0].transport_type_kind = info.transportTypeKind
      obj.selectedItem[0].transport_type_kind_name = getMasterName(
        MASTER_CODE.TRANSPORT_TYPE_KIND,
        info.transportTypeKind
      )
      obj.selectedItem[0].auto_planning_exclude_flag =
        info.autoPlanningExcludeFlag
      obj.selectedItem[0].auto_planning_exclude_flag_name = getMasterName(
        MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG,
        info.autoPlanningExcludeFlag
      )
      obj.selectedItem[0].begin_weekday = info.beginWeekday
      obj.selectedItem[0].begin_weekday_name = getMasterName(
        MASTER_CODE.WEEKDAY_NAME,
        info.beginWeekday
      )
      obj.selectedItem[0].begin_time = info.beginTime.replace(/:/gi, '')
      obj.selectedItem[0].end_weekday = info.endWeekday
      obj.selectedItem[0].end_weekday_name = getMasterName(
        MASTER_CODE.WEEKDAY_NAME,
        info.endWeekday
      )
      obj.selectedItem[0].end_time = info.endTime.replace(/:/gi, '')
      obj.selectedItem[0].charge_area_id01 = info.chargeAreaId01
      obj.selectedItem[0].charge_area_name01 = info.chargeAreaName01
      obj.selectedItem[0].charge_area_id02 = info.chargeAreaId02
      obj.selectedItem[0].charge_area_name02 = info.chargeAreaName02
      obj.selectedItem[0].charge_area_id03 = info.chargeAreaId03
      obj.selectedItem[0].charge_area_name03 = info.chargeAreaName03
      obj.selectedItem[0].charge_area_id04 = info.chargeAreaId04
      obj.selectedItem[0].charge_area_name04 = info.chargeAreaName04
      obj.selectedItem[0].charge_area_id05 = info.chargeAreaId05
      obj.selectedItem[0].charge_area_name05 = info.chargeAreaName05
      obj.selectedItem[0].deleted = info.deleted
      obj.selectedItem[0].deleted_name = getMasterName(
        MASTER_CODE.DELETED,
        info.deleted
      )
      obj.selectedItem[0].registration_time_display =
        info.registrationTimeDisplay
      obj.selectedItem[0].registration_user = info.registrationUser
      obj.selectedItem[0].update_time_display = info.updateTimeDisplay
      obj.selectedItem[0].update_user = info.updateUser

      obj.OperationTimePattern.patternDetails[obj.detailRowIndex] =
        obj.selectedItem[0]
    } else {
      obj.OperationTimePattern.patternDetails.push(addInfo)
    }
  }
  obj.tableItems = obj.OperationTimePattern.patternDetails
}

function changeCamelCase(obj) {
  var patterns_camel = convertCamelCace(obj.OperationTimePattern.patternDetails)

  return patterns_camel
}

function changeSnakeCase(obj) {
  var patterns = convertSnakeCace(obj.OperationTimePattern.patternDetails)
  patterns.sort(function (a, b) {
    return a.vehicle_id - b.vehicle_id
  })

  return patterns
}
