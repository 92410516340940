import {
  openMsgModal,
  openSubModal,
  formatHHmmss,
  checkCoordinatesRange,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN, USER_EROLE } from '@/common/const.js'
import PlaceService from './PlaceService'
import _ from 'lodash'

export function init(obj) {
  setUserDepartmentInfo(obj)

  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    PlaceService.get(obj.placeId)
      .then(
        (response) => {
          obj.formData = response.data
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.placeId = null
            obj.formData.deleted = '0'
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
          } else {
            obj.formData.placeId = obj.placeId
          }
          obj.oriData = _.cloneDeep(obj.formData)

          if (
            !checkCoordinatesRange(
              obj.formData.latitude,
              obj.formData.longitude
            )
          ) {
            let message = obj.$store.getters['init/getMessage'](
              'ED01S003_MG004I'
            )
            openMsgModal(obj, MSG_TYPE.I, message)
          }
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.formData.deleted == '1') {
    obj.formData.deleted = '0'
  }

  obj.formData.departureBeginTime = formatHHmmss(
    obj.formData.departureBeginTime
  )
  obj.formData.departureEndTime = formatHHmmss(obj.formData.departureEndTime)
  obj.formData.impossibleDepartureBeginTime1 = formatHHmmss(
    obj.formData.impossibleDepartureBeginTime1
  )
  obj.formData.impossibleDepartureEndTime1 = formatHHmmss(
    obj.formData.impossibleDepartureEndTime1
  )
  obj.formData.impossibleDepartureBeginTime2 = formatHHmmss(
    obj.formData.impossibleDepartureBeginTime2
  )
  obj.formData.impossibleDepartureEndTime2 = formatHHmmss(
    obj.formData.impossibleDepartureEndTime2
  )
  obj.formData.impossibleDepartureBeginTime3 = formatHHmmss(
    obj.formData.impossibleDepartureBeginTime3
  )
  obj.formData.impossibleDepartureEndTime3 = formatHHmmss(
    obj.formData.impossibleDepartureEndTime3
  )
  obj.formData.arrivalBeginTime = formatHHmmss(obj.formData.arrivalBeginTime)
  obj.formData.arrivalEndTime = formatHHmmss(obj.formData.arrivalEndTime)
  obj.formData.impossibleArrivalBeginTime1 = formatHHmmss(
    obj.formData.impossibleArrivalBeginTime1
  )
  obj.formData.impossibleArrivalEndTime1 = formatHHmmss(
    obj.formData.impossibleArrivalEndTime1
  )
  obj.formData.impossibleArrivalBeginTime2 = formatHHmmss(
    obj.formData.impossibleArrivalBeginTime2
  )
  obj.formData.impossibleArrivalEndTime2 = formatHHmmss(
    obj.formData.impossibleArrivalEndTime2
  )
  obj.formData.impossibleArrivalBeginTime3 = formatHHmmss(
    obj.formData.impossibleArrivalBeginTime3
  )
  obj.formData.impossibleArrivalEndTime3 = formatHHmmss(
    obj.formData.impossibleArrivalEndTime3
  )

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = PlaceService.create(obj.formData)
  } else {
    // 詳細
    callApi = PlaceService.update(obj.placeId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export async function convertAddressToCoordinates(obj) {
  let message = ''
  let address = ''
  if (obj.formData.address1) {
    address = obj.formData.address1
  }
  if (obj.formData.address2) {
    address = address + obj.formData.address2
  }
  address = address.replace('#', '')

  console.log('address', address)

  // 入力チェック
  if (!address) {
    message = obj.$store.getters['init/getMessage']('MB01S002_MG008E')
    openMsgModal(obj, MSG_TYPE.E, message)
    return
  }

  obj.$store.dispatch('init/setServiceLoading')
  await PlaceService.convertAddressToCoordinates(address)
    .then(
      (response) => {
        let coordinates = response.data
        obj.formData.latitude = coordinates.latitude
        obj.formData.longitude = coordinates.longitude
        obj.formData.accuracyLevel = coordinates.accuracyLevel
        obj.formData.cityCode = coordinates.cityCode
        obj.formData.postalCode = coordinates.postalCode

        message = obj.$store.getters['init/getMessage'](
          'MB01S002_MG004I',
          address
        )
        openMsgModal(obj, MSG_TYPE.I, message)
      },
      (e) => {
        // エラーモーダル表示
        if (e.response == undefined) {
          message = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          message = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, message)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function setUserDepartmentInfo(obj) {
  let loginUserRoleId = obj.$store.getters['authentication/getRoleId']
  // ロール＝OWNERの場合
  // 荷主はログインユーザの部署を設定し変更不可
  if (loginUserRoleId == USER_EROLE.OWNER) {
    obj.formData.ownerId =
      obj.$store.getters['authentication/getUserDepartmentId']
    obj.formData.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.formData.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']

    obj.oriData.ownerId =
      obj.$store.getters['authentication/getUserDepartmentId']
    obj.oriData.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.oriData.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']
  }
}
