import { openMsgModal, openSubModal } from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN, VISIBILITY } from '@/common/const.js'
import CalendarService from './CalendarService'
import _ from 'lodash'

export async function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    let data = {
      calendar_id: obj.selectedItem.calendar_id,
      yymm: obj.selectedItem.yymm,
      calendar_name: obj.selectedItem.calendar_name,
      delete_enable_kind: obj.selectedItem.delete_enable_kind,
    }
    await doSearch(obj, data, true)
  }
  // 詳細モードで、標準カレンダーの場合、名称と削除可否区分は非活性
  if (obj.screenKbn === DETAIL_SCREEN_KBN.DETAIL) {
    if (obj.selectedItem.calendar_id === 0) {
      obj.detailInfo.calendar_name.visibility = VISIBILITY.DISABLE
      obj.detailInfo.delete_enable_kind.visibility = VISIBILITY.DISABLE
    }
  } else if (obj.screenKbn === DETAIL_SCREEN_KBN.COPY) {
    obj.Calendar.calendarId = null
    obj.oriData = _.cloneDeep(obj.Calendar)
  }
  //
  if (obj.Calendar.calendarId === null) {
    obj.detailCtrlInfo.previousMonth.visibility = VISIBILITY.DISABLE
    obj.detailCtrlInfo.nextMonth.visibility = VISIBILITY.DISABLE
  }
}

export function initDisplayData(obj) {
  obj.allWorkingList = []
  for (let i = 0; i < 6; i++) {
    let dayArray = []
    for (let j = 0; j < 7; j++) {
      dayArray.push({ label: '', model: '' })
    }
    obj.allWorkingList.push(dayArray)
  }

  let yymm = obj.Calendar.yymm.replace('/', '')
  let dt = obj.$moment(yymm + '01')
  obj.Calendar.yymm = yymm.substr(0, 4) + '/' + yymm.substr(4, 2)
  const nDays = obj.$moment(obj.Calendar.yymm).daysInMonth()
  const startIndex = obj.$moment(dt.weekday())

  let i = 0
  obj.allWorkingList.forEach((weekArray) => {
    weekArray.forEach((element) => {
      //該当月の設定処理を行う
      if (i >= startIndex && i <= startIndex + nDays - 1) {
        element.label = `${i - startIndex + 1}日`
        element.model = `day${i - startIndex + 1}`
      }
      i++
    })
  })
  console.log(obj.allWorkingList)
}

export function clear(obj) {
  obj.Calendar = _.cloneDeep(obj.oriData)
  initDisplayData(obj)

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export async function moveMonth(obj) {
  obj.$bvModal.hide('moveMonth-modal')

  let dt = obj.$moment(obj.Calendar.yymm + '/01')
  let yymm
  if (obj.isNext) {
    yymm = obj.$moment(dt.add(1, 'M'))
  } else {
    yymm = obj.$moment(dt.add(-1, 'M'))
  }
  yymm = obj.$moment(yymm).format('YYYYMM')
  obj.Calendar.yymm = yymm.substr(0, 4) + '/' + yymm.substr(4, 2)

  let data = {
    calendar_id: obj.selectedItem.calendar_id,
    yymm: yymm,
    calendar_name: obj.Calendar.calendarName,
    delete_enable_kind: obj.Calendar.deleteEnableKind,
  }
  await doSearch(obj, data, false)

  initDisplayData(obj)
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  if (obj.Calendar.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  console.log(obj.Calendar)
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var callApi
  let registerData = _.cloneDeep(obj.Calendar)
  registerData.yymm = registerData.yymm.replace('/', '')
  if (obj.isCreate === true || obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = CalendarService.create(registerData)
  } else {
    // 詳細
    callApi = CalendarService.updateDetail(registerData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'calendar-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function setBgColor(obj, model) {
  let val = obj.Calendar[model]
  if (val == 0) {
    return 'red' //非稼働日
  } else if (val == 1) {
    return 'white' //稼働日１
  } else if (val == 2) {
    return 'CornflowerBlue' //稼働日２
  } else {
    return 'LightGray'
  }
}

export function setFontColor(obj, model) {
  let val = obj.Calendar[model]
  if (val == 0 || val == 2) {
    return 'white'
  } else {
    return 'black' //稼働日１
  }
}

export function workingDayClick(obj, days) {
  //対象外の日
  if (days.label == '') return

  let val = obj.Calendar[days.model]
  //非稼働日→稼働日１
  if (val == 0) {
    obj.Calendar[days.model] = '1'
  }
  //稼働日１→非稼働日
  if (val == 1) {
    obj.Calendar[days.model] = '0'
  }

  setBgColor(obj, days.model)
  setFontColor(obj, days.model)
  obj.isEdit = true
  return
}

async function doSearch(obj, data, isInit) {
  let isNull = false
  obj.isCreate = false
  obj.$store.dispatch('init/setServiceLoading')
  await CalendarService.detail(data.calendar_id, data.yymm)
    .then(
      (response) => {
        if (response.data != '') {
          obj.Calendar = response.data
          obj.Calendar.yymm =
            data.yymm.substr(0, 4) + '/' + data.yymm.substr(4, 2)
        } else {
          obj.isCreate = true
          isNull = true
          if (!isInit) {
            CalendarService.detail(0, data.yymm).then((response) => {
              if (response.data != '') {
                let calendarId = obj.Calendar.calendarId
                let calendarName = obj.Calendar.calendarName
                let deleteEnableKind = obj.Calendar.deleteEnableKind

                obj.Calendar = response.data
                obj.Calendar.calendarId = calendarId
                obj.Calendar.calendarName = calendarName
                obj.Calendar.deleteEnableKind = deleteEnableKind
                obj.Calendar.yymm =
                  data.yymm.substr(0, 4) + '/' + data.yymm.substr(4, 2)
                isNull = false
              }
            })
          }

          if (isNull) {
            obj.Calendar.calendarId = 0
            obj.Calendar.calendarName = data.calendar_name
            obj.Calendar.yymm =
              data.yymm.substr(0, 4) + '/' + data.yymm.substr(4, 2)
            obj.Calendar.deleteEnableKind = data.delete_enable_kind

            let dt = obj.$moment(data.yymm + '01')
            for (let index = 1; index < 32; index++) {
              let dayName = `day${index}`
              obj.Calendar[dayName] = getWorkingKind(obj, dt)
              dt = obj.$moment(dt.add(1, 'd'))
            }
          }
        }

        if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
          obj.Calendar.calendarName = null
          obj.Calendar.deleteEnableKind = null
          obj.Calendar.deleted = '0'
          obj.Calendar.registrationTimeDisplay = null
          obj.Calendar.registrationUser = null
          obj.Calendar.updateTimeDisplay = null
          obj.Calendar.updateUser = null
        } else {
          // 現状取得しているカレンダーIDで「標準カレンダー」を判断する。
          // 「標準カレンダー」 -> メッセージを表示する。
          if (obj.Calendar.calendarId == 0) {
            openMsgModal(
              obj,
              obj.MSG_TYPE.I,
              obj.$store.getters['init/getMessage']('MJ01S002_MG001I')
            )
          }
        }
        obj.Calendar.calendarId = data.calendar_id

        obj.oriData = _.cloneDeep(obj.Calendar)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function getWorkingKind(obj, dt) {
  let workingkind = '0'
  let week = obj.$moment(dt.weekday())
  if (week == 0) {
    workingkind = '0' //非稼働日
  } else {
    workingkind = '1' //稼働日１
  }
  return workingkind
}
