import { openMsgModal, openSubModal, formatYYYYMMDD } from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import VehicleService from './VehicleService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    VehicleService.get(obj.vehicleId)
      .then(
        (response) => {
          obj.formData = response.data

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.vehicleId = null
            obj.formData.deleted = '0'
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
          } else {
            obj.formData.vehicleId = obj.vehicleId
          }
          obj.oriData = _.cloneDeep(obj.formData)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.formData.deleted == '1') {
    obj.formData.deleted = '0'
  }

  obj.formData.inspectionEndDate = formatYYYYMMDD(
    obj,
    obj.formData.inspectionEndDate
  )
  obj.formData.useStartDate = formatYYYYMMDD(obj, obj.formData.useStartDate)
  obj.formData.useFinishDate = formatYYYYMMDD(obj, obj.formData.useFinishDate)

  obj.formData.nonoperationDay01From = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay01From
  )
  obj.formData.nonoperationDay01To = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay01To
  )
  obj.formData.nonoperationDay02From = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay02From
  )
  obj.formData.nonoperationDay02To = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay02To
  )
  obj.formData.nonoperationDay03From = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay03From
  )
  obj.formData.nonoperationDay03To = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay03To
  )
  obj.formData.nonoperationDay04From = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay04From
  )
  obj.formData.nonoperationDay04To = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay04To
  )
  obj.formData.nonoperationDay05From = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay05From
  )
  obj.formData.nonoperationDay05To = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay05To
  )
  obj.formData.nonoperationDay06From = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay06From
  )
  obj.formData.nonoperationDay06To = formatYYYYMMDD(
    obj,
    obj.formData.nonoperationDay06To
  )

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = VehicleService.create(obj.formData)
  } else {
    // 詳細
    callApi = VehicleService.update(obj.vehicleId, obj.formData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
