<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
          @clear="clear(obj)"
        ></BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 部署参照モーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      selectMode="single"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所参照モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetBasePlace"
      @after-close-unset="afterCloseUnsetBasePlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 方面参照モーダル -->
    <AreaModal
      id="area-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetArea"
      @after-close-unset="afterCloseUnsetArea"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 車両稼働パターン参照モーダル -->
    <PatternModal
      id="operationtimepattern-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPattern"
      @after-close-unset="afterCloseUnsetPattern"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import VehicleInfo from './VehicleInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND, INTEGER_MAX_MIN } from '@/common/const.js'
import {
  clear,
  goRegister,
  getBreadcrumbItems,
  back,
} from '@/common/screen-common.js'
import { init, register } from '@/master/vehicle/VehicleDetail.js'

const screenId = SCREEN_ID.VEHICLE_DETAIL

export default {
  name: 'vehicleDetail',

  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    AreaModal: () => import('@/master/area/AreaModal.vue'),
    PatternModal: () =>
      import('@/master/operationTimePattern/OperationTimePatternModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    vehicleId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      formData: new VehicleInfo(),
      areaModalKbn: -1,
      obj: this,
      registerMessage: null,
      oriData: new VehicleInfo(),
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 車両パターンタブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 車両ID
              type: 'text',
              id: 'vehicleId',
              columnInfo: this.detailInfo.vehicle_id,
            },
            {
              // 輸送手段コード
              type: 'text',
              id: 'vehicleCode',
              columnInfo: this.detailInfo.vehicle_code,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
            },
            {
              // 輸送手段名
              type: 'text',
              id: 'vehicleName',
              columnInfo: this.detailInfo.vehicle_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },

            {
              // 運送会社
              type: 'referenceCodeName',
              codeId: 'carrierCode',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_code,
              nameColumnInfo: this.detailInfo.carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_carrier,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CARRIER
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // ナンバープレート
              type: 'text',
              id: 'numberplate',
              maxLength: 50,
              columnInfo: this.detailInfo.numberplate,
            },
            {
              // 車型
              type: 'select',
              id: 'vehicleModel',
              columnInfo: this.detailInfo.vehicle_model,
              options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 輸送手段カテゴリ
              type: 'select',
              id: 'vehicleCategory',
              columnInfo: this.detailInfo.vehicle_category,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CATEGORY),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // タリフ区分
              type: 'select',
              id: 'tariffType',
              columnInfo: this.detailInfo.tariff_type,
              options: getListOptions(this.MASTER_CODE.TARIFF_TYPE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // タリフ判別車型
              type: 'select',
              id: 'tariffVehicleModel',
              columnInfo: this.detailInfo.tariff_vehicle_model,
              options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 最大積載質量
              type: 'text',
              id: 'maxLoadingWeight',
              columnInfo: this.detailInfo.max_loading_weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 最大積載容量
              type: 'text',
              id: 'maxLoadingVolume',
              columnInfo: this.detailInfo.max_loading_volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 最大積載数量
              type: 'text',
              id: 'maxLoadingAmount',
              columnInfo: this.detailInfo.max_loading_amount,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 輸送手段区分
              type: 'select',
              id: 'vehicleKind',
              columnInfo: this.detailInfo.vehicle_kind,
              options: getListOptions(this.MASTER_CODE.VEHICLE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 仮車区分
              type: 'select',
              id: 'managementVehicleKind',
              columnInfo: this.detailInfo.management_vehicle_kind,
              options: getListOptions(this.MASTER_CODE.MANAGEMENT_VEHICLE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 電話番号
              type: 'text',
              id: 'tel',
              columnInfo: this.detailInfo.tel,
              rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC],
            },
            {
              // 作業準備時間
              type: 'text',
              id: 'preparationTime',
              columnInfo: this.detailInfo.preparation_time,
              rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            },
            {
              // 車両基地場所
              type: 'referenceCodeName',
              codeId: 'vehicleBasePlaceCode',
              nameId: 'vehicleBasePlaceName',
              codeColumnInfo: this.detailInfo.vehicle_base_place_code,
              nameColumnInfo: this.detailInfo.vehicle_base_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_base_place,
              onClickCallback: () => {
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 輸送距離区分
              type: 'select',
              id: 'transportDistanceKind',
              columnInfo: this.detailInfo.transport_distance_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_DISTANCE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 積置きフラグ
              type: 'select',
              id: 'preloadable',
              columnInfo: this.detailInfo.preloadable,
              options: getListOptions(this.MASTER_CODE.PRELOADABLE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 輸送形態区分
              type: 'select',
              id: 'transportTypeKind',
              columnInfo: this.detailInfo.transport_type_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_TYPE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },

            {
              // 車体質量
              type: 'text',
              id: 'vehicleWeight',
              columnInfo: this.detailInfo.vehicle_weight,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              // 車体長さ
              type: 'text',
              id: 'vehicleLength',
              columnInfo: this.detailInfo.vehicle_length,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 車体幅
              type: 'text',
              id: 'vehicleWidth',
              columnInfo: this.detailInfo.vehicle_width,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 車体高さ
              type: 'text',
              id: 'vehicleHeight',
              columnInfo: this.detailInfo.vehicle_height,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 輸送手段優先順
              type: 'text',
              id: 'vehiclePriorityOrder',
              columnInfo: this.detailInfo.vehicle_priority_order,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 担当方面1
              type: 'referenceCodeName',
              codeId: 'chargeAreaId01',
              nameId: 'chargeAreaName01',
              codeColumnInfo: this.detailInfo.charge_area_id_01,
              nameColumnInfo: this.detailInfo.charge_area_name_01,
              btnColumnInfo: this.detailInfo.sub_modal_area_01,
              onClickCallback: () => {
                this.areaModalKbn = 1
                this.openSubModal(this, 'area-modal')
              },
            },
            {
              // 担当方面2
              type: 'referenceCodeName',
              codeId: 'chargeAreaId02',
              nameId: 'chargeAreaName02',
              codeColumnInfo: this.detailInfo.charge_area_id_02,
              nameColumnInfo: this.detailInfo.charge_area_name_02,
              btnColumnInfo: this.detailInfo.sub_modal_area_02,
              onClickCallback: () => {
                this.areaModalKbn = 2
                this.openSubModal(this, 'area-modal')
              },
            },
            {
              // 担当方面3
              type: 'referenceCodeName',
              codeId: 'chargeAreaId03',
              nameId: 'chargeAreaName03',
              codeColumnInfo: this.detailInfo.charge_area_id_03,
              nameColumnInfo: this.detailInfo.charge_area_name_03,
              btnColumnInfo: this.detailInfo.sub_modal_area_03,
              onClickCallback: () => {
                this.areaModalKbn = 3
                this.openSubModal(this, 'area-modal')
              },
            },
            {
              // 担当方面4
              type: 'referenceCodeName',
              codeId: 'chargeAreaId04',
              nameId: 'chargeAreaName04',
              codeColumnInfo: this.detailInfo.charge_area_id_04,
              nameColumnInfo: this.detailInfo.charge_area_name_04,
              btnColumnInfo: this.detailInfo.sub_modal_area_04,
              onClickCallback: () => {
                this.areaModalKbn = 4
                this.openSubModal(this, 'area-modal')
              },
            },
            {
              // 担当方面5
              type: 'referenceCodeName',
              codeId: 'chargeAreaId05',
              nameId: 'chargeAreaName05',
              codeColumnInfo: this.detailInfo.charge_area_id_05,
              nameColumnInfo: this.detailInfo.charge_area_name_05,
              btnColumnInfo: this.detailInfo.sub_modal_area_05,
              onClickCallback: () => {
                this.areaModalKbn = 5
                this.openSubModal(this, 'area-modal')
              },
            },
            {
              // 評価用固定費
              type: 'text',
              id: 'evaluationFixValue',
              columnInfo: this.detailInfo.evaluation_fix_value,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },

            {
              // 高速道路使用可否フラグ
              type: 'select',
              id: 'highwayUseYnFlag',
              columnInfo: this.detailInfo.highway_use_yn_flag,
              options: getListOptions(this.MASTER_CODE.HIGHWAY_USE_YN_FLAG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 稼動時間パターン
              type: 'referenceCodeName',
              codeId: 'operationTimePatternId',
              nameId: 'operationTimePatternName',
              codeColumnInfo: this.detailInfo.operation_time_pattern_id,
              nameColumnInfo: this.detailInfo.operation_time_pattern_name,
              btnColumnInfo: this.detailInfo.sub_modal_pattern,
              onClickCallback: () => {
                this.openSubModal(this, 'operationtimepattern-modal')
              },
            },
            {
              // 稼動時間パターン有効フラグ
              type: 'select',
              id: 'operationTimePatternValidFlag',
              columnInfo: this.detailInfo.operation_time_pattern_valid_flag,
              options: getListOptions(
                this.MASTER_CODE.OPERATION_TIME_PATTERN_VALID_FLAG
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },

            {
              // 車検満了日
              type: 'date',
              id: 'inspectionEndDate',
              columnInfo: this.detailInfo.inspection_end_date,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 使用開始日
              type: 'date',
              id: 'useStartDate',
              columnInfo: this.detailInfo.use_start_date,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATE,
              ],
            },
            {
              // 使用終了日
              type: 'date',
              id: 'useFinishDate',
              columnInfo: this.detailInfo.use_finish_date,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATE,
              ],
            },

            {
              // 非稼動日1（From）
              type: 'date',
              id: 'nonoperationDay01From',
              columnInfo: this.detailInfo.nonoperation_day_01_from,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日1（To）
              type: 'date',
              id: 'nonoperationDay01To',
              columnInfo: this.detailInfo.nonoperation_day_01_to,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日2（From）
              type: 'date',
              id: 'nonoperationDay02From',
              columnInfo: this.detailInfo.nonoperation_day_02_from,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日2（To）
              type: 'date',
              id: 'nonoperationDay02To',
              columnInfo: this.detailInfo.nonoperation_day_02_to,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日3（From）
              type: 'date',
              id: 'nonoperationDay03From',
              columnInfo: this.detailInfo.nonoperation_day_03_from,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日3（To）
              type: 'date',
              id: 'nonoperationDay03To',
              columnInfo: this.detailInfo.nonoperation_day_03_to,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日4（From）
              type: 'date',
              id: 'nonoperationDay04From',
              columnInfo: this.detailInfo.nonoperation_day_04_from,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日4（To）
              type: 'date',
              id: 'nonoperationDay04To',
              columnInfo: this.detailInfo.nonoperation_day_04_to,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日5（From）
              type: 'date',
              id: 'nonoperationDay05From',
              columnInfo: this.detailInfo.nonoperation_day_05_from,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日5（To）
              type: 'date',
              id: 'nonoperationDay05To',
              columnInfo: this.detailInfo.nonoperation_day_05_to,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日6（From）
              type: 'date',
              id: 'nonoperationDay06From',
              columnInfo: this.detailInfo.nonoperation_day_06_from,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 非稼動日6（To）
              type: 'date',
              id: 'nonoperationDay06To',
              columnInfo: this.detailInfo.nonoperation_day_06_to,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // マスタ区分
              type: 'select',
              id: 'isOnetimeMaster',
              columnInfo: this.detailInfo.is_onetime_master,
              options: getListOptions(this.MASTER_CODE.IS_ONETIME_MASTER),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 輸送手段予備1
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
            },
            {
              // 輸送手段予備2
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
            },
            {
              // 輸送手段予備3
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
            },
            {
              // 輸送手段予備4
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
            },
            {
              // 輸送手段予備5
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
            },
            {
              // 輸送手段予備6
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
            },
            {
              // 輸送手段予備7
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              // 輸送手段予備8
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              // 輸送手段予備9
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              // 輸送手段予備10
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // バージョン
              type: 'text',
              id: 'version',
              columnInfo: this.detailInfo.version,
            },
            {
              // トリガ更新日時
              type: 'text',
              id: 'updateByTrigger',
              columnInfo: this.detailInfo.update_by_trigger,
            },
          ],
        },
        {
          // 輸送手段条件情報タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 輸送手段条件１
              type: 'select',
              id: 'vehicleCondition01',
              columnInfo: this.detailInfo.vehicle_condition01,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２
              type: 'select',
              id: 'vehicleCondition02',
              columnInfo: this.detailInfo.vehicle_condition02,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件３
              type: 'select',
              id: 'vehicleCondition03',
              columnInfo: this.detailInfo.vehicle_condition03,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件４
              type: 'select',
              id: 'vehicleCondition04',
              columnInfo: this.detailInfo.vehicle_condition04,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件５
              type: 'select',
              id: 'vehicleCondition05',
              columnInfo: this.detailInfo.vehicle_condition05,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件６
              type: 'select',
              id: 'vehicleCondition06',
              columnInfo: this.detailInfo.vehicle_condition06,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件７
              type: 'select',
              id: 'vehicleCondition07',
              columnInfo: this.detailInfo.vehicle_condition07,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件８
              type: 'select',
              id: 'vehicleCondition08',
              columnInfo: this.detailInfo.vehicle_condition08,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件９
              type: 'select',
              id: 'vehicleCondition09',
              columnInfo: this.detailInfo.vehicle_condition09,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１０
              type: 'select',
              id: 'vehicleCondition10',
              columnInfo: this.detailInfo.vehicle_condition10,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１１
              type: 'select',
              id: 'vehicleCondition11',
              columnInfo: this.detailInfo.vehicle_condition11,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１２
              type: 'select',
              id: 'vehicleCondition12',
              columnInfo: this.detailInfo.vehicle_condition12,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１３
              type: 'select',
              id: 'vehicleCondition13',
              columnInfo: this.detailInfo.vehicle_condition13,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１４
              type: 'select',
              id: 'vehicleCondition14',
              columnInfo: this.detailInfo.vehicle_condition14,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１５
              type: 'select',
              id: 'vehicleCondition15',
              columnInfo: this.detailInfo.vehicle_condition15,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１６
              type: 'select',
              id: 'vehicleCondition16',
              columnInfo: this.detailInfo.vehicle_condition16,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１７
              type: 'select',
              id: 'vehicleCondition17',
              columnInfo: this.detailInfo.vehicle_condition17,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１８
              type: 'select',
              id: 'vehicleCondition18',
              columnInfo: this.detailInfo.vehicle_condition18,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件１９
              type: 'select',
              id: 'vehicleCondition19',
              columnInfo: this.detailInfo.vehicle_condition19,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 輸送手段条件２０
              type: 'select',
              id: 'vehicleCondition20',
              columnInfo: this.detailInfo.vehicle_condition20,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.formData.carrierId = id
      this.formData.carrierCode = code
      this.formData.carrierName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.formData.carrierId = null
      this.formData.carrierCode = null
      this.formData.carrierName = null
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetBasePlace(selectedItem) {
      this.formData.vehicleBasePlaceId = selectedItem.place_id
      this.formData.vehicleBasePlaceCode = selectedItem.place_code
      this.formData.vehicleBasePlaceName = selectedItem.place_name
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetBasePlace() {
      this.formData.vehicleBasePlaceId = null
      this.formData.vehicleBasePlaceCode = null
      this.formData.vehicleBasePlaceName = null
    },

    /**
     * 方面マスタから取得データ設定
     */
    afterCloseSetArea(id, name) {
      if (this.areaModalKbn == 1) {
        this.formData.chargeAreaId01 = id
        this.formData.chargeAreaName01 = name
      } else if (this.areaModalKbn == 2) {
        this.formData.chargeAreaId02 = id
        this.formData.chargeAreaName02 = name
      } else if (this.areaModalKbn == 3) {
        this.formData.chargeAreaId03 = id
        this.formData.chargeAreaName03 = name
      } else if (this.areaModalKbn == 4) {
        this.formData.chargeAreaId04 = id
        this.formData.chargeAreaName04 = name
      } else if (this.areaModalKbn == 5) {
        this.formData.chargeAreaId05 = id
        this.formData.chargeAreaName05 = name
      }
    },

    /**
     * 方面情報解除
     */
    afterCloseUnsetArea() {
      if (this.areaModalKbn == 1) {
        this.formData.chargeAreaId01 = null
        this.formData.chargeAreaName01 = null
      } else if (this.areaModalKbn == 2) {
        this.formData.chargeAreaId02 = null
        this.formData.chargeAreaName02 = null
      } else if (this.areaModalKbn == 3) {
        this.formData.chargeAreaId03 = null
        this.formData.chargeAreaName03 = null
      } else if (this.areaModalKbn == 4) {
        this.formData.chargeAreaId04 = null
        this.formData.chargeAreaName04 = null
      } else if (this.areaModalKbn == 5) {
        this.formData.chargeAreaId05 = null
        this.formData.chargeAreaName05 = null
      }
    },

    /**
     * 車両稼働パターンマスタから取得データ設定
     */
    afterCloseSetPattern(id, name) {
      this.formData.operationTimePatternId = id
      this.formData.operationTimePatternName = name
    },

    /**
     * 車両稼働パターン情報解除
     */
    afterCloseUnsetPattern() {
      this.formData.operationTimePatternId = null
      this.formData.operationTimePatternName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
  },
}
</script>
